/* Main Colors */
/* Gray */
.root {
  overflow: hidden;
}
.root.background-white {
  background-color: white;
}
.root.background-gray90 {
  background-color: #1D1E26;
}
.root.background-none {
  background-color: transparent;
}
.root.margin-24 {
  margin: 24px;
}
.root.padding-12 {
  padding: 12px;
}
.root.padding-24 {
  padding: 24px;
}
.root.shadow {
  box-shadow: 0 3px 6px 0 rgba(29, 30, 38, 0.1), 0 1px 3px 0 rgba(29, 30, 38, 0.1);
}
.root:global(.-clickable):hover {
  cursor: pointer;
}