/* Main Colors */
/* Gray */
.root :global(.uui-tooltip-body) {
  padding: 3px 12px;
  line-height: 24px;
  font-size: 14px;
  font-family: "Sans Regular", Arial, sans-serif;
  box-shadow: 0 1px 4px 0 rgba(44, 47, 60, 0.05), 0 2px 18px 0 rgba(44, 47, 60, 0.03);
  word-wrap: break-word;
}
.root :global(.uui-popover-arrow) {
  position: absolute;
  width: 12px;
  height: 12px;
  overflow: hidden;
}
.root :global(.uui-popover-arrow)::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.root :global(.uui-tooltip-body) {
  background-color: var(--background-color);
  color: var(--text-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="right"])::after {
  background: var(--background-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="left"])::after {
  background: var(--background-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="top"])::after {
  background: var(--background-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="bottom"])::after {
  background: var(--background-color);
}