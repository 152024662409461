@import 'styles/common.scss';

.project-detail {
  &-team-member {
    margin-bottom: 8px;
    padding-right: 16px;

    & &.has-action {
      &:hover {
        background: #ebedf5;
        cursor: pointer;
      }
    }

    div & &__name {
      font-size: 14px;
      font-weight: bold;
      min-height: 20px;

      div {
        padding: 0px;
        color: #303240;
      }
    }
    div & &__role {
      color: #6c6f80;
      min-height: 20px;
      div {
        padding: 0px;
      }
    }
    &__icons {
      display: flex;
    }
    .mail-icon,
    .teams-icon,
    .telescope-icon {
      margin-left: 12px;
    }
    &__ownership {
      transform: rotate(-90deg);
    }
    &__btnicon {
      display: none;
    }
    &:hover & {
      &__btnicon {
        display: block;
      }
    }
  }
}
