/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: $sans-pro-bold;
  font-weight: bold;
  line-height: 1.1;
  text-rendering: optimizelegibility;
  margin: 0;
  color: $black-color;

  small {
    font-weight: normal;
  }
}

h1,
.h1 {
  @include font-heading(h1);
}

h2,
.h2 {
  @include font-heading(h2);
}

h3,
.h3 {
  @include font-heading(h3);
}

h4,
.h4 {
  @include font-heading(h4);
}

h5,
.h5 {
  @include font-heading(h5);
}

h6,
.h6 {
  @include font-heading(h6);
}
