/* Main Colors */
/* Gray */
.root :global(.uui-tooltip-body) {
  background-color: #474A59;
  padding: 0 10px;
  color: white;
  line-height: 24px;
  font-size: 14px;
  font-family: "Sans Regular", Arial, sans-serif;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.root :global(.uui-tooltip-arrow) {
  border: 5px solid transparent;
}
.root :global(.uui-slider-scale-number) {
  font-size: 11px;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #474A59;
  margin-top: 10px;
}
.root :global(.uui-slider-scale-dot) {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  top: -1px;
}
.root:global(.uui-slider-container) {
  height: 12px;
}
.root:global(.uui-slider-container) :global(.uui-slider) {
  margin: 0 6px 0 6px;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #E1E3EB;
  min-width: 100px;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale) {
  height: 6px;
}
.root:global(.uui-slider-container) :global(.uui-slider-handle) {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #008ACE;
  cursor: pointer;
}
.root:global(.uui-slider-container) :global(.uui-slider-filled) {
  margin: 0 6px;
  height: 3px;
  border-radius: 10px;
  background-color: #008ACE;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale-dot) {
  background-color: #ACAFBF;
  border: 1px #E1E3EB solid;
}
.root:global(.uui-slider-container):hover :global(.uui-slider) {
  background-color: #008ACE;
}
.root:global(.uui-slider-container):hover :global(.uui-slider-scale-dot):not(:global(.uui-slider-scale-filled-dot)) {
  border-color: #008ACE;
  background-color: #008ACE;
}
.root:global(.uui-slider-container) :global(.uui-slider-scale-filled-dot) {
  background-color: #008ACE;
  border-color: #008ACE;
}
.root:global(.uui-slider-container):global(.uui-active) :global(.uui-slider) {
  background-color: #008ACE;
}
.root:global(.uui-slider-container):global(.uui-active) :global(.uui-slider-scale-dot):not(:global(.uui-slider-scale-filled-dot)) {
  border-color: #008ACE;
  background-color: #008ACE;
}
.root:global(.uui-slider-container):global(.uui-disabled) {
  pointer-events: none;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider-filled) {
  background-color: #EBEDF5;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider-handle) {
  background-color: #EBEDF5;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider) {
  background-color: #EBEDF5;
}
.root:global(.uui-slider-container):global(.uui-disabled) :global(.uui-slider-scale-filled-dot) {
  background-color: #EBEDF5;
  border-color: #EBEDF5;
}