:global(.icon-container-color-info) {
  --icon-container-fill-color: var(--info-color);
}

:global(.icon-container-color-success) {
  --icon-container-fill-color: var(--success-color);
}

:global(.icon-container-color-warning) {
  --icon-container-fill-color: var(--warning-color);
}

:global(.icon-container-color-error) {
  --icon-container-fill-color: var(--error-color);
}

:global(.icon-container-color-default) {
  --icon-container-fill-color: var(--icon-color);
}

:global(.icon-container-color-secondary) {
  --icon-container-fill-color: var(--secondary-color);
}