/* Main Colors */
/* Gray */
.root :global(.uui-thumb-vertical), .root :global(.uui-thumb-horizontal) {
  background: #CED0DB;
  border-radius: 1.5px;
}
.root :global(.uui-thumb-vertical):hover, .root :global(.uui-thumb-horizontal):hover {
  border-radius: 3px;
}
.root :global(.uui-thumb-vertical):active, .root :global(.uui-thumb-horizontal):active {
  border-radius: 3px;
  background: #6C6F80;
}
.root :global(.uui-thumb-vertical) {
  width: 3px;
  margin-left: 3px;
}
.root :global(.uui-thumb-vertical):hover {
  width: 6px;
  margin-left: 0;
}
.root :global(.uui-thumb-vertical):active {
  width: 6px;
  margin-left: 0;
}
.root :global(.uui-thumb-horizontal) {
  height: 3px;
  margin-top: 3px;
}
.root :global(.uui-thumb-horizontal):hover {
  height: 6px;
  margin-top: 0;
}
.root :global(.uui-thumb-horizontal):active {
  height: 6px;
  margin-top: 0;
}