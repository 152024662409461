/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.search-input {
  --background-color: #474A59;
  --color: white;
  --fill: #ACAFBF;
  --placeholder-color: #ACAFBF;
  flex-basis: 200px;
  max-width: 500px;
  height: 36px;
  min-width: 36px;
  border-radius: 18px;
  background-color: var(--background-color);
  fill: var(--fill);
  padding: 0 6px;
  margin: 0 12px;
  transition: background-color 0.3s ease-out, flex-basis 0.3s ease-in-out;
}
.search-input :global(.uui-input) {
  color: var(--color);
  font-family: "Sans Regular", Arial, sans-serif;
}
.search-input :global(.uui-input)::placeholder {
  color: var(--placeholder-color);
}
.search-input :global(.uui-caption),
.search-input :global(.uui-input) {
  padding: 8px 6px;
  line-height: 18px;
  font-size: 14px;
}
.search-input :global(.uui-icon) svg {
  height: 16px;
  max-width: 16px;
}
.search-input :global(.uui-icon-cancel) svg {
  height: 24px;
  max-width: 24px;
}
.search-input :global(.uui-icon-cancel):hover {
  cursor: pointer;
}
.search-input:global(.uui-input-box) {
  min-height: 36px;
}