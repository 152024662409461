/* Main Colors */
/* Gray */
.fill-light {
  color: #303240;
}
.fill-light:global(.-clickable):hover {
  color: var(--caption-color);
}

.size-24 {
  padding-left: 0;
  padding-right: 0;
}
.size-24 :global(.uui-caption) {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
}
.size-24:first-of-type {
  margin-right: 6px;
}
.size-24:last-of-type {
  margin-left: 6px;
}

.size-30 {
  padding-left: 2px;
  padding-right: 2px;
}
.size-30 :global(.uui-caption) {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
}
.size-30:first-of-type {
  margin-right: 6px;
}
.size-30:last-of-type {
  margin-left: 6px;
}