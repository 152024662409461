.container {
  display: flex;
  flex-direction: column;
}
.container :global(.uui-input) {
  display: block;
  width: 100%;
  min-width: 0;
  outline: none;
  border-width: 1px;
}
.container :global(.uui-textarea-counter) {
  align-self: flex-end;
}

.auto-size {
  resize: none;
  overflow-y: hidden;
}

.no-auto-size {
  resize: vertical;
  overflow: auto;
}