/* Main Colors */
/* Gray */
.root :global(.uui-presets-container) {
  padding: 24px;
  font-family: "Sans Regular", Arial, sans-serif;
}
.root :global(.uui-presets-header) {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 12px;
}
.root :global(.uui-presets-item) {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: #008ACE;
  padding-bottom: 6px;
}
.root :global(.uui-presets-item):hover {
  color: #00689b;
  cursor: pointer;
}