:global(.text-input-vars) {
  --text-input-bg-color: var(--input-bg-color);
  --text-input-bg-color-disabled: var(--input-bg-disabled-color);
  --text-input-bg-color-readonly: var(--input-bg-readonly-color);
  --text-input-border-color: var(--input-border-color);
  --text-input-border-color-hover: var(--input-textinput-border-hover-color);
  --text-input-border-color-active: var(--input-border-checked-color);
  --text-input-border-color-error: var(--negative-color);
  --text-input-border-color-disabled: var(--input-border-disabled-color);
  --text-input-border-color-readonly: var(--input-border-readonly-color);
  --text-input-placeholder-color: var(--input-placeholder-color);
  --text-input-placeholder-color-disabled: var(--input-placeholder-disabled-color);
  --text-input-text-color: var(--input-text-color);
  --text-input-text-color-disabled: var(--input-text-disabled-color);
  --text-input-icon-color: var(--input-icon-color);
  --text-input-icon-color-disabled: var(--input-icon-disabled-color);
  --text-input-icon-color-clickable: var(--icon-color);
  --text-input-icon-color-clickable-hover: var(--icon-hover-color);
  --text-input-border-radius: var(--border-radius);
}