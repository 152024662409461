:global(.switch-vars) {
  --switch-bg-color: var(--input-switch-bg-color);
  --switch-bg-color-checked: var(--input-bg-checked-color);
  --switch-bg-color-hover: var(--input-bg-hover-color);
  --switch-bg-color-disabled: var(--input-bg-disabled-color);
  --switch-bg-color-checked-hover: var(--input-bg-checked-hover-color);
  --switch-bg-color-checked-disabled: var(--input-bg-checked-disabled-color);
  --switch-border-color: var(--input-border-color);
  --switch-border-color-checked: var(--input-border-checked-color);
  --switch-border-color-hover: var(--input-switch-border-hover-color);
  --switch-border-color-disabled: var(--input-border-disabled-color);
  --switch-border-color-checked-hover: var(--input-border-checked-hover-color);
  --switch-border-color-checked-disabled: var(--input-border-checked-disabled-color);
  --switch-label-color: var(--input-label-color);
  --switch-label-color-disabled: var(--input-label-disabled-color);
  --switch-toggler-bg-color: #fff;
  --switch-toggler-bg-color-disabled: var(--input-bg-disabled-color);
  --switch-toggler-border-color: var(--input-switch-bg-color);
  --switch-toggler-border-color-checked: var(--input-bg-checked-color);
  --switch-toggler-border-color-hover: var(--input-bg-hover-color);
  --switch-toggler-border-color-disabled: var(--input-border-disabled-color);
  --switch-toggler-border-color-checked-hover: var(--input-bg-checked-hover-color);
  --switch-toggler-border-color-checked-disabled: var(--input-bg-checked-disabled-color);
}