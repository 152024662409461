/* Main Colors */
/* Gray */
.root {
  --uui-dt-row-border-color: transparent;
  --uui-dt-row-border-width: 0;
}
.root:global(.-clickable) {
  cursor: pointer;
}
.root:global(.-clickable):hover {
  --uui-dt-row-background: #EBEDF5;
}
.root:global(.-draggable):hover {
  cursor: grab;
}
.root:global(.-draggable):active {
  cursor: grabbing;
}
.root:global(.uui-invalid) {
  --uui-dt-row-background: #FEF7F6;
}
.root:global(.uui-dragged-out) {
  opacity: 0.5;
}
.root:global(.uui-drag-ghost) {
  box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
}
.root:global(.uui-focus) {
  border: 1px solid #008ACE;
}

.size-24 {
  min-height: 24px;
}

.size-30 {
  min-height: 30px;
}

.size-36 {
  min-height: 36px;
}

.size-48 {
  min-height: 48px;
}

.size-60 {
  min-height: 60px;
}

.border-gray30 {
  --uui-dt-row-border-color: #E1E3EB;
  --uui-dt-row-border-width: 1px;
}

.background-white {
  --uui-dt-row-background: white;
}

.background-gray5 {
  --uui-dt-row-background: #FAFAFC;
}