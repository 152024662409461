/* Main Colors */
/* Gray */
.root {
  height: 312px;
}
.root :global(.uui-datepicker-container) {
  background-color: #FFFFFF;
}
.root :global(.uui-datepickerheader-header) {
  padding-top: 18px;
  padding-bottom: 6px;
}
.root :global(.uui-datepickerBody-wrapper) {
  padding: 0 18px 12px;
}
.root :global(.uui-datepickerheader-nav-title) {
  font-family: "Sans Semibold", Arial, sans-serif;
  color: #008ACE;
  font-size: 14px;
  padding: 3px 10px;
}
.root :global(.uui-datepickerheader-nav-title):hover {
  background-color: #ceefff;
  cursor: pointer;
}
.root :global(.uui-datepickerheader-nav-title):focus {
  outline: none;
}
.root :global(.uui-datepickerheader-nav-icon-right), .root :global(.uui-datepickerheader-nav-icon-left) {
  fill: #6C6F80;
  width: 36px;
  height: 36px;
  align-items: center;
}
.root :global(.uui-datepickerheader-nav-icon-right):hover, .root :global(.uui-datepickerheader-nav-icon-left):hover {
  cursor: pointer;
  background-color: #EBEDF5;
}
.root :global(.uui-datepickerheader-nav-icon-right):focus, .root :global(.uui-datepickerheader-nav-icon-left):focus {
  outline: none;
}
.root :global(.uui-datepickerheader-nav-icon-right) {
  transform: rotate(-90deg);
}
.root :global(.uui-datepickerheader-nav-icon-left) {
  transform: rotate(90deg);
}
.root :global(.uui-monthselection-content), .root :global(.uui-yearselection-content) {
  font-family: "Sans Regular", Arial, sans-serif;
}
.root :global(.uui-monthselection-month-container) {
  margin: 0 0 12px;
  display: flex;
  flex-direction: column;
}
.root :global(.uui-monthselection-month-container) > * {
  display: flex;
}
.root :global(.uui-yearselection-year-container) {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px;
}
.root :global(.uui-yearselection-year-container) > * {
  display: flex;
}
.root :global(.uui-yearselection-year-row) {
  display: flex;
  justify-content: space-between;
  width: 252px;
}
.root :global(.uui-monthselection-month) {
  width: 72px;
  height: 36px;
  border-radius: 20px;
  margin: 6px;
  color: #303240;
}
.root :global(.uui-monthselection-month):hover {
  width: 70px;
  height: 34px;
  border: 1px solid #008ACE;
  cursor: pointer;
}
.root :global(.uui-yearselection-year) {
  width: 63px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 6px 0;
  color: #303240;
}
.root :global(.uui-yearselection-year):hover {
  width: 61px;
  height: 34px;
  border: 1px solid #008ACE;
  cursor: pointer;
}
.root :global(.uui-monthselection-current-month):global(.uui-monthselection-month) {
  background-color: #008ACE;
  color: #FFFFFF;
}
.root :global(.uui-yearselection-current-year):global(.uui-yearselection-year) {
  background-color: #008ACE;
  color: #FFFFFF;
}

.date-input {
  width: 100%;
}