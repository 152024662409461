/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.search-input {
  --background-color: #303240;
  --color: #CED0DB;
  --fill: #ACAFBF;
  --placeholder-color: #ACAFBF;
  height: 60px;
  background-color: var(--background-color);
  fill: var(--fill);
  padding: 0 24px;
  flex-shrink: 0;
  width: auto;
}
.search-input :global(.uui-input) {
  color: var(--color);
  font-family: "Sans Regular", Arial, sans-serif;
}
.search-input :global(.uui-input)::placeholder {
  color: var(--placeholder-color);
}
.search-input :global(.uui-caption),
.search-input :global(.uui-input) {
  padding: 18px 16px;
  line-height: 24px;
  font-size: 14px;
}
.search-input :global(.uui-icon) svg {
  height: 16px;
  max-width: 16px;
}
.search-input :global(.uui-icon-cancel) svg {
  height: 24px;
  max-width: 24px;
}
.search-input :global(.uui-icon-cancel):hover {
  cursor: pointer;
}