@import 'styles/common.scss';

#root {
  .edit-showroom-modal {
    width: 690px !important;

    &_tabs {
      height: 84px !important;
      padding: 24px;
      align-items: center;
    }

    &_required-tab {
      border: 2px solid #008ace !important;
      color: #008ace !important;
      margin: 0px 6px !important;
      border-radius: 100px !important;
      background-color: #ceefff !important;
    }

    &_required-tab-disabled {
      border: 2px solid #acafbf !important;
      color: #acafbf !important;
      margin: 0px 6px !important;
      border-radius: 100px !important;
      background-color: #ced0db !important;
    }

    &_required-tab-text {
      color: #008ace !important;
      margin: 0px 6px !important;
    }

    &__warning-alert {
      margin-left: 24px;
      padding: 12px 0;
    }

    &__main {
    	button[uui-disabled] {
				svg {
					path {
						fill: lightgrey;
					}
				}
			}
      .edit-showroom-modal {
        &__body {
          overflow: auto !important;

          .showroom-label {
            .uui-label-top {
              .uui-label {
                margin-bottom: 0px;
              }
            }
          }

          &__description {
            overflow: visible; // override default text overflow
            padding: 12px 24px !important;
            font-size: 14px;
            line-height: 24px !important;
          }

          &__status {
            padding: 12px 24px;

            &__title {
              margin: 0 !important; // override default margin
              padding: 0 0 8px 0 !important; // override default padding
              font-weight: 600 !important;
              font-size: 14px;
              line-height: 18px;
            }

            .error-msg-section {
              min-height: auto;
              padding-left: 206px;

              & .uui-invalid-message {
                margin-top: 0;
              }
            }

          }

          .modal-footer {
            padding: 12px 24px;
          }
        }
      }
    }

    .showroom-label {
      width: 150px !important;
      border: 1px solid #e1e3eb;
      background-color: #fafafc;
      min-height: 36px !important;
      padding: 8px;
      font-size: 12px !important;
      font-style: normal !important;
      position: relative;

      &_add {
        width: 23.5% !important;
        margin-left: 37px !important;

        & .uui-caption {
          flex: none;
        }
      }

      &__files {
        width: 150px !important;
        background-color: #fafafc;
        border: 1px solid #e1e3eb;
        display: flex;
        height: 101px !important;
        color: red;
        justify-content: left;
        padding: 40px 10px;
      }

      &__input-block {
        width: 100%;
      }

      &__invalid-field {
        border: 1px solid #E54322;
      }

      .uui-label-top {
        display: inline-block;
      }
    }

    .showroom-drop-spot {
      &__invalid-field {
        border: 2px dashed #E54322;
      }
    }

    .links-dropdown {
      align-items: flex-start;
      margin-left: 37px;

      &__items {
        width: 100%
      }
    }
  }

  .icon-name-container {
    gap: 16px !important; // override default gap
  }

  .flexSpacer {
    background-color: #ced0db !important;
    height: 2px !important;
    width: 118px !important;
    margin: 0 12px !important;
  }

  .doneButton {
    height: 48px !important;
    width: 48px !important;
  }

  .uploaded-file {
    height: 101px !important;
    width: 250px !important;

    &__old {
      div[style] {
        & div:not(:first-child) {
          display: none;
        }
      }
    }
  }
}
