@import 'styles/common.scss';

div.useful-links {
  justify-self: start;
  flex-direction: row;
  align-items: stretch;
  gap: 15px;

  .link-container {
    flex: 1;
    border: 1px solid #008ace;
    border-radius: 8px;
    padding: 15px;
  }

  .link-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    .column-head {
      font-weight: 600;
      font-family: 'Source Sans Pro', Helvetica, serif;
      padding: 0;
    }

    .category-head {
      font-weight: 600;
      font-family: 'Source Sans Pro', Helvetica, serif;
      padding: 0;

      &:not(:first-child) {
        margin-top: 15px;
      }
    }

    .item-caption {
      font-weight: 400;
      font-family: 'Source Sans Pro', Helvetica, serif;
      text-align: left;
      font-size: 14px;
      line-height: 24px;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .item {
      color: #008ace;
      align-items: center;
      padding: 0 20px 0 0;
      margin-top: 8px;
      padding-right: 0px;

      & svg {
        width: 24px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .useful-links {
    flex-direction: column !important;

    .link-column {
      flex: 1 0 auto;
    }
  }
}
