.container {
  flex-wrap: nowrap;
}

.target {
  width: 18px;
  padding: 0;
}

.panel {
  padding-top: 6px;
  padding-bottom: 6px;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: var(--divider-color);
}

.input {
  flex-basis: 200px;
}

.notification {
  overflow: visible;
}
.notification:after {
  display: block;
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: var(--negative-color);
  border-radius: 50%;
  right: -3px;
  top: -3px;
}