div .badge {
  border: none;
  border-radius: unset;
  white-space: nowrap;
  flex-shrink: 0;
  margin-right: 8px;

  &__caption {
    color: black;
  }
}
