/* Main Colors */
/* Gray */
.filter-sort-button:global(.-clickable):hover {
  background-color: #FFFFFF;
}
.filter-sort-button:global(.-clickable):hover .sort-text {
  color: #008ACE;
}
.filter-sort-button:global(.-clickable):hover :global(.uui-icon) {
  fill: #008ACE;
}
.filter-sort-button:global(.-clickable) .active-text {
  color: #008ACE;
}
.filter-sort-button :global(.uui-icon) {
  margin-left: 12px;
}
.filter-sort-button:global(.uui-button-box):global(.-clickable):hover {
  background-color: white;
  color: #008ACE;
  fill: #008ACE;
  border-color: transparent;
}

.sorting-panel-container {
  padding: 6px 0;
  border-bottom: 1px solid #CED0DB;
}
@media screen and (max-width: 720px) {
  .sorting-panel-container {
    padding: 0;
  }
}

.sort-active {
  margin-right: 12px;
}