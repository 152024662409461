.root :global(.uui-tooltip-body) {
  padding: 3px 12px;
  line-height: 24px;
  font-size: 14px;
  font-family: var(--font-regular);
  box-shadow: var(--tooltip-shadow);
  max-width: 300px;
  word-wrap: break-word;
  border-radius: var(--tooltip-border-radius);
}
.root :global(.uui-tooltip-arrow) {
  position: absolute;
  width: 12px;
  height: 12px;
  overflow: hidden;
}
.root :global(.uui-tooltip-arrow)::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  box-shadow: var(--tooltip-arrow-shadow);
}
.root:global(.uui-tooltip-container[data-placement^="right"]) :global(.uui-tooltip-arrow) {
  right: 100%;
}
.root:global(.uui-tooltip-container[data-placement^="right"]) :global(.uui-tooltip-arrow)::after {
  transform: translateX(100%) translateY(20%) rotate(45deg);
}
.root:global(.uui-tooltip-container[data-placement^="left"]) :global(.uui-tooltip-arrow) {
  left: 100%;
}
.root:global(.uui-tooltip-container[data-placement^="left"]) :global(.uui-tooltip-arrow)::after {
  transform: translateX(-50%) translateY(20%) rotate(45deg);
}
.root:global(.uui-tooltip-container[data-placement^="top"]) :global(.uui-tooltip-arrow) {
  top: 100%;
}
.root:global(.uui-tooltip-container[data-placement^="top"]) :global(.uui-tooltip-arrow)::after {
  transform: translateX(30%) translateY(-50%) rotate(45deg);
}
.root:global(.uui-tooltip-container[data-placement^="bottom"]) :global(.uui-tooltip-arrow) {
  bottom: 100%;
}
.root:global(.uui-tooltip-container[data-placement^="bottom"]) :global(.uui-tooltip-arrow)::after {
  transform: translateX(30%) translateY(100%) rotate(45deg);
}
.root :global(.uui-tooltip-body) {
  background-color: var(--tooltip-bg-color);
  color: var(--tooltip-text-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="right"])::after {
  background: var(--tooltip-bg-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="left"])::after {
  background: var(--tooltip-bg-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="top"])::after {
  background: var(--tooltip-bg-color);
}
.root:global(.uui-tooltip-container) :global(.uui-popover-arrow[data-placement^="bottom"])::after {
  background: var(--tooltip-bg-color);
}