.garage-users-table {
  padding-top: 24px;

  .warning-alert {
    margin-bottom: 16px;
  }

  .toggle-role-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    .overlay {
      content: '';
      position: absolute;
      background: transparent;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 18px;
      height: 20px;
      pointer-events: none;
    }

    .checkbox-role {
      & > div.uui-checkbox {
        margin-top: 0;
      }
    }
  }

  .column-name {
    padding-top: 6px;
    padding-bottom: 6px;
    display: flex;

    .user-avatar {
      flex: 0 0 36px;
      margin-right: 12px;
    }

    .user-information {
      color: #303240;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    .user-contacts {
      display: flex;
      align-items: center;
      margin-top: 6px;

      .teams-icon,
      .mail-icon {
        margin-right: 12px;
      }
    }
  }
}
