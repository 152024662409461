.sticky-header {
  position: sticky;
  top: 0;
  isolation: isolate;
  z-index: 5;
}

.listContainer {
  margin-top: 1px;
  isolation: isolate;
}

.table :global(.-scrolled-left) :global(.uui-scroll-shadow-left) {
  opacity: 1;
}
.table :global(.-scrolled-right) :global(.uui-scroll-shadow-right) {
  opacity: 1;
}
.table :global(.uui-scroll-shadow-top) {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: -5px;
}
.table :global(.uui-scroll-shadow-top-visible) {
  display: block;
  height: 5px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
  transition: display 200ms;
}

.no-results {
  position: sticky;
  left: 0;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.no-results-icon {
  align-items: center;
}
.no-results-title {
  padding: 0;
  margin-top: 30px;
}