/* Main Colors */
/* Gray */
.global-menu-btn {
  --background-color-hover: #1D1E26;
  --background-color-active: #1D1E26;
  --fill: white;
  display: block;
  height: 60px;
  width: 60px;
  fill: var(--fill);
  flex-shrink: 0;
  cursor: pointer;
}
.global-menu-btn:hover {
  background-color: var(--background-color-hover);
}
.global-menu-btn:active {
  background-color: var(--background-color-active);
}

.globalMenuIcon svg {
  width: 60px;
  height: 60px;
}