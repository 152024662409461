@import '../../../../styles/common.scss';

.generate-report {
  &__modalHeader {
    font-family: $sans-pro-source;
    font-size: 18px;
    font-weight: 600;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  &__notification {
    width: 100% !important;
    margin-bottom: 20px;
  }
  &__modalContent {
    margin-right: 24px;
    margin-left: 24px;
  }
  & &__radioGroup {
    margin-left: 8px;
  }
  & &__dateRow {
    display: flex;
    justify-content: space-between;
  }
  &__startDate {
    width: 10.6rem;
    height: 4rem;
  }
  & &__startDateLabel label {
    font-family: $sans-pro-regular;
  }
  & &__endDateLabel label {
    font-family: $sans-pro-regular;
  }
  &__endDate {
    width: 10.6rem;
    margin-right: 8px;
    height: 4rem;
  }
  &__buttons {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  & &__cancelButton {
    margin-right: 12px;
  }
}
