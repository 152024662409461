/* Main Colors */
/* Gray */
.row-wrapper {
  position: relative;
  padding: 0 24px 0 0;
  width: 100%;
  box-sizing: border-box;
  touch-action: auto;
}
.row-wrapper.not-pinned:not(:hover) .pin-icon-button {
  visibility: hidden;
}
.row-wrapper:hover {
  box-shadow: 0 0 1px #ACAFBF;
}
.row-wrapper:hover, .row-wrapper:hover .title, .row-wrapper:hover .pin-icon-button {
  background-color: #F5F6FA;
}
.row-wrapper .drag-handle {
  height: 12px;
  background-position: right;
  background-repeat: repeat-y;
  padding: 6px 0 6px 24px;
  background-position-y: -2px;
  opacity: 1;
  touch-action: none;
}
.row-wrapper .drag-handle.dnd-disabled {
  opacity: 0.3;
  touch-action: auto;
}
.row-wrapper:global(.uui-drag-ghost) {
  background-color: #FFFFFF;
  box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
}