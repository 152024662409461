.modal-blocker {
  animation-duration: 0.3s;
  animation-name: animateModalBlocker;
  opacity: 1;
  transition: all 0.3s ease-out;
}
@keyframes animateModalBlocker {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal-blocker.blocker-overlay :global(.uui-modal-blocker) {
  background-color: var(--modals-overlay-color);
}

.modal:global(.uui-modal-window) {
  background-color: var(--modals-bg-color);
  overflow: auto;
  border-radius: var(--modals-border-radius);
  max-height: 80%;
  max-width: 100vw;
  box-shadow: var(--modals-shadow);
}
@media (max-width: 640px) {
  .modal:global(.uui-modal-window) {
    min-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  }
}
.modal:global(.uui-modal-window) :global(.uui-shadow-top)::before {
  background: var(--divider-color);
  height: 1px;
}
.modal:global(.uui-modal-window) :global(.uui-shadow-bottom)::after {
  background: var(--divider-color);
  height: 1px;
}

.modal-footer {
  border-bottom-left-radius: var(--modals-border-radius);
  border-bottom-right-radius: var(--modals-border-radius);
}
.modal-footer.border-top {
  border-top: 1px solid var(--divider-color);
}

.modal-header {
  border-top-left-radius: var(--modals-border-radius);
  border-top-right-radius: var(--modals-border-radius);
}