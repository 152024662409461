.root :global(.uui-accordion-toggler) {
  font-family: "Sans Semibold", Arial, sans-serif;
  cursor: pointer;
}
.root :global(.uui-accordion-body) {
  font-family: "Sans Regular", Arial, sans-serif;
}
.root.mode-block :global(.uui-accordion-toggler) {
  background: var(--background-color);
  box-shadow: 0 3px 6px 0 rgba(29, 30, 38, 0.1), 0 1px 3px 0 rgba(29, 30, 38, 0.1);
  fill: var(--icon-color);
  line-height: 24px;
  font-size: 18px;
  padding: 0 18px;
}
.root.mode-block :global(.uui-accordion-toggler) :global(.uui-accordion-toggle-container) {
  min-height: 48px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.root.mode-block :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--text-color);
}
.root.mode-block :global(.uui-accordion-toggler):hover, .root.mode-block :global(.uui-accordion-toggler):focus {
  box-shadow: 0 6px 12px 0 rgba(29, 30, 38, 0.1), 0 3px 6px 0 rgba(29, 30, 38, 0.1);
}
.root.mode-block :global(.uui-accordion-body) {
  padding: 18px;
  line-height: 24px;
  letter-spacing: 0;
  background: var(--background-color);
  box-shadow: 0 3px 6px 0 rgba(29, 30, 38, 0.1), 0 1px 3px 0 rgba(29, 30, 38, 0.1);
  border-top: 1px solid var(--border-color);
  overflow-wrap: anywhere;
}
.root.mode-block:global(.uui-opened) :global(.uui-accordion-toggler):hover, .root.mode-block:global(.uui-opened) :global(.uui-accordion-toggler):focus {
  border-color: var(--border-color);
}
.root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler) {
  cursor: default;
  fill: var(--icon-color-disabled);
}
.root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--text-color-disabled);
}
.root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler):hover, .root.mode-block:global(.uui-disabled) :global(.uui-accordion-toggler):focus {
  box-shadow: 0 3px 6px 0 rgba(29, 30, 38, 0.1), 0 1px 3px 0 rgba(29, 30, 38, 0.1);
}
.root.mode-inline :global(.uui-accordion-toggler) {
  border-bottom: 1px solid var(--border-color);
  fill: var(--icon-color);
  line-height: 18px;
  font-size: 14px;
  padding: 8px 12px 8px;
}
.root.mode-inline :global(.uui-accordion-toggler) :global(.uui-accordion-toggle-container) {
  min-height: 24px;
}
.root.mode-inline :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--text-color);
}
.root.mode-inline :global(.uui-accordion-body) {
  padding: 1px 12px 12px;
  border-bottom: 1px solid var(--border-color);
}
.root.mode-inline:global(.uui-opened) :global(.uui-accordion-toggler) {
  border-bottom: unset;
}
.root.mode-inline:global(.uui-disabled) :global(.uui-accordion-toggler) {
  cursor: default;
  fill: var(--icon-color-disabled);
  border-bottom: unset;
}
.root.mode-inline:global(.uui-disabled) :global(.uui-accordion-toggler) :global(.uui-accordion-title) {
  color: var(--text-color-disabled);
}
.root.padding-0 :global(.uui-accordion-toggler), .root.padding-0 :global(.uui-accordion-body) {
  padding-left: 0px;
  padding-right: 0px;
}
.root.padding-6 :global(.uui-accordion-toggler), .root.padding-6 :global(.uui-accordion-body) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.padding-12 :global(.uui-accordion-toggler), .root.padding-12 :global(.uui-accordion-body) {
  padding-left: 12px;
  padding-right: 12px;
}
.root.padding-18 :global(.uui-accordion-toggler), .root.padding-18 :global(.uui-accordion-body) {
  padding-left: 18px;
  padding-right: 18px;
}