/* Main Colors */
/* Gray */
.file-card-wrapper {
  box-sizing: border-box;
  min-height: 48px;
  padding: 5px;
  border: 1px solid #CED0DB;
}
.file-card-wrapper .file-name {
  white-space: nowrap;
}

.error-card-wrapper {
  border: 1px solid #E54322;
}

:global(.uui-loading) .default-color,
:global(.uui-loading) .doc-color,
:global(.uui-loading) .xls-color,
:global(.uui-loading) .pdf-color,
:global(.uui-loading) .movie-color,
:global(.uui-loading) .img-color {
  fill: #CED0DB;
}

.default-color {
  fill: #6C6F80;
}

.doc-color {
  fill: #2B579A;
}

.xls-color {
  fill: #217346;
}

.pdf-color {
  fill: #FA0F01;
}

.movie-color {
  fill: #5214CC;
}

.img-color {
  fill: #E67E17;
}

.drop-start {
  border: 2px dashed #008ACE;
  background-color: #ceefff;
}

.error-block {
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 12px;
  color: #be3316;
  display: inline-flex;
  align-items: center;
  padding: 0;
  cursor: pointer;
}
.error-block svg {
  margin-right: 4px;
  fill: #E54322;
}

.icons-block {
  min-width: 18px;
  flex-basis: 0;
}