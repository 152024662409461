/* Main Colors */
/* Gray */
.root {
  --text-color: #474A59;
  --icon-color: #6C6F80;
}
.root:global(.uui-timepicker-container) {
  display: flex;
  padding: 15px 12px;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-input):global(.uui-input-box) {
  margin: 6px 0;
  width: 36px;
  height: 36px;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-input) > input {
  text-align: center;
  font-size: 24px;
  line-height: 24px;
  color: var(--text-color);
  font-family: "Sans Regular", Arial, sans-serif;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-item) {
  position: relative;
  padding: 9px 24px;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-item:first-child)::after {
  content: ":";
  position: absolute;
  top: 35px;
  left: 81px;
  color: var(--text-color);
  font-size: 24px;
  line-height: 30px;
}
.root:global(.uui-timepicker-container) .icon {
  align-items: center;
  fill: var(--icon-color);
}
.root:global(.uui-timepicker-container) .icon:hover {
  cursor: pointer;
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-up) {
  align-items: center;
  fill: var(--icon-color);
  transform: rotate(180deg);
}
.root:global(.uui-timepicker-container) :global(.uui-timepicker-icon-down) {
  align-items: center;
  fill: var(--icon-color);
}
.root :global(.uui-dropdown-body) {
  align-items: center;
}

.date-input {
  width: 100%;
}