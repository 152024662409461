.event-edit-form {
  width: 690px !important;

  .event-field {
    .uui-pale {
      fill: var(--icon-color-disabled);
      background-color: var(--background-color-disabled);
      border-color: var(--border-color-disabled);
      .selected-item {
        background-color: #ceefff;
        /* Puedes agregar más estilos si es necesario */
      }
    }

    .uui-pale:hover {
      border-color: var(--border-color-disabled);
    }

    .uui-pale input {
      color: var(--text-color-disabled);
    }

    .footnotes {
      justify-content: space-between;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .max-width {
      max-width: 159px;
    }

    .invalid-input {
      color: #e54322;
    }
  }

  .event-form-wrapper {
    padding: 12px 24px;
    line-height: 18px;
  }

  .event-edit-header {
    margin-bottom: 6px;
  }

  .has-margin-bottom {
    margin-bottom: 12px;
  }
  .align-bottom {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 6px;
  }

  .has-margin-top {
    margin-top: 12px;
  }

  .event-form-section {
    margin-top: 12px;
  }

  .child-label {
    .uui-label {
      font-weight: 400;
      font-family: 'Sans Regular', Arial, sans-serif;
    }
  }
}
.invalid {
  color: #e54322 !important;
}
