@import '../../../../../styles/base';

.project-date-container {
  padding-bottom: 24px !important;
  position: relative;

  .uui-label-top {
    .uui-label {
      font-family: $sans-pro-source;
      font-weight: 600;
    }
  }
}
