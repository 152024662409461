:global(.icon-button-color-info) {
  --icon-button-icon-color: var(--info-color);
  --icon-button-icon-color-hover: var(--info-hover-color);
  --icon-button-icon-color-active: var(--info-active-color);
  --icon-button-icon-color-disabled: var(--icon-disabled-color);
}

:global(.icon-button-color-success) {
  --icon-button-icon-color: var(--success-color);
  --icon-button-icon-color-hover: var(--success-hover-color);
  --icon-button-icon-color-active: var(--success-active-color);
  --icon-button-icon-color-disabled: var(--icon-disabled-color);
}

:global(.icon-button-color-warning) {
  --icon-button-icon-color: var(--warning-color);
  --icon-button-icon-color-hover: var(--warning-hover-color);
  --icon-button-icon-color-active: var(--warning-active-color);
  --icon-button-icon-color-disabled: var(--icon-disabled-color);
}

:global(.icon-button-color-error) {
  --icon-button-icon-color: var(--error-color);
  --icon-button-icon-color-hover: var(--error-hover-color);
  --icon-button-icon-color-active: var(--error-active-color);
  --icon-button-icon-color-disabled: var(--icon-disabled-color);
}

:global(.icon-button-color-default) {
  --icon-button-icon-color: var(--icon-color);
  --icon-button-icon-color-hover: var(--icon-hover-color);
  --icon-button-icon-color-active: var(--icon-active-color);
  --icon-button-icon-color-disabled: var(--icon-disabled-color);
}

:global(.icon-button-color-secondary) {
  --icon-button-icon-color: var(--secondary-color);
  --icon-button-icon-color-hover: var(--secondary-hover-color);
  --icon-button-icon-color-active: var(--secondary-active-color);
  --icon-button-icon-color-disabled: var(--secondary-disabled-color);
}