/* Main Colors */
/* Gray */
.root {
  --uui-dt-row-background: #FAFAFC;
  --uui-dt-row-border-color: #E1E3EB;
  --uui-dt-row-border-width: 1px;
}

.header-cell {
  background: none;
}

.config-icon {
  position: absolute;
  height: 100%;
  right: 24px;
  z-index: 2;
}