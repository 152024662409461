.cell {
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  display: flex;
  align-items: flex-start;
  background-color: var(--uui-dt-cell-background);
  border-width: var(--uui-dt-cell-border-width);
  margin-left: calc(var(--uui-dt-cell-border-width) * -1);
  border-left-style: solid;
  border-right-style: solid;
  border-top-style: none;
  border-bottom-style: none;
  border-left-color: var(--uui-dt-cell-border-color);
  border-right-color: var(--uui-dt-cell-border-color);
}
.cell:first-child {
  border-left-color: transparent;
}
.cell:last-child {
  border-right-color: transparent;
}

.editor-wrapper {
  flex: 1 1 0;
  min-width: 0;
  position: relative;
  align-self: stretch;
  background-color: var(--uui-dt-cell-editor-background);
}

.overlay {
  position: absolute;
  pointer-events: none;
  top: calc(var(--uui-dt-row-border-width) * -1);
  bottom: calc(var(--uui-dt-row-border-width) * -1);
  left: calc(var(--uui-dt-cell-border-width) * -1);
  right: calc(var(--uui-dt-cell-border-width) * -1);
  border-width: var(--uui-dt-cell-editor-border-width);
  border-style: solid;
  border-color: transparent;
  visibility: hidden;
}
.editor-wrapper:hover .overlay {
  z-index: 1;
  visibility: visible;
  border-color: var(--uui-dt-cell-editor-hover-border-color);
}
.cell:global(.uui-focus) .overlay {
  z-index: 2;
  visibility: visible;
  border-color: var(--uui-dt-cell-editor-focus-border-color);
}