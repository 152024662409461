:global(.radio-input-vars) {
  --radio-input-bg-color: var(--input-bg-color);
  --radio-input-bg-color-disabled: var(--input-bg-disabled-color);
  --radio-input-border-color: var(--input-border-color);
  --radio-input-border-color-checked: var(--input-border-checked-color);
  --radio-input-border-color-hover: var(--input-border-hover-color);
  --radio-input-border-color-disabled: var(--input-border-disabled-color);
  --radio-input-border-color-checked-disabled: var(--input-border-checked-disabled-color);
  --radio-input-label-color: var(--input-label-color);
  --radio-input-label-color-disabled: var(--input-label-disabled-color);
  --radio-input-fill-color-checked: var(--input-border-checked-color);
  --radio-input-fill-color-hover: var(--input-border-hover-color);
  --radio-input-fill-color-checked-disabled: var(--input-border-checked-disabled-color);
  --radio-input-error-color: var(--negative-color);
}