/* Main Colors */
/* Gray */
.text.theme-dark {
  color: #FAFAFC;
}
.text.theme-dark.size-12 b, .text.theme-dark.size-12 strong {
  color: #F5F6FA;
}
.text.theme-dark pre, .text.theme-dark code {
  color: #1D1E26;
  background-color: #F5F6FA;
}