@import 'styles/common.scss';

.project-detail-left__description {
  white-space: pre-line;
  
  & &-header {
    padding-top: 0px;
    padding-bottom: 8px;
  }
}
