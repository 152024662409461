/* Main Colors */
/* Gray */
.checkbox-color-blue {
  --border-color-default: #CED0DB;
  --border-color-hovered: #0079b5;
  --border-color-disabled: #E1E3EB;
  --border-color-checked-disabled: #b5e6ff;
  --background-color: white;
  --background-color-checked: #008ACE;
  --background-color-disabled: #FAFAFC;
  --background-color-checked-hovered: #0079b5;
  --background-color-checked-disabled: #b5e6ff;
  --errored-color: #E54322;
  --fill-color: white;
  --text-color-default: #303240;
  --text-color-disabled: #6C6F80;
}