/* Main Colors */
/* Gray */
.root {
  background-color: var(--background-color);
  border-left: 6px solid var(--fill-color);
}
.root .icon-wrapper :global(.uui-icon) {
  fill: var(--fill-color);
}

.alert-wrapper {
  padding: 12px 18px 12px 12px;
  display: flex;
  overflow: hidden;
  min-width: 170px;
}

.icon-wrapper {
  height: 100%;
  margin-right: 14px;
}

.action-wrapper {
  display: flex;
}

.action-link:not(:last-child) {
  margin-right: 12px;
}

.close-icon {
  height: 100%;
  margin-left: 17px;
}

.main-path {
  width: 100%;
  display: flex;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}