/* Main Colors */
/* Gray */
.menuRoot {
  padding: 0;
  margin: 0;
}
.menuRoot:focus {
  outline: none;
}

.bodyRoot {
  box-shadow: 0 3px 6px 0 rgba(29, 30, 38, 0.1), 0 1px 3px 0 rgba(29, 30, 38, 0.1);
  color: #303240;
  fill: #6C6F80;
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
  padding: 0.5em 0;
  background-color: #FFFFFF;
}
.bodyRoot :global(.uui-icon) svg {
  height: 24px;
  max-width: 24px;
}

.submenuRootItem .iconAfter {
  transform: rotate(-90deg);
}

.iconCheck {
  color: transparent;
  fill: transparent;
}

.splitterRoot {
  display: flex;
  width: 100%;
}
.splitterRoot .splitter {
  width: 100%;
  border: 0;
  border-top: 1px solid #CED0DB;
  height: 0;
}

.headerRoot {
  color: #6C6F80;
  fill: #6C6F80;
  box-sizing: border-box;
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding: 0.5em 1em;
  width: 100%;
  cursor: default;
  font-size: 0.9em;
}
.headerRoot:focus {
  outline: none;
}

.itemRoot {
  box-sizing: border-box;
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 14px;
  line-height: 18px;
  padding: 0.5em 1em;
  width: 100%;
  color: #303240;
  fill: #6C6F80;
}
.itemRoot :global(.uui-icon) svg {
  height: 24px;
  max-width: 24px;
}
.itemRoot:global(.uui-active) {
  color: #008ACE;
  fill: #008ACE;
}
.itemRoot:global(.uui-active):hover, .itemRoot:global(.uui-active):focus {
  color: #008ACE !important;
  fill: #008ACE !important;
}
.itemRoot:global(.uui-active) .iconCheck {
  color: #008ACE;
  fill: #008ACE;
}
.itemRoot:focus {
  outline: none;
}
.itemRoot .icon {
  justify-content: flex-start;
}
.itemRoot.link {
  display: flex;
  align-items: center;
}
.itemRoot.link:global(.uui-active) {
  color: #008ACE;
  fill: #008ACE;
}
.itemRoot.link:global(.uui-active):hover, .itemRoot.link:global(.uui-active):focus {
  color: #008ACE !important;
  fill: #008ACE !important;
}
.itemRoot.link:global(.uui-active) .iconCheck {
  color: #008ACE;
  fill: #008ACE;
}
.itemRoot.link:focus {
  outline: none;
}
.itemRoot:global(.-clickable:not(.uui-disabled)):hover, .itemRoot:global(.-clickable:not(.uui-disabled)):focus {
  background-color: #E1E3EB;
  cursor: pointer;
}
.itemRoot:global(.uui-disabled) {
  color: #ACAFBF;
  fill: #ACAFBF;
}
.itemRoot:global(.uui-disabled):hover, .itemRoot:global(.uui-disabled):focus {
  background-color: transparent;
  cursor: default;
}
.itemRoot:global(.uui-opened) {
  background-color: #E1E3EB;
  fill: #303240;
}
.itemRoot > *:not(:last-child) {
  margin: 0 0.5em 0 0;
}

.selected-checkmark {
  margin-left: auto;
  fill: #008ACE;
}
.selected-checkmark:hover, .selected-checkmark:focus {
  fill: #008ACE;
}