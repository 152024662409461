/* Main Colors */
/* Gray */
.root {
  --background-color: white;
  --background-color-disabled: #FAFAFC;
  --border-color: #CED0DB;
  --border-color-checked: #008ACE;
  --border-color-hovered: #0079b5;
  --border-color-disabled: #E1E3EB;
  --border-color-checked-disabled: #b5e6ff;
  --caption-color: #303240;
  --caption-color-disabled: #6C6F80;
  --fill-color-checked: #008ACE;
  --fill-color-hovered: #0079b5;
  --fill-color-checked-disabled: #b5e6ff;
  --errored-color: #E54322;
}
.root:hover :global(.uui-radioinput), .root:focus-within :global(.uui-radioinput) {
  border-color: var(--border-color-hovered);
}
.root:hover :global(.uui-radioinput):global(.uui-checked), .root:focus-within :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--border-color-hovered);
}
.root:hover :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon), .root:focus-within :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--fill-color-hovered);
}
.root:hover:global(.uui-disabled) :global(.uui-radioinput), .root:hover:global(.uui-readonly) :global(.uui-radioinput), .root:focus-within:global(.uui-disabled) :global(.uui-radioinput), .root:focus-within:global(.uui-readonly) :global(.uui-radioinput) {
  background-color: var(--background-color-disabled);
  border-color: var(--border-color-disabled);
}
.root:hover:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:hover:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon), .root:focus-within:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:focus-within:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--fill-color-checked-disabled);
}
.root :global(.uui-radioinput) {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  align-self: baseline;
  flex-shrink: 0;
  box-sizing: border-box;
}
.root :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--border-color-checked);
}
.root :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--fill-color-checked);
}
.root :global(.uui-input-label) {
  color: var(--caption-color);
  flex-grow: 1;
  font-family: "Sans Regular", Arial, sans-serif;
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.root :global(.uui-input-label) b, .root :global(.uui-input-label) strong {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.root :global(.uui-input-label) i, .root :global(.uui-input-label) em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.root :global(.uui-input-label) a {
  display: inline-flex;
  align-items: center;
  color: #008ACE;
}
.root :global(.uui-input-label) a:visited {
  color: #5214CC;
}
.root :global(.uui-input-label) a:visited:hover {
  color: #3F0F9E;
}
.root :global(.uui-input-label) a:hover {
  text-decoration: underline;
  color: #0079b5;
}
.root :global(.uui-input-label) code {
  padding: 0.125em 0.25em;
  color: #303240;
  background-color: #E1E3EB;
  font-family: "Roboto Mono", Arial, sans-serif;
  white-space: pre-wrap;
  word-wrap: normal;
}
.root:global(.uui-invalid) :global(.uui-icon) {
  fill: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-input-label) {
  color: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-radioinput) {
  border-color: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-checked):global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--errored-color);
}
.root:global(.uui-invalid):hover :global(.uui-radioinput), .root:global(.uui-invalid):focus-within :global(.uui-radioinput) {
  border-color: var(--errored-color);
}
.root:global(.uui-invalid):hover :global(.uui-icon), .root:global(.uui-invalid):focus-within :global(.uui-icon) {
  fill: var(--errored-color);
}
.root:global(.uui-disabled), .root:global(.uui-readonly) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-radioinput), .root:global(.uui-readonly) :global(.uui-radioinput) {
  cursor: default;
  background-color: var(--background-color-disabled);
  border-color: var(--border-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--fill-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput):global(.uui-checked), .root:global(.uui-readonly) :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--border-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon), .root:global(.uui-readonly) :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--fill-color-checked-disabled);
}
.root:global(.uui-disabled):global(.uui-invalid) :global(.uui-radioinput), .root:global(.uui-readonly):global(.uui-invalid) :global(.uui-radioinput) {
  cursor: default;
  background-color: var(--background-color-disabled);
  border-color: var(--border-color-disabled);
}
.root:global(.uui-disabled):global(.uui-invalid) :global(.uui-radioinput) :global(.uui-icon), .root:global(.uui-readonly):global(.uui-invalid) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--fill-color-checked-disabled);
}
.root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--caption-color);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--caption-color-disabled);
}
.root.size-18 :global(.uui-radioinput) {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  margin: 0px 0;
}
.root.size-18 :global(.uui-icon) {
  margin: 0px 0;
}
.root.size-18 :global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-12 :global(.uui-radioinput) {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 1px 0;
}
.root.size-12 :global(.uui-icon) {
  margin: 1px 0;
}
.root.size-12 :global(.uui-icon) svg {
  height: 12px;
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  line-height: 14px;
}
.root.size-12 :global(.uui-input-label) {
  margin-left: 6px;
}