/* Main Colors */
/* Gray */
.root:global(.uui-dropdown-body) {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-sizing: border-box;
  box-shadow: 0 6px 18px rgba(29, 30, 38, 0.1), 0 3px 12px rgba(29, 30, 38, 0.1);
  border: 0;
  overflow: hidden;
}
.root.background-white {
  background-color: white;
}
.root.background-gray70 {
  background-color: #474A59;
}
.root :global(.uui-popover-arrow) {
  position: absolute;
  width: 12px;
  height: 12px;
  overflow: hidden;
}
.root :global(.uui-popover-arrow)::after {
  content: "";
  position: absolute;
  background: #FFFFFF;
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}
.root.padding-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.root.padding-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.root.padding-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.root.padding-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.root.padding-30 {
  padding-left: 30px;
  padding-right: 30px;
}
.root.vPadding-6 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.root.vPadding-12 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.vPadding-18 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.root.vPadding-24 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.root.vPadding-30 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.root.vPadding-48 {
  padding-top: 24px;
  padding-bottom: 24px;
}