@import '../../../../../styles/base';

.project-cover-container {
  &__label {
    .uui-label-top {
      .uui-label {
        font-family: $sans-pro-source;
        font-weight: 600;
      }
    }
  }
}

.cover {
  &__img-wrapper {
    border-radius: unset;
    cursor: pointer;
  }

  &__img-wrapper &__img {
    border-radius: unset;
    width: 150px;
    height: 150px;
    margin-bottom: 8px;
    vertical-align: middle;
  }

  &__controls {
    justify-content: space-between;
  }

  &__button {
    &_add {
      width: 100%;
    }
    &_delete {
      width: 36px;
    }
  }

  &__placeholder.Text_root__wYraY {
    text-align: center;
    color: #6c6f80;
  }
}
