@font-face {
  font-family: 'Source Sans Pro Black';
  src: local('SansPro-Black'), url('../assets/font/SourceSansPro-Black.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Black Italic';
  src: local('SansPro-Black-Italic'),
    url('../assets/font/SourceSansPro-BlackItalic.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: local('SansPro-Bold'), url('../assets/font/SourceSansPro-Bold.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Bold Italic';
  src: local('SansPro-Bold-Italic'),
    url('../assets/font/SourceSansPro-BoldItalic.woff2');
}

@font-face {
  font-family: 'Source Sans Pro ExtraLight';
  src: local('SansPro-ExtraLight'),
    url('../assets/font/SourceSansPro-ExtraLight.woff2');
}

@font-face {
  font-family: 'Source Sans Pro ExtraLight Italic';
  src: local('SansPro-ExtraLight-Italic'),
    url('../assets/font/SourceSansPro-ExtraLightItalic.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: local('SansPro-Italic'),
    url('../assets/font/SourceSansPro-ExtraLight.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Light';
  src: local('SansPro-Light'), url('../assets/font/SourceSansPro-Light.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Light Italic';
  src: local('SansPro-Light-Italic'),
    url('../assets/font/SourceSansPro-LightItalic.woff2');
}

@font-face {
  font-family: 'Source Sans Pro Regular';
  src: local('SansPro-Regular'),
    url('../assets/font/SourceSansPro-Regular.woff2');
}

@font-face {
  font-family: 'Source Sans Pro SemiBold';
  src: local('SansPro-SemiBold'),
    url('../assets/font/SourceSansPro-SemiBold.woff2');
}

@font-face {
  font-family: 'Source Sans Pro SemiBold Italic';
  src: local('SansPro-SemiBold-Italic'),
    url('../assets/font/SourceSansPro-SemiBoldItalic.woff2');
}

// Museo sans

@font-face {
  font-family: 'MuseoSans 100';
  src: local('MuseoSans-100'), url('../assets/font/MuseoSans-100.otf');
}

@font-face {
  font-family: 'MuseoSans 100 Italic';
  src: local('MuseoSans-100Italic'),
    url('../assets/font/MuseoSans-100Italic.otf');
}
@font-face {
  font-family: 'MuseoSans 300';
  src: local('MuseoSans-300'), url('../assets/font/MuseoSans-300.otf');
}

@font-face {
  font-family: 'MuseoSans 300 Italic';
  src: local('MuseoSans-300Italic'),
    url('../assets/font/MuseoSans-300Italic.otf');
}

@font-face {
  font-family: 'MuseoSans 500';
  src: local('MuseoSans-500'), url('../assets/font/MuseoSans-500.otf');
}

@font-face {
  font-family: 'MuseoSans 500 Italic';
  src: local('MuseoSans-500Italic'),
    url('../assets/font/MuseoSans-500Italic.otf');
}

@font-face {
  font-family: 'MuseoSans 700';
  src: local('MuseoSans-700'), url('../assets/font/MuseoSans-700.otf');
  font-display: block;
}

@font-face {
  font-family: 'MuseoSans 700 Italic';
  src: local('MuseoSans-700Italic'),
    url('../assets/font/MuseoSans-700Italic.otf');
}

// Source Sans Pro

@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-ExtraLight.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-ExtraLight.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-ExtraLight.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-ExtraLight.ttf')
      format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-ExtraLightIt.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-ExtraLightIt.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-ExtraLightIt.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-ExtraLightIt.ttf')
      format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Light.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Light.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Light.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-LightIt.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-LightIt.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-LightIt.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-LightIt.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Regular.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Regular.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-It.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-It.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-It.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-It.ttf')
      format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Semibold.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Semibold.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Semibold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-SemiboldIt.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-SemiboldIt.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-SemiboldIt.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-SemiboldIt.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Bold.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Bold.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Bold.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Bold.ttf')
      format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-BoldIt.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-BoldIt.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-BoldIt.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-BoldIt.ttf')
      format('truetype');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Black.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Black.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-Black.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro';
  src: url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-BlackIt.ttf.woff2')
      format('woff2'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-BlackIt.otf.woff')
      format('woff'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/otf/SourceSansPro-BlackIt.otf')
      format('opentype'),
    url('https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/ttf/SourceSansPro-BlackIt.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
}
