@import '../../../../../styles/common.scss';

.linked-event {
  .title {
    padding: 0 0 8px;
    line-height: 24px;
    font-family: $sans-pro-semi-bold !important;
  }
  .link {
    display: inline-flex;
    padding: 0;
  }
}
