/* Main Colors */
/* Gray */
.linkButton-color-blue {
  --text-color: #008ACE;
  --text-color-hover: #0079b5;
  --text-color-active: #00689b;
  --text-color-disabled: #CED0DB;
}

.linkButton-color-green {
  --text-color: #88CC00;
  --text-color-hover: #77b300;
  --text-color-active: #669900;
  --text-color-disabled: #CED0DB;
}

.linkButton-color-amber {
  --text-color: #FFC000;
  --text-color-hover: #e6ad00;
  --text-color-active: #cc9a00;
  --text-color-disabled: #CED0DB;
}

.linkButton-color-red {
  --text-color: #E54322;
  --text-color-hover: #d53919;
  --text-color-active: #be3316;
  --text-color-disabled: #CED0DB;
}