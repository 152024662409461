[data-placement^=right] :global(.uui-popover-arrow) {
  right: 100%;
}
[data-placement^=right] :global(.uui-popover-arrow)::after {
  transform: translateX(100%) translateY(20%) rotate(45deg);
}

[data-placement^=left] :global(.uui-popover-arrow) {
  left: 100%;
}
[data-placement^=left] :global(.uui-popover-arrow)::after {
  transform: translateX(-50%) translateY(20%) rotate(45deg);
}

[data-placement^=top] :global(.uui-popover-arrow) {
  top: 100%;
}
[data-placement^=top] :global(.uui-popover-arrow)::after {
  transform: translateX(30%) translateY(-50%) rotate(45deg);
}

[data-placement^=bottom] :global(.uui-popover-arrow) {
  bottom: 100%;
}
[data-placement^=bottom] :global(.uui-popover-arrow)::after {
  transform: translateX(30%) translateY(100%) rotate(45deg);
}