.applicant-item {
  display: flex;
  flex-direction: column;
  padding: 6px;

  &:hover {
    background-color: #ebedf5;
    cursor: pointer;
  }

  .applicant-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    gap: 16px;
  }

  .applicant-info {
    display: flex;
    align-items: center;

    .applicant-image {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 12px;
    }

    .applicant-details {
      display: flex;
      flex-direction: column;
      .info-container {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 0;
        margin: 0;
      }

      .applicant-name {
        font-weight: 600;
        font-size: 14px;
        color: #303240;
        padding: 0;
      }

      .applicant-position {
        font-size: 12px;
        color: #6c6f80;

        padding: 0;
      }
    }
    .button-icons {
      display: flex;
      align-items: center;
      margin-left: 12px;
      gap: 12px;
    }
  }

  .applicant-actions {
    display: flex;
    align-items: center;

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      margin: 0 4px;
      height: 34px;
      width: 34px;
    }
  }

  .applicant-request {
    & > div {
      padding: 0;
    }

    .applicant-date {
      color: #6c6f80;
      margin-left: auto;
      text-align: right;
    }
  }
}
