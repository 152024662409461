/* Main Colors */
/* Gray */
.text-color-gray90 {
  --color: #1D1E26;
}

.text-color-gray80 {
  --color: #303240;
}

.text-color-gray60 {
  --color: #6C6F80;
}

.text-color-gray5 {
  --color: #FAFAFC;
}

.root {
  color: var(--color);
}
.root.font-sans {
  font-family: "Sans Regular", Arial, sans-serif;
  font-weight: normal;
}
.root.font-sans-semibold {
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
.root.font-sans-italic {
  font-family: "Sans Italic", Arial, sans-serif;
  font-weight: normal;
}
.root.font-museo-sans {
  font-family: "Museo Sans", "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
}
.root.font-museo-slab {
  font-family: "Museo Slab", "Roboto Slab", Arial, sans-serif;
  font-weight: normal;
}
.root b, .root strong {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.root i, .root em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.root a {
  display: inline-flex;
  align-items: center;
  color: #008ACE;
}
.root a:visited {
  color: #5214CC;
}
.root a:visited:hover {
  color: #3F0F9E;
}
.root a:hover {
  text-decoration: underline;
  color: #0079b5;
}
.root code {
  padding: 0.125em 0.25em;
  color: #303240;
  background-color: #E1E3EB;
  font-family: "Roboto Mono", Arial, sans-serif;
  white-space: pre-wrap;
  word-wrap: normal;
}