@import '../../../../styles/common.scss';

.project-card__wrapper {
  .project-card-item {
    flex-wrap: wrap;
    align-items: stretch;
    @media screen and (max-width: 860px) {
      flex-direction: column;
    }
    &__infor {
      flex: 70%;
      align-self: stretch;
      max-width: 100%;
      @media screen and (min-width: 860px) {
        border-left: rgba(196, 196, 196, 0.3) 1px solid;
        flex: calc(100% - 231px) !important;
      }
    }
    &__owner-time {
      align-self: stretch;
      flex: 30% !important;
      @media screen and (min-width: 860px) {
        border-left: rgba(196, 196, 196, 0.3) 1px solid;
        flex: 231px !important;
      }
      padding: 30px 0;
    }
    .project-owner {
      padding: 0 0 15px 20px;
    }
    .project-time {
      padding: 15px 0 0 20px;
      &__item {
        min-height: initial;
      }
      .time-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        padding: 0;
      }
      &__title {
        color: #acadb6;
        width: 90px;
      }
      &__value {
        color: #303240;
      }
    }
  }
}
