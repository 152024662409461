/* Main Colors */
/* Gray */
.modal-blocker {
  animation-duration: 0.3s;
  animation-name: animateModalBlocker;
  opacity: 1;
  transition: all 0.3s ease-out;
}
@keyframes animateModalBlocker {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal-blocker.shadow-light :global(.uui-modal-blocker) {
  background-color: rgba(245, 245, 245, 0.9);
}
.modal-blocker.shadow-dark :global(.uui-modal-blocker) {
  background: rgba(29, 30, 38, 0.8);
}
.modal-blocker.shadow-none :global(.uui-modal-blocker) {
  background-color: transparent;
}

.modal.width-300:global(.uui-modal-window) {
  width: 300px;
}
@media (max-width: 640px) {
  .modal.width-300:global(.uui-modal-window) {
    width: unset;
  }
}
.modal.width-420:global(.uui-modal-window) {
  width: 420px;
}
@media (max-width: 640px) {
  .modal.width-420:global(.uui-modal-window) {
    width: unset;
  }
}
.modal.width-600:global(.uui-modal-window) {
  width: 600px;
}
@media (max-width: 640px) {
  .modal.width-600:global(.uui-modal-window) {
    width: unset;
  }
}
.modal.width-900:global(.uui-modal-window) {
  width: 900px;
}
@media (max-width: 640px) {
  .modal.width-900 {
    width: unset;
  }
}
.modal.height-300:global(.uui-modal-window) {
  height: 300px;
}
.modal.height-700:global(.uui-modal-window) {
  height: 700px;
}
.modal:global(.uui-modal-window) {
  background-color: white;
  overflow: auto;
  border-radius: 0;
  max-height: 80vh;
  box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
}
@media (max-width: 640px) {
  .modal:global(.uui-modal-window) {
    min-width: 100vw;
    max-height: 100vh;
    border-radius: 0;
  }
}
.modal:global(.uui-modal-window) :global(.uui-shadow-top)::before {
  background: #CED0DB;
  height: 1px;
}
.modal:global(.uui-modal-window) :global(.uui-shadow-bottom)::after {
  background: #CED0DB;
  height: 1px;
}

.modal-footer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.modal-footer.border-top {
  border-top: 1px solid #CED0DB;
}

.modal-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}