/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.text-placeholder-color-gray10 {
  --background-color: #F5F6FA;
  --background-color-gradient: #EBEDF5;
  --color: #F5F6FA;
}

.text-placeholder-color-gray40 {
  --background-color: #CED0DB;
  --background-color-gradient: #ACAFBF;
  --color: #CED0DB;
}

@keyframes skeleton_loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
}

.loading-word {
  user-select: none;
  font-family: "Redacted";
  letter-spacing: -1px;
  margin: 0 3px 3px 0;
  color: var(--color);
  vertical-align: 1px;
}
.loading-word:last-child {
  margin-right: 0;
}
.loading-word.animated-loading {
  color: transparent;
  background: linear-gradient(to right, var(--background-color) 0%, var(--background-color-gradient) 50%, var(--background-color) 100%) repeat;
  background-size: 200% 100%;
  background-clip: border-box;
  -webkit-background-clip: text;
  animation: skeleton_loading 1s ease infinite;
}