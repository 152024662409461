@use '~@epam/promo/assets/styles/colors.scss' as *;
@import 'styles/common.scss';

.project-detail {
  &-project-owner {
    margin-bottom: 8px;
    padding-right: 16px;

    & &.has-action {
      &:hover {
        background: #ebedf5;
        cursor: pointer;
      }
    }

    &__avatar {
      margin-right: 12px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: cover;
    }
    &__nameRoleContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 0;
    }
    div & &__name {
      font-weight: 600;
      padding: 0; //remove default padding form uui component
      min-height: 20px;
      div {
        padding: 0px;
        color: #303240;
      }
    }
    &__icons {
      display: flex;
    }
    .mail-icon,
    .teams-icon,
    .telescope-icon {
      margin-left: 12px;
    }
    div & &__role {
      color: #6c6f80;
      font-weight: 400;
      padding: 0;
      min-height: 20px;
      div {
        padding: 0px;
      }
    }
    &__swap {
      display: none;
    }
    &:hover & {
      &__swap {
        display: block;
      }
    }
    &__ownership {
      transform: rotate(-90deg);
    }
  }
}
