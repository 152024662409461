/* Main Colors */
/* Gray */
.done {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #CED0DB;
}
.done:global(.uui-button-box) {
  background-color: #fff;
  border-width: 1px !important;
}