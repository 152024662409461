#root {
  .label-container {
    position: relative;
  }

  .label-tooltip {
    &__text {
      color: white;
      & * {
        color: white;
      }
    }

    &__icon {
      position: absolute;
      margin: 0px 4px;
    }
  }
}