@import '../../../../../styles/base';

.event-container {
  &__label {
    .events-toggler {
      .uui-button-box.uui-enabled {
        background-color: #ceefff;
      }
    }

    .uui-label-top {
      .uui-label {
        font-family: $sans-pro-source;
        font-weight: 600;
      }
    }
  }
}
