.AddTeamMember {
  margin: 20px 0px;
  .MemberInput {
    margin-bottom: 10px;
  }
  .PositionInput {
    margin-bottom: 15px;
    width: 50%;
  }
  .Button {
    margin-left: 10px;
  }
}
