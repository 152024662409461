/* Main Colors */
/* Gray */
.root {
  --background-color: white;
  --background-color-disabled: #FAFAFC;
  --background-color-readonly: #FAFAFC;
  --border-color-default: #CED0DB;
  --border-color-hovered: #6C6F80;
  --border-color-active: #008ACE;
  --border-color-errored: #E54322;
  --border-color-disabled: #E1E3EB;
  --border-color-readonly: #E1E3EB;
  --placeholder-color: #6C6F80;
  --placeholder-color-disabled: #ACAFBF;
  --text-color: #303240;
  --text-color-disabled: #ACAFBF;
  --icon-color: #6C6F80;
  --icon-color-hovered: #6C6F80;
  --icon-color-disabled: #ACAFBF;
}
.root:global(.uui-input-box) {
  padding-right: 0;
  min-width: 40px;
}
.root:global(.uui-input-box):global(.uui-numeric-input-without-arrows) {
  padding-right: 5px;
}
.root:global(.uui-input-box) :global(.uui-numeric-input-up-button) {
  transform: rotate(180deg);
}
.root:global(.uui-input-box) :global(.uui-numeric-input-up-button):hover svg {
  fill: var(--icon-color-hovered);
}
.root:global(.uui-input-box) :global(.uui-numeric-input-down-button):hover svg {
  fill: var(--icon-color-hovered);
}
.root:global(.uui-input-box) :global(.uui-input.uui-value-in-placeholder)::placeholder {
  color: inherit;
}
.root:global(.uui-input-box) :global(.uui-icon) svg {
  margin-top: -2px;
}
.root:global(.uui-input-box).size-24 :global(.uui-numeric-input-button-group) > * {
  height: 12px;
}
.root:global(.uui-input-box).size-30 :global(.uui-numeric-input-button-group) > * {
  height: 15px;
}
.root:global(.uui-input-box).size-36 :global(.uui-numeric-input-button-group) > * {
  height: 17px;
}
.root:global(.uui-input-box).size-42 :global(.uui-numeric-input-button-group) > * {
  height: 21px;
}
.root:global(.uui-input-box).size-48 :global(.uui-numeric-input-button-group) > * {
  height: 23px;
}
.root:global(.uui-disabled) :global(.uui-numeric-input-button-group) > *:hover, .root:global(.uui-disabled) :global(.uui-numeric-input-button-group) > *:focus {
  cursor: default;
  background-color: transparent;
}
.root:global(.uui-readonly).mode-form {
  background-color: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: #E1E3EB;
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}