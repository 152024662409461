.container {
  text-decoration: none;
  outline: none;
  border: 0;
  background: none;
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  align-items: unset;
  padding: unset;
  margin: unset;
  justify-content: unset;
  overflow: hidden;
  display: flex;
  position: relative;
}
.container:hover {
  text-decoration: none;
}
.container:global(.-clickable) {
  cursor: pointer;
}
.container:global(.uui-disabled) {
  cursor: default;
}