/* Main Colors */
/* Gray */
.dropdown-body {
  --background-color: #1D1E26;
  --dropdown-body-background-color: #1D1E26;
  --dropdown-item-background-color: #303240;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  background-color: var(--dropdown-body-background-color);
}
.dropdown-body :global(.-clickable):global(.uui-button-box):hover {
  background-color: var(--dropdown-item-background-color);
}
.dropdown-body :global(.uui-button-box):global(.uui-active) {
  border: none;
  border-left: 3px solid var(--border-color-active);
  background-color: var(--dropdown-item-background-color);
}