.empty-panel {
  &__img {
    margin-top: 28px;
    height: 315px;
  }

  &__header {
    font-size: 24px;
    line-height: 30px;
    font-family: 'MuseoSans 700', sans-serif;
    margin-bottom: 3px;
  }

  &__message {
    width: 400px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.suggestions {
  &__listElement {
    text-align: left;
    line-height: 24px;
    font-size: 14px;
  }

  &__header {
    font-size: 30px;
    font-family: 'MuseoSans 700', sans-serif;
  }

  &__listHeader {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    margin-top: 20px;
  }

  &__list {
    padding-left: 20px;
  }
}
