.root {
  overflow: hidden;
  border-radius: var(--panel-border-radius);
}
.root.background {
  background-color: var(--panel-bg-color);
}
.root.margin-24 {
  margin: 24px;
}
.root.padding-12 {
  padding: 12px;
}
.root.padding-24 {
  padding: 24px;
}
.root.shadow {
  box-shadow: var(--panel-shadow);
}
.root:global(.-clickable):hover {
  cursor: pointer;
}