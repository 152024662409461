@import '../../../styles/base';

#root {
  .modal-container {
    width: 690px !important;
    max-height: calc(100vh - 80px) !important;

    &__header {
      & > * {
        font-family: $sans-pro-source;
        font-weight: 600;
      }
    }

    .artifacts-panel {
      &.Panel_root__eSlhR.Panel_margin-24__cnUPw {
        margin-top: 12px;

        .artifact-caption {
          &.Text_root__wYraY.Text_font-sans__QSU1z {
            font-family: $sans-pro-source;
            color: #303240;
            padding: 0 0 24px;

            &.py-6 {
              padding-top: 6px;
              padding-bottom: 6px;
            }
          }
        }

        .link-Counter-text {
          &.Text_root__wYraY.Text_font-sans__QSU1z {
            font-family: $sans-pro-regular;
            color: #6c6f80;
            text-align: right;
          }
        }

        .error-msg-section {
          min-height: auto;
          padding-left: 166px;

          & .uui-invalid-message {
            margin-top: 0;
          }
        }
      }
    }

    .links-dropdown {
      align-items: flex-start;

      &__items {
        width: 100%;
      }
    }
  }
}
