/* Main Colors */
/* Gray */
.blocker {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.marker {
  position: absolute;
  pointer-events: none;
}

.top {
  top: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #008ACE;
}

.bottom {
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #008ACE;
}

.left {
  top: 0;
  bottom: 0;
  left: -1px;
  width: 2px;
  background-color: #008ACE;
}

.right {
  top: 0;
  bottom: 0;
  right: -1px;
  width: 2px;
  background-color: #008ACE;
}

.inside {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 2px solid #008ACE;
  background-color: rgba(255, 255, 255, 0.5);
}