/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.container {
  --background-color-hover: #1D1E26;
  --background-color-active: #1D1E26;
  --icon-color: white;
  --icon-color-hover: white;
  --icon-color-active: white;
  height: 60px;
  min-width: 48px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
}
.container:global(.-clickable):hover {
  background: var(--background-color-hover);
}
.container:global(.-clickable):active {
  background-color: var(--background-color-active);
}