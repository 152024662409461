.project-owner {
  display: flex;
  align-items: flex-start;
  border-bottom: rgba(196, 196, 196, 0.3) 1px solid;
  padding-left: 20px;
  padding-bottom: 15px;
  &__img-profile {
    margin-right: 12px;
  }
  &__infor {
    .owner-name {
      font-size: 14px;
      color: #303240;
      padding-top: 0;
      padding-bottom: 1px;
      line-height: 16px;
      overflow: visible;
    }
    .owner-title {
      font-size: 12px;
      line-height: 18px;
      color: #6c6f80;
      padding: 0;
      margin-bottom: 4px;
    }
    .button-icons {
      display: flex;
    }
    .mail-icon,
    .teams-icon {
      margin-right: 12px;
    }
  }
}
