.menuRoot {
  padding: 0;
  margin: 0;
}
.menuRoot:focus {
  outline: none;
}

.bodyRoot {
  color: var(--text-primary-color);
  fill: var(--icon-color);
  font-family: var(--font-regular);
  font-size: 14px;
  padding: 0.5em 0;
  background-color: var(--surface-color);
  box-shadow: var(--shadow);
}
.bodyRoot :global(.uui-icon) svg {
  height: 24px;
  max-width: 24px;
}

.submenuRootItem .iconAfter {
  transform: rotate(-90deg);
}

.iconCheck {
  color: transparent;
  fill: transparent;
}

.splitterRoot {
  display: flex;
  width: 100%;
}
.splitterRoot .splitter {
  width: 100%;
  border: 0;
  border-top: 1px solid var(--divider-color);
  height: 0;
}

.headerRoot {
  color: var(--text-secondary-color);
  fill: var(--text-secondary-color);
  padding: 0.5em 1em;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  cursor: default;
  font-size: 0.9em;
}
.headerRoot:focus {
  outline: none;
}

.itemRoot {
  padding: 0.5em 1em;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
}
.itemRoot :global(.uui-icon) svg {
  height: 24px;
  max-width: 24px;
}
.itemRoot:global(.uui-selected) {
  color: var(--primary-color);
  fill: var(--primary-color);
}
.itemRoot:global(.uui-selected):hover, .itemRoot:global(.uui-selected):focus {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.itemRoot:global(.uui-selected) .iconCheck {
  color: var(--primary-color);
  fill: var(--primary-color);
}
.itemRoot:focus {
  outline: none;
}
.itemRoot .icon {
  justify-content: flex-start;
}
.itemRoot.link {
  color: var(--text-primary-color);
  fill: var(--icon-color);
  display: flex;
  align-items: center;
}
.itemRoot.link:global(.uui-selected) {
  color: var(--primary-color);
  fill: var(--primary-color);
}
.itemRoot.link:global(.uui-selected):hover, .itemRoot.link:global(.uui-selected):focus {
  color: var(--primary-color) !important;
  fill: var(--primary-color) !important;
}
.itemRoot.link:global(.uui-selected) .iconCheck {
  color: var(--primary-color);
  fill: var(--primary-color);
}
.itemRoot.link:focus {
  outline: none;
}
.itemRoot:global(.-clickable:not(.uui-disabled)):hover, .itemRoot:global(.-clickable:not(.uui-disabled)):focus {
  background-color: var(--surface-hover-color);
  fill: var(--icon-active-color);
  cursor: pointer;
}
.itemRoot:global(.uui-disabled) {
  color: var(--disabled-color);
  fill: var(--disabled-color);
}
.itemRoot:global(.uui-disabled):hover, .itemRoot:global(.uui-disabled):focus {
  background-color: transparent;
  cursor: default;
}
.itemRoot > *:not(:last-child) {
  margin: 0 0.5em 0 0;
}