/* Main Colors */
/* Gray */
.cell {
  --uui-dt-cell-background: transparent;
  --uui-dt-cell-border-width: 1px;
  --uui-dt-cell-border-color: transparent;
  --uui-dt-cell-editor-border-width: 1px;
  --uui-dt-cell-editor-hover-border-color: #008ACE;
  --uui-dt-cell-editor-focus-border-color: #008ACE;
  --uui-dt-cell-editor-invalid-border-color: #E54322;
}
.cell.border-gray30 {
  --uui-dt-cell-border-color: #E1E3EB;
}
.cell:global(.uui-focus) {
  --uui-dt-cell-editor-border-width: 2px;
}
.cell.align-widgets-top.size-24 :global(.uui-checkbox), .cell.align-widgets-top.size-24 .folding-arrow, .cell.align-widgets-top.size-30 :global(.uui-checkbox), .cell.align-widgets-top.size-30 .folding-arrow {
  margin-top: 6px;
}
.cell.align-widgets-top.size-36 :global(.uui-checkbox), .cell.align-widgets-top.size-36 .folding-arrow {
  margin-top: 9px;
}
.cell.align-widgets-top.size-42 :global(.uui-checkbox), .cell.align-widgets-top.size-42 .folding-arrow {
  margin-top: 12px;
}
.cell.align-widgets-top.size-48 :global(.uui-checkbox), .cell.align-widgets-top.size-48 .folding-arrow {
  margin-top: 15px;
}
.cell.align-widgets-top.size-60 :global(.uui-checkbox), .cell.align-widgets-top.size-60 .folding-arrow {
  margin-top: 21px;
}
.cell.align-widgets-center :global(.uui-checkbox-container), .cell.align-widgets-center .folding-arrow {
  align-self: center;
}
.cell.padding-12 {
  padding: 0 12px;
}
.cell.padding-24 {
  padding: 0 24px;
}
.cell.padding-left-12 {
  padding-left: 12px;
}
.cell.padding-left-24 {
  padding-left: 24px;
}
.cell.padding-right-24 {
  padding-right: 24px;
}
.cell.background-blue {
  --uui-dt-cell-background: rgba(0, 138, 206, 0.1);
}
.cell.background-green {
  --uui-dt-cell-background: rgba(136, 204, 0, 0.1);
}
.cell.background-amber {
  --uui-dt-cell-background: rgba(255, 192, 0, 0.1);
}
.cell.background-red {
  --uui-dt-cell-background: rgba(229, 67, 34, 0.1);
}
.cell.background-gray5 {
  --uui-dt-cell-background: #FAFAFC;
}
.cell:global(.uui-invalid) {
  --uui-dt-cell-editor-background: rgba(229, 67, 34, 0.1);
}

.drag-handle {
  position: absolute;
  left: 2px;
  width: 6px;
  top: 3px;
  bottom: 2px;
  align-self: stretch;
  visibility: hidden;
}
:global(.-draggable.uui-table-row):not(:global(.uui-drop-accepted)):hover .drag-handle {
  visibility: visible;
}

.checkbox {
  padding-right: 12px;
}

.indent {
  display: flex;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 12px;
  padding-right: 12px;
}

.folding-arrow {
  margin: auto 0;
}
.folding-arrow:global(.uui-icon) {
  position: relative;
  fill: #6C6F80;
}

.folding-arrow-12:global(.uui-icon) {
  left: -2px;
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
}

.folding-arrow-18:global(.uui-icon) {
  left: -4px;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
}