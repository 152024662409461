.root:global(.uui-horizontal-direction) > * {
  margin-left: 12px;
}
.root:global(.uui-horizontal-direction) :first-child {
  margin-left: 0;
}
.root:global(.uui-vertical-direction) > * {
  margin-bottom: 12px;
}
.root:global(.uui-vertical-direction) :last-child {
  margin-bottom: 0;
}