@import '../../styles/common.scss';

.dashboard {
  font-family: $sans-pro-regular;
  font-size: 12px;
  padding-top: 40px;
  margin: 0 auto;
  & &__search {
    height: 60px;
    padding: 0 23px;
    div &:hover {
      border-color: #008ace;
    }
  }
  &__main-tabs {
    margin-top: 40px;
    margin-bottom: 40px;
    border-bottom: #ced0db solid 1px;
  }
  .paginator-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  & &__downloadText div:first-child {
    text-transform: none;
  }
  .eventName {
    background-color: #e1e3eb;
    display: inline-flex;
    align-items: center;
    grid-gap: 6px;
    font-family: $sans-pro-source;
    font-weight: 400;
    padding: 6px 12px;
  }
}
.showroom {
  padding-top: 56px;
  &__search_div {
    position: relative;
  }
  & &__search {
    position: absolute;
    height: 60px;
    bottom: 14px;
    padding: 0 23px;
    div &:hover {
      border-color: #008ace;
    }
  }
  .paginator-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
}
