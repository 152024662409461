/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.root {
  --color: white;
  --fill: white;
  --server-badge-border-color: white;
  --background-color: #303240;
  height: 60px;
  min-height: 60px;
  min-width: 360px;
  background-color: var(--background-color);
  color: var(--color);
  fill: var(--fill);
}
.root :global(.uui-mainmenu-server-badge) {
  position: absolute;
  left: 80px;
  bottom: 0;
}
.root :global(.uui-mainmenu-server-badge-label) {
  padding: 0 5px;
  border: 2px solid var(--server-badge-border-color);
  border-radius: 5px 5px 0 0;
  border-bottom: 0;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-size: 9px;
  text-transform: uppercase;
}
.root:global(.uui-mainmenu-transparent) {
  background-color: transparent;
}
.root:global(.uui-mainmenu-transparent) :global(#global_menu_toggle), .root:global(.uui-mainmenu-transparent) :global(.uui-burger-menu) {
  background-color: transparent;
}
.root:global(.uui-mainmenu-transparent) :global(#global_menu_toggle):hover, .root:global(.uui-mainmenu-transparent) :global(#global_menu_toggle):active, .root:global(.uui-mainmenu-transparent) :global(.uui-burger-menu):hover, .root:global(.uui-mainmenu-transparent) :global(.uui-burger-menu):active {
  background-color: transparent;
  color: darkblue;
  fill: darkblue;
}