/* Main Colors */
/* Gray */
.root {
  --background-color: #CED0DB;
  --background-color-checked: #008ACE;
  --background-color-hovered: #6C6F80;
  --background-color-disabled: #FAFAFC;
  --background-color-checked-hovered: #0079b5;
  --background-color-checked-disabled: #b5e6ff;
  --border-color: #CED0DB;
  --border-color-checked: #008ACE;
  --border-color-hovered: #6C6F80;
  --border-color-disabled: #E1E3EB;
  --border-color-checked-hovered: #0079b5;
  --border-color-checked-disabled: #b5e6ff;
  --caption-color: #303240;
  --caption-color-disabled: #6C6F80;
  --toggler-background-color: white;
  --toggler-background-color-disabled: #FAFAFC;
  --toggler-border-color: #CED0DB;
  --toggler-border-color-checked: #008ACE;
  --toggler-border-color-hovered: #6C6F80;
  --toggler-border-color-disabled: #E1E3EB;
  --toggler-border-color-checked-hovered: #0079b5;
  --toggler-border-color-checked-disabled: #b5e6ff;
}
.root.size-12 :global(.uui-switch-body) {
  height: 12px;
  width: 24px;
}
.root.size-12 :global(.uui-switch-toggler) {
  height: 12px;
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  letter-spacing: 0.2px;
}
.root.size-12 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(12px);
}
.root.size-18 :global(.uui-switch-body) {
  height: 18px;
  width: 36px;
}
.root.size-18 :global(.uui-switch-toggler) {
  height: 18px;
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  letter-spacing: 0.1px;
}
.root.size-18 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(18px);
}
.root.size-24 :global(.uui-switch-body) {
  height: 24px;
  width: 48px;
}
.root.size-24 :global(.uui-switch-toggler) {
  height: 24px;
  width: 24px;
}
.root.size-24 :global(.uui-input-label) {
  font-size: 14px;
  letter-spacing: 0.1px;
}
.root.size-24 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(24px);
}
.root :global(.uui-switch-body) {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
}
.root :global(.uui-switch-toggler) {
  left: -1px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: var(--toggler-background-color);
  border: 1px solid var(--toggler-border-color);
  transition: 0.1s transform cubic-bezier(0.4, 0, 0.2, 1);
}
.root :global(.uui-input-label) {
  font-family: "Sans Regular", Arial, sans-serif;
  color: var(--caption-color);
  margin-left: 11px;
}
.root :global(.uui-input-label) b, .root :global(.uui-input-label) strong {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.root :global(.uui-input-label) i, .root :global(.uui-input-label) em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.root :global(.uui-input-label) a {
  display: inline-flex;
  align-items: center;
  color: #008ACE;
}
.root :global(.uui-input-label) a:visited {
  color: #5214CC;
}
.root :global(.uui-input-label) a:visited:hover {
  color: #3F0F9E;
}
.root :global(.uui-input-label) a:hover {
  text-decoration: underline;
  color: #0079b5;
}
.root :global(.uui-input-label) code {
  padding: 0.125em 0.25em;
  color: #303240;
  background-color: #E1E3EB;
  font-family: "Roboto Mono", Arial, sans-serif;
  white-space: pre-wrap;
  word-wrap: normal;
}
.root:hover :global(.uui-switch-body), .root:focus-within :global(.uui-switch-body) {
  background-color: var(--background-color-hovered);
  border-color: var(--border-color-hovered);
}
.root:hover :global(.uui-switch-toggler), .root:focus-within :global(.uui-switch-toggler) {
  border-color: var(--toggler-border-color-hovered);
}
.root:hover :global(.uui-checked):global(.uui-switch-body), .root:focus-within :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--background-color-checked-hovered);
  border-color: var(--border-color-checked-hovered);
}
.root:hover :global(.uui-checked) :global(.uui-switch-toggler), .root:focus-within :global(.uui-checked) :global(.uui-switch-toggler) {
  border-color: var(--toggler-border-color-checked-hovered);
}
.root :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--background-color-checked);
  border: 1px solid var(--border-color-checked);
}
.root :global(.uui-checked):global(.uui-switch-body) :global(.uui-switch-toggler) {
  border: 1px solid var(--toggler-border-color-checked);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--caption-color-disabled);
}
.root:global(.uui-disabled),
.root:global(.uui-disabled) :global(.uui-switch-toggler),
.root:global(.uui-disabled) :global(.uui-switch-body),
.root:global(.uui-disabled) :global(.uui-input-label) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-switch-body) {
  background-color: var(--background-color-disabled);
  border: 1px solid var(--border-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-switch-toggler), .root:global(.uui-disabled):hover :global(.uui-switch-toggler), .root:global(.uui-disabled):focus-within :global(.uui-switch-toggler) {
  background-color: var(--toggler-background-color-disabled);
  border-color: var(--toggler-border-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--background-color-checked-disabled);
  border: 1px solid var(--border-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked) :global(.uui-switch-toggler) {
  background-color: var(--toggler-background-color-disabled);
  border-color: var(--toggler-border-color-checked-disabled);
}