/* Main Colors */
/* Gray */
.accordion-colors {
  --background-color: white;
  --border-color: #CED0DB;
  --text-color: #303240;
  --text-color-disabled: #ACAFBF;
  --icon-color: #6C6F80;
  --icon-color-disabled: #ACAFBF;
  --focus-color: #008ACE;
}