@import 'styles/common.scss';

.subscribe-modal {
  width: 550px !important;
  & &__body {
    overflow: auto;
    padding: 24px 24px;

    &__description {
      overflow: visible;
      margin-bottom: 6px;
      padding-top: 0px;
      padding-bottom: 22px;
    }

    &__subheader {
      margin-bottom: 2px;
    }

    &__checkbox {
      padding: 0px;
      margin-bottom: 12px;
      min-height: unset !important;
    }

    &__technologies {
      .uui-button-box.uui-enabled {
        background-color: #e3fcfc;
      }
    }

    &__formContainer {
      border: 1px solid #ced0db;
      padding: 18px 24px 18px 24px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &__formContainer:last-child {
      margin-bottom: 0px;
    }

    &__formContainer .formInputLabel {
      margin-top: 10px;
    }

    .modal-footer {
      padding: 12px 24px;
    }

    .addSubscriptionButton {
      width: 146px;
    }

    .deleteFormButton {
      position: absolute;
      right: 45px;
    }
  }
}
