.root {
  fill: var(--text-input-icon-color);
  border-style: solid;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: var(--text-input-border-radius);
}
.root :global(.uui-input) {
  color: var(--text-input-text-color);
  font-family: var(--font-regular);
  padding-left: 6px;
  padding-right: 6px;
}
.root :global(.uui-input)::placeholder {
  color: var(--text-input-placeholder-color);
}
.root :global(.uui-input):placeholder-shown {
  text-overflow: ellipsis;
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root.mode-form {
  background-color: var(--text-input-bg-color);
  border-color: var(--text-input-border-color);
}
.root.mode-form :global(.-clickable) {
  fill: var(--text-input-icon-color-clickable);
}
.root.mode-form :global(.-clickable):hover {
  fill: var(--text-input-icon-color-clickable-hover);
}
.root.mode-form:hover {
  border-color: var(--text-input-border-color-hover);
}
.root.mode-form:global(.uui-focus) {
  border-color: var(--text-input-border-color-active);
}
.root.mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.uui-icon):first-child {
  padding-left: 6px;
}
.root.mode-cell :global(.-clickable) {
  fill: transparent;
}
.root.mode-cell:hover {
  background-color: var(--text-input-bg-color);
  border-color: var(--text-input-border-color);
}
.root.mode-cell:hover :global(.-clickable) {
  fill: var(--text-input-icon-color-clickable);
}
.root.mode-cell:hover :global(.-clickable):hover {
  fill: var(--text-input-icon-color-clickable-hover);
}
.root.mode-cell:global(.uui-focus) {
  background-color: var(--text-input-bg-color);
  border-color: var(--text-input-border-color-active);
}
.root:global(.uui-invalid).mode-form, .root:global(.uui-invalid).mode-cell {
  background-color: var(--text-input-bg-color);
  border-color: var(--text-input-border-color-error);
}
.root:global(.uui-readonly) {
  color: var(--text-input-text-color);
  fill: var(--text-input-icon-color);
  pointer-events: none;
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--text-input-bg-color-readonly);
  border-color: var(--text-input-border-color-readonly);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-disabled) {
  color: var(--text-input-text-color-disabled);
  fill: var(--text-input-icon-color-disabled);
  pointer-events: none;
}
.root:global(.uui-disabled) :global(.uui-input) {
  color: var(--text-input-text-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-input)::placeholder {
  color: var(--text-input-placeholder-color-disabled);
}
.root:global(.uui-disabled).mode-form {
  background-color: var(--text-input-bg-color-disabled);
  border-color: var(--text-input-border-color-disabled);
}
.root:global(.uui-disabled).mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root.size-24 {
  min-width: 24px;
}
.root.size-24 :global(.uui-caption), .root.size-24 :global(.uui-input) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.root.size-24:global(.uui-button-box), .root.size-24:global(.uui-input-box) {
  min-height: 24px;
  border-width: 1px;
}
.root.size-30 {
  min-width: 30px;
}
.root.size-30 :global(.uui-caption), .root.size-30 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.root.size-30:global(.uui-button-box), .root.size-30:global(.uui-input-box) {
  min-height: 30px;
  border-width: 1px;
}
.root.size-36 {
  min-width: 36px;
}
.root.size-36 :global(.uui-caption), .root.size-36 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root.size-36:global(.uui-button-box), .root.size-36:global(.uui-input-box) {
  min-height: 36px;
  border-width: 1px;
}
.root.size-42 {
  min-width: 42px;
}
.root.size-42 :global(.uui-caption), .root.size-42 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root.size-42:global(.uui-button-box), .root.size-42:global(.uui-input-box) {
  min-height: 42px;
  border-width: 1px;
}
.root.size-48 {
  min-width: 48px;
}
.root.size-48 :global(.uui-caption), .root.size-48 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.root.size-48:global(.uui-button-box), .root.size-48:global(.uui-input-box) {
  min-height: 48px;
  border-width: 1px;
}