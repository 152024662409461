.root:global(.uui-button-box) {
  border-style: solid;
  justify-content: center;
  min-width: inherit;
  border-radius: var(--button-border-radius);
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: center;
  flex: 1 1 auto;
  font-family: var(--font-semibold);
  font-weight: normal;
  user-select: none;
}

.mode-solid {
  background-color: var(--button-bg-color);
  color: var(--button-caption-color);
  fill: var(--button-caption-color);
  border-color: var(--button-bg-color);
}
.mode-solid:global(.-clickable):hover {
  background-color: var(--button-bg-color-hover);
  border-color: var(--button-bg-color-hover);
}
.mode-solid:global(.-clickable):focus {
  background-color: var(--button-bg-color-hover);
  border-color: var(--button-bg-color-hover);
  box-shadow: var(--button-focus-shadow);
  outline: var(--button-focus-outline);
}
.mode-solid:global(.-clickable):active {
  background-color: var(--button-bg-color-active);
  border-color: var(--button-bg-color-active);
}
.mode-solid:global(.uui-disabled) {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: var(--button-border-color-disabled);
}
.mode-solid:global(.-clickable):global(.uui-disabled), .mode-solid:hover:global(.uui-disabled), .mode-solid:active:global(.uui-disabled), .mode-solid:focus:global(.uui-disabled) {
  background-color: var(--button-bg-color-disabled);
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: var(--button-border-color-disabled);
}

.mode-outline {
  background-color: var(--button-bg-color-halftone);
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: var(--button-border-color);
}
.mode-outline:global(.-clickable):hover, .mode-outline:global(.-clickable):focus {
  background-color: var(--button-bg-color-halftone-hover);
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: var(--button-border-color);
}
.mode-outline:global(.-clickable):active {
  background-color: var(--button-bg-color-halftone-active);
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: var(--button-border-color);
}
.mode-outline:global(.uui-disabled) {
  background-color: var(--button-bg-color-halftone);
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: var(--button-border-color-disabled);
}
.mode-outline:global(.-clickable):global(.uui-disabled), .mode-outline:hover:global(.uui-disabled), .mode-outline:active:global(.uui-disabled), .mode-outline:focus:global(.uui-disabled) {
  background-color: var(--button-bg-color-halftone);
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: var(--button-border-color-disabled);
}

.mode-none {
  background-color: transparent;
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: var(--button-border-color);
}
.mode-none:global(.-clickable):hover, .mode-none:global(.-clickable):focus {
  background-color: var(--button-bg-color-halftone-hover);
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: var(--button-border-color);
}
.mode-none:global(.-clickable):active {
  background-color: var(--button-bg-color-halftone-active);
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: var(--button-border-color);
}
.mode-none:global(.uui-disabled) {
  background-color: transparent;
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: var(--button-border-color-disabled);
}
.mode-none:global(.-clickable):global(.uui-disabled), .mode-none:hover:global(.uui-disabled), .mode-none:active:global(.uui-disabled), .mode-none:focus:global(.uui-disabled) {
  background-color: transparent;
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: var(--button-border-color-disabled);
}

.mode-ghost {
  background-color: transparent;
  color: var(--button-caption-halftone-color);
  fill: var(--button-caption-halftone-color);
  border-color: transparent;
}
.mode-ghost:global(.-clickable):hover, .mode-ghost:global(.-clickable):focus {
  background-color: var(--button-bg-color-halftone-hover);
  border-color: var(--button-bg-color-halftone-hover);
}
.mode-ghost:global(.-clickable):active {
  background-color: var(--button-bg-color-halftone-active);
  border-color: var(--button-bg-color-halftone-active);
}
.mode-ghost:global(.uui-disabled) {
  background-color: transparent;
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: transparent;
}
.mode-ghost:global(.-clickable):global(.uui-disabled), .mode-ghost:hover:global(.uui-disabled), .mode-ghost:active:global(.uui-disabled), .mode-ghost:focus:global(.uui-disabled) {
  background-color: transparent;
  color: var(--button-caption-color-disabled);
  fill: var(--button-caption-color-disabled);
  border-color: transparent;
}

.size-24 {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
.size-24 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-24:global(.uui-button-box) {
  min-height: 24px;
  border-width: 1px;
}
.size-24 :global(.uui-caption) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.size-30 {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
.size-30 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-30:global(.uui-button-box) {
  min-height: 30px;
  border-width: 1px;
}
.size-30 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.size-36 {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
}
.size-36 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-36:global(.uui-button-box) {
  min-height: 36px;
  border-width: 1px;
}
.size-36 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.size-42 {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
}
.size-42 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-42:global(.uui-button-box) {
  min-height: 42px;
  border-width: 1px;
}
.size-42 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}
.size-42 :global(.uui-caption) {
  font-family: var(--font-primary);
  text-transform: uppercase;
}

.size-48 {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
}
.size-48 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-48:global(.uui-button-box) {
  min-height: 48px;
  border-width: 1px;
}
.size-48 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
}
.size-48 :global(.uui-caption) {
  font-family: var(--font-primary);
  text-transform: uppercase;
}