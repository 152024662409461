/* Main Colors */
/* Gray */
.root {
  --fill-color: #FFC000;
  --fill-color-empty: #ACAFBF;
  --fill-color-hovered: #e6ad00;
  --fill-color-disabled: #6C6F80;
  --fill-color-disabled-empty: #ACAFBF;
  --fill-color-invalid: #E54322;
  fill: var(--fill-color);
}
.root :global(.uui-rating-star) {
  padding: 0 2px;
}
.root :global(.uui-rating-empty-star-container) {
  fill: var(--fill-color-empty);
}
.root :global(.uui-rating-filled-star-container) svg {
  stroke-width: 0.1px;
  stroke: var(--fill-color);
}
.root.size-18 svg {
  width: 18px;
  height: 18px;
}
.root.size-24 svg {
  width: 24px;
  height: 24px;
}
.root.size-30 svg {
  width: 30px;
  height: 30px;
}
.root:global(.uui-disabled) :global(.uui-rating-empty-star-container) {
  fill: var(--fill-color-disabled-empty);
}
.root:global(.uui-disabled) :global(.uui-rating-filled-star-container) {
  fill: var(--fill-color-disabled);
}
.root:global(.uui-invalid) :global(.uui-rating-star) {
  fill: var(--fill-color-invalid);
}