@import 'base';
@import 'color';
@import 'font';
@import 'breakpoint';
@import 'typography';

html {
  * {
    box-sizing: border-box;
  }
}

body {
  margin: 0;
  font-family: $sans-pro-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

.uui-thumb-vertical,
.uui-thumb-horizontal {
  background-color: $custom-scrollbar-darkgrey;
}

/**
 * Utilities
 */
.text-centre,
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

label,
.label {
  font-size: toRem(16);
}

.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-filled {
  color: $black-color !important;
}

.MuiFormHelperText-root.Mui-error {
  color: $red-color !important;
  position: absolute;
  bottom: -22px;
}

.MuiFormLabel-root.Mui-focused,
.MuiInputBase-input {
  color: $black-color !important;
  font-family: $sans-pro-regular !important;
}

.MuiMenuItem-root {
  font-family: $sans-pro-regular !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid $black-color !important;
}

.small-text {
  font-size: toRem(12);
}

.text-italic {
  font-style: italic;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.d-flex {
  display: flex;
}

.m-auto {
  margin: auto;
}
.w-100 {
  width: 100% !important;
}
.ml-1 {
  margin-left: 1em !important;
}
.mr-1 {
  margin-right: 1em !important;
}
.mt-1 {
  margin-top: 1em !important;
}
.mb-1 {
  margin-bottom: 1em !important;
}
.MuiInput-underline {
  &:before {
    border-bottom-color: $silver-color !important;
  }
}
.container {
  width: 100%;
  margin: 0 auto;
  @media screen and (min-width: 1231px) {
    width: 1166px;
  }
  @media screen and (max-width: 1230px) {
    padding: 0 20px;
  }
}
.dashboard-container,
.showroom-container {
  @extend .container;
}

@mixin ellipsis($lines-to-show, $font-size) {
  $line-height: 1.4;
  .text-display {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: ($font-size * $line-height * $lines-to-show) + px;
    margin: 0 auto;
    font-size: $font-size + px;
    line-height: ($line-height);
    -webkit-line-clamp: $lines-to-show;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
