.root:global(.uui-blocker-container) :global(.uui-blocker) {
  background-color: rgba(245, 246, 250, 0.5019607843);
}
.root:global(.uui-blocker-container) :global(.uui-blocker-enter) {
  background-color: rgba(245, 246, 250, 0);
}
.root:global(.uui-blocker-container) :global(.uui-blocker-enter-active) {
  background-color: rgba(245, 246, 250, 0.5019607843);
  transition: opacity 1000ms ease-out, background-color 2000ms ease-out;
}
.root:global(.uui-blocker-container) :global(.uui-blocker-exit) {
  background-color: rgba(245, 246, 250, 0.5019607843);
  pointer-events: none;
  transition: opacity 1000ms ease-out, background-color 200ms ease-out;
}
.root:global(.uui-blocker-container) :global(.uui-blocker-exit-active) {
  background-color: rgba(245, 246, 250, 0);
}