.footer {
  background-color: #303240;
  width: 100%;
  margin-top: auto;
  $b: #{&}-container;
  #{$b} {
    margin: 0 auto;
    #{$b}__row {
      border-bottom: initial;
      padding-right: 16px;
      padding-left: 16px;
      #{$b}__text {
        font-weight: 600;
      }
      .company-logo {
        margin-right: 12px;
      }
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
    }
  }
}
