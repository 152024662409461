.applicants-list-container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.applicant-title {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #303240;
  margin-bottom: 10px;
}
