.root:hover :global(.uui-radioinput), .root:focus-within :global(.uui-radioinput) {
  border-color: var(--radio-input-border-color-hover);
}
.root:hover :global(.uui-radioinput):global(.uui-checked), .root:focus-within :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--radio-input-border-color-hover);
}
.root:hover :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon), .root:focus-within :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--radio-input-fill-color-hover);
}
.root:hover:global(.uui-disabled) :global(.uui-radioinput), .root:hover:global(.uui-readonly) :global(.uui-radioinput), .root:focus-within:global(.uui-disabled) :global(.uui-radioinput), .root:focus-within:global(.uui-readonly) :global(.uui-radioinput) {
  background-color: var(--radio-input-bg-color-disabled);
  border-color: var(--radio-input-border-color-disabled);
}
.root:hover:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:hover:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon), .root:focus-within:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:focus-within:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--radio-input-fill-color-checked-disabled);
}
.root :global(.uui-radioinput) {
  background-color: var(--radio-input-bg-color);
  border: 1px solid var(--radio-input-border-color);
  align-self: baseline;
  flex-shrink: 0;
  box-sizing: border-box;
}
.root :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--radio-input-border-color-checked);
}
.root :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--radio-input-fill-color-checked);
}
.root :global(.uui-input-label) {
  color: var(--radio-input-label-color);
  flex-grow: 1;
  font-family: var(--font-regular);
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.root:global(.uui-invalid) :global(.uui-icon) {
  fill: var(--radio-input-error-color);
}
.root:global(.uui-invalid) :global(.uui-input-label) {
  color: var(--radio-input-error-color);
}
.root:global(.uui-invalid) :global(.uui-radioinput) {
  border-color: var(--radio-input-error-color);
}
.root:global(.uui-invalid) :global(.uui-checked):global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--radio-input-error-color);
}
.root:global(.uui-invalid):hover :global(.uui-radioinput), .root:global(.uui-invalid):focus-within :global(.uui-radioinput) {
  border-color: var(--radio-input-error-color);
}
.root:global(.uui-invalid):hover :global(.uui-icon), .root:global(.uui-invalid):focus-within :global(.uui-icon) {
  fill: var(--radio-input-error-color);
}
.root:global(.uui-disabled), .root:global(.uui-readonly) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-radioinput), .root:global(.uui-readonly) :global(.uui-radioinput) {
  cursor: default;
  background-color: var(--radio-input-bg-color-disabled);
  border-color: var(--radio-input-border-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput) :global(.uui-icon), .root:global(.uui-readonly) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--radio-input-fill-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput):global(.uui-checked), .root:global(.uui-readonly) :global(.uui-radioinput):global(.uui-checked) {
  border-color: var(--radio-input-border-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon), .root:global(.uui-readonly) :global(.uui-radioinput):global(.uui-checked) :global(.uui-icon) {
  fill: var(--radio-input-fill-color-checked-disabled);
}
.root:global(.uui-disabled):global(.uui-invalid) :global(.uui-radioinput), .root:global(.uui-readonly):global(.uui-invalid) :global(.uui-radioinput) {
  cursor: default;
  background-color: var(--radio-input-bg-color-disabled);
  border-color: var(--radio-input-border-color-disabled);
}
.root:global(.uui-disabled):global(.uui-invalid) :global(.uui-radioinput) :global(.uui-icon), .root:global(.uui-readonly):global(.uui-invalid) :global(.uui-radioinput) :global(.uui-icon) {
  fill: var(--radio-input-fill-color-checked-disabled);
}
.root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--radio-input-label-color);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--radio-input-label-color-disabled);
}
.root.size-18 :global(.uui-radioinput) {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  margin: 0px 0;
}
.root.size-18 :global(.uui-icon) {
  margin: 0px 0;
}
.root.size-18 :global(.uui-icon) svg {
  height: 18px;
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-12 :global(.uui-radioinput) {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  margin: 1px 0;
}
.root.size-12 :global(.uui-icon) {
  margin: 1px 0;
}
.root.size-12 :global(.uui-icon) svg {
  height: 12px;
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  line-height: 14px;
}
.root.size-12 :global(.uui-input-label) {
  margin-left: 6px;
}