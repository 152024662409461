@use '~@epam/promo/assets/styles/colors' as *;
@import '../../styles/common.scss';

.timezone-panel {
  padding: 0 12px;

  .active-timezone {
    color: $bright-blue;
  }

  .gray-text {
    color: $gray60;
  }

  .primary-text {
    color: $gray80;
  }
}

.timezone-panel:hover .primary-text {
  @extend .active-timezone;
}

.font {
  font-family: $sans-pro-regular, Helvetica, serif;
  font-style: normal;
}

.font-400 {
  @extend .font;
  font-weight: 400;
}

.font-600 {
  @extend .font;
  font-weight: 600;
}

.divider {
  height: 1px;
  background-color: $gray40;
  border: none;
}

.events-page {
  .flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
  }

  .event-spinner {
    padding-top: 19px;
  }

  .event-container {
    @extend .container;
  }

  .event-div {
    position: relative;

    &__list {
      margin-top: -19px;
    }
  }

  .event-list {
    @extend .flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 0 10px 0;
    justify-content: center;
    align-content: center;
    gap: 20px 10px;
  }

  .link-button {
    padding: 0 5px;
  }

  .event-card-container {
    padding: 16px 24px 0 24px;
    position: relative;
    width: 382px;
    height: 550px;

    .no-wrap {
      white-space: nowrap;
    }

    .event-card-status {
      .uui-caption {
        font-family: $sans-pro-source;
      }
    }

    .event-card-location {
      font-family: $sans-pro-source;
      padding-left: 4px;
    }

    .event-card-homesite {
      min-height: 24px;
      padding: 6px 0;
    }

    .event-card-description {
      font-family: $sans-pro-source;
      font-size: 12px;
    }

    .date-line {
      background-color: $gray20;
      padding: 5px;
      font-size: 12px;
      font-family: $sans-pro-source;

      &__title {
        background-color: $gray20;
        padding: 5px;
        color: $gray60;
        font-size: 12px;
        font-family: $sans-pro-source;
      }
    }

    .event-card-schedule {
      @extend .font-400;
      padding: 0;
      font-size: 12px;
      font-family: $sans-pro-source;
    }

    .event-buttons {
      padding-top: 50px;
    
      .view-project-btn {
            
        .uui-caption {
          width: 105px;
          height: 36px;
          padding: 9px 6px;
          text-align: center;
        }
      }
      
      .editor-mode {
        background-color: $bright-blue;
        color: white;
    
        &:not(.isDisabled):hover {
          background-color: #0079b5;
          color: white;
        }

        &.isDisabled {
          background-color: transparent;
          color: #CED0DB;
        }
      }
    }

    .event-card-date-line {
      min-height: 29px;

      .event-card-frame {
        padding: 3px 0;
      }
    }

    .event-card-main {
      @extend .flex;
      height: 62%;
      flex-direction: column;
    }

    .grow {
      flex-grow: 1;
    }

    .event-card-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 10px 20px 24px 24px;
      align-items: center;
    }

    .event-card-title {
      font-family: $sans-pro-semi-bold;
      font-weight: 600;
      font-style: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .no-padding {
      padding: 0;
    }
  }

  .event-timezones {
    @extend .flex;
    flex-wrap: wrap;
    justify-content: left;
    padding-top: 2px;
  }

  .gray-text {
    color: $gray60;
  }

  .dashed-text {
    text-decoration: underline dashed $gray60;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
  }

  .paginator-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 24px 0;
  }

  .add-events-button {
    padding-right: 16px;
    padding-left: 16px;

    .uui-caption {
      padding: 9px 6px;
      font-size: 14px;
      font-weight: 700;
    }

    .uui-icon {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
