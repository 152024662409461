/* Main Colors */
/* Gray */
.root {
  background-color: #E1E3EB;
  font-family: "Sans Regular", Arial, sans-serif;
  color: white;
}
.root :global(.bar) {
  background-color: #008ACE;
}
.root :global(.topLabel) {
  color: #1D1E26;
}
.root.striped :global(.bar) {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0.1) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  animation: animate-stripes 1s linear infinite;
}

@keyframes animate-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.size-12 {
  height: 12px;
  font-size: 12px;
  line-height: 12px;
}

.size-18 {
  height: 18px;
  font-size: 14px;
  line-height: 18px;
}

.size-24 {
  height: 24px;
  font-size: 16px;
  line-height: 24px;
}