.request-to-join-modal {
  .modal-body {
    padding: 15px 24px 24px 24px;

    .form-group {
      .control-label {
        font-weight: 600;
      }

      .comments-wrapper {
        position: relative;

        .error-message {
          position: absolute;
          bottom: -18px;
          font-size: 12px;
          line-height: 18px;
          color: #e54322;
        }
      }

      .length-count-text {
        text-align: right;
        color: #6c6f80;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;

        &.has-error {
          color: #e54322;
        }
      }
    }
  }
}
