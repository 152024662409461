:global(.text-color-brand) {
  --text-color: var(--text-brand-color);
}

:global(.text-color-primary) {
  --text-color: var(--text-primary-color);
}

:global(.text-color-secondary) {
  --text-color: var(--text-secondary-color);
}

:global(.text-color-disabled) {
  --text-color: var(--text-disabled-color);
}

:global(.text-color-contrast) {
  --text-color: var(--text-contrast-color);
}