/* Main Colors */
/* Gray */
.color-white {
  --background-color: white;
  --text-color: #303240;
}

.color-gray90 {
  --background-color: #1D1E26;
  --text-color: white;
}

.color-red {
  --background-color: #E54322;
  --text-color: white;
}