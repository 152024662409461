@import '../../../styles/common.scss';

.project-detail {
  &-buttons-line {
    padding-bottom: 20px;
    .project-detail-button {
      margin-left: 10px;
    }
  }

  &-modal {
    max-height: calc(100vh - 80px) !important;
    max-width: calc(100vw - 10px) !important;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    div &__close {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;
      cursor: pointer;
    }
    .section-title {
      margin-right: 12px;
      overflow: visible;
      padding: 0;
    }
    .location {
      &-title {
        margin-top: 24px;
        margin-bottom: 8px;
      }
    }
  }

  div &-modal &-container {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    gap: 20px 0;
    padding: 40px;
    font-family: $sans-pro-regular;
    background-color: white;
  }
  &-category {
    font-weight: bold;
    font-size: 24px;
    margin-right: 12px;
  }
  &-location {
    font-weight: bold;
    margin-top: 32px;
    font-size: 24px;
    line-height: 30px;
  }
  &-left {
    display: grid;
    gap: 24px;
    padding-right: 10px;
    width: 60%;
    &__info {
      display: flex;
      overflow: hidden;
      &__body {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .project-time {
          padding: 0;
          margin-top: 8px;
        }
        .project-detail__name {
          color: #303240;
          font-size: 36px;
          line-height: 48px;
          padding-top: 0;
          word-wrap: break-word;
          hyphens: auto;
        }
      }
      & &__image {
        margin-right: 20px;
        border-radius: unset;
      }
    }
  }
  &-showroom-icon-container {
    gap: 0;
  }
  &-showroom-text {
    color: #e67e17 !important;
    font-family: $sans-pro-regular, Helvetica, serif !important;
    font-size: 14px;
    line-height: 18px;
    padding-left: 7px !important;
  }
  &-showroom-icon {
    color: #e67e17 !important;
  }
  &-pending-showroom-request {
    background-color: #f5f6fa !important;
    padding: 20px !important;
    gap: 20px !important;
    margin-top: 32px !important;
  }
  &-add-button {
    padding: 0 6px !important;
  }
  &-contribute-button {
    margin: 0 12px !important;
  }

  &-container {
    .project-detail {
      &-left {
        .project {
          &-detail-statuses-container {
            gap: 20px;
            .uui-button-box .uui-caption,
            .project-detail-showroom-text {
              font-size: 12px;
              font-weight: normal;
            }
          }
          &-detail {
            &-tags {
              .project-tags-header {
                font-size: 16px;
                font-family: $sans-pro-semi-bold;
              }
            }
            &-left__description {
              font-size: 16px;
              line-height: 24px;
              width: 605px;
              word-wrap: break-word;
              hyphens: auto;
              .project-detail-left__description-header {
                font-size: 16px;
                font-family: $sans-pro-semi-bold;
              }
            }
          }
        }
        .useful-links {
          .column-head {
            font-size: 16px;
            font-family: $sans-pro-semi-bold;
          }
          .category-head {
            font-size: 14px;
            font-family: $sans-pro-semi-bold;
          }
          .item-caption {
            font-size: 14px;
            font-family: $sans-pro-regular;
          }
        }
      }

      &-right {
        width: 40%;
        .project-button {
          margin-left: 12px;
        }
        &__edit-project {
          display: flex;
          justify-content: flex-start;
        }
        &__category {
          margin-top: 32px;
          margin-bottom: 8px;
          &--text {
            font-weight: bold;
            font-size: 24px;
            margin-right: 12px;
          }
        }
        &__hide-category {
          display: none !important;
        }
    
        &__title {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 8px;
        }
        &__description {
          font-size: 14px;
          margin-bottom: 32px;
        }
        &__teams {
          margin-top: 24px;
          margin-bottom: 8px;
          min-height: unset !important;
        }
        &__location {
          min-height: unset !important;
          &-list {
            margin: 0 !important;
            list-style-type: none;
            padding-left: 0;
            &-item {
              line-height: 24px;
              padding-bottom: 8px;
              font-size: 14px;
              &--country {
                font-family: $sans-pro-regular;
                font-size: 14px;
                color: #6c6f80;
              }
              &--city {
                font-family: $sans-pro-regular;
                font-size: 14px;
                color: #6c6f80;
              }
            }
          }
        }
        .section-title,
        .project-detail__opportunities__name {
          font-size: 16px;
          font-family: $sans-pro-semi-bold;
        }
        .project-detail__opportunities__description > * {
          font-size: 14px;
          font-family: $sans-pro-regular;
        }
        .project-detail-project-owner,
        .project-detail-team-member {
          &__name {
            & div {
              font-size: 14px;
              font-family: $sans-pro-regular;
            }
          }
    
          &__role {
            & div {
              color: #6C6F80;
              font-size: 12px;
              font-family: $sans-pro-regular;
            }
          }
        }
      }
    }
  }
}

.project-time {
  padding-left: 24px;
  padding-bottom: 29px;
  padding-top: 30px;
  div & &__item {
    min-height: initial;
  }
  .time-text {
    font-family: $sans-pro-regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    padding: 0;
  }
  & &__title {
    color: #6c6f80 !important;
    width: 80px;
  }
  & &__value {
    color: #303240;
  }
}

.showroom-clockIcon {
  height: 40px;
  width: 40px;
}

@media screen and (max-width: 800px) {
  .project-detail {
    &-left {
      width: 100%;
    }
    &-right {
      width: 100%;
    }
  }
}
