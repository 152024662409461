/* Main Colors */
/* Gray */
.divider {
  margin-right: 3px;
  border-left: 1px solid #CED0DB;
  height: 24px;
}

.dropdownDeleteIcon :global(.uui-icon) {
  visibility: hidden;
}
.dropdownDeleteIcon:hover :global(.uui-icon) {
  visibility: visible;
}

.presetsWrapper {
  min-height: 60px;
}
.presetsWrapper :not(:first-child).addPresetContainer {
  padding-left: 6px;
}