/* Main Colors */
/* Gray */
:local(.typography-header) :global(.hero-header), :local(.typography-header) h1, :local(.typography-header) h2, :local(.typography-header) h3, :local(.typography-header) h4, :local(.typography-header) h5, :local(.typography-header) h6 {
  margin: 0;
}
:local(.typography-header) :global(.hero-header) {
  font-family: "Museo Slab", "Roboto Slab", Arial, sans-serif;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
:local(.typography-header) :global(.hero-header), :local(.typography-header) :global(.promo-header) {
  color: #1D1E26;
  text-align: center;
}
:local(.typography-header) :global(.promo-header), :local(.typography-header) h1, :local(.typography-header) h2, :local(.typography-header) h3 {
  font-family: "Museo Sans", "Sans Semibold", Arial, sans-serif;
}
:local(.typography-header) :global(.promo-header) {
  text-transform: uppercase;
}
:local(.typography-header) h4, :local(.typography-header) h5, :local(.typography-header) h6 {
  font-family: "Sans Semibold", Arial, sans-serif;
}
:local(.typography-header) h1, :local(.typography-header) h2, :local(.typography-header) h3, :local(.typography-header) h4, :local(.typography-header) h5, :local(.typography-header) h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
:local(.typography-header) h1 {
  font-size: 36px;
}
:local(.typography-header) h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
:local(.typography-header) h2:global(.promo-header) {
  letter-spacing: 2px;
}
:local(.typography-header) h3 {
  font-size: 24px;
}
:local(.typography-header) h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
:local(.typography-header) h5 {
  font-size: 16px;
}
:local(.typography-header) h6 {
  font-size: 14px;
}

:local(.typography-block) ol, :local(.typography-block) ul {
  padding: 0;
}
:local(.typography-block) li {
  margin-left: 1.25em;
  line-height: 1.5;
}
:local(.typography-block) pre {
  padding: 1.25em;
  color: #F5F6FA;
  background-color: #474A59;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
:local(.typography-block) img {
  max-width: 100%;
}
:local(.typography-block) figure {
  margin: 0;
}
:local(.typography-block) figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
:local(.typography-block) p {
  margin: 0.5em 0;
  line-height: 1.5;
}

:local(.typography-inline) b, :local(.typography-inline) strong {
  font-family: "Sans Semibold", Arial, sans-serif;
}
:local(.typography-inline) i, :local(.typography-inline) em {
  font-family: "Sans Italic", Arial, sans-serif;
}
:local(.typography-inline) a {
  display: inline-flex;
  align-items: center;
  color: #008ACE;
}
:local(.typography-inline) a:visited {
  color: #5214CC;
}
:local(.typography-inline) a:visited:hover {
  color: #3F0F9E;
}
:local(.typography-inline) a:hover {
  text-decoration: underline;
  color: #0079b5;
}
:local(.typography-inline) code {
  padding: 0.125em 0.25em;
  color: #303240;
  background-color: #E1E3EB;
  font-family: "Roboto Mono", Arial, sans-serif;
  white-space: pre-wrap;
  word-wrap: normal;
}

:local(.typography-16) {
  font-size: 16px;
}

:local(.typography-14) {
  font-size: 14px;
}

:local(.typography-12) {
  font-size: 12px;
}

:local(.typography-promo) {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #303240;
  composes: typography-header;
  composes: typography-block;
  composes: typography-inline;
}