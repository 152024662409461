/* Main Colors */
/* Gray */
.icon-container-color-blue {
  --fill-color: #008ACE;
}

.icon-container-color-green {
  --fill-color: #88CC00;
}

.icon-container-color-amber {
  --fill-color: #FFC000;
}

.icon-container-color-red {
  --fill-color: #E54322;
}

.icon-container-color-cyan {
  --fill-color: #14CCCC;
}

.icon-container-color-orange {
  --fill-color: #E67E17;
}

.icon-container-color-purple {
  --fill-color: #AD14CC;
}

.icon-container-color-violet {
  --fill-color: #5214CC;
}

.icon-container-color-white {
  --fill-color: white;
}

.icon-container-color-gray5 {
  --fill-color: #FAFAFC;
}

.icon-container-color-gray10 {
  --fill-color: #F5F6FA;
}

.icon-container-color-gray20 {
  --fill-color: #EBEDF5;
}

.icon-container-color-gray30 {
  --fill-color: #E1E3EB;
}

.icon-container-color-gray30 {
  --fill-color: #E1E3EB;
}

.icon-container-color-gray40 {
  --fill-color: #CED0DB;
}

.icon-container-color-gray50 {
  --fill-color: #ACAFBF;
}

.icon-container-color-gray60 {
  --fill-color: #6C6F80;
}

.icon-container-color-gray70 {
  --fill-color: #474A59;
}

.icon-container-color-gray80 {
  --fill-color: #303240;
}

.icon-container-color-gray90 {
  --fill-color: #1D1E26;
}