.root:hover :global(.uui-checkbox), .root:active :global(.uui-checkbox), .root:focus-within :global(.uui-checkbox) {
  border-color: var(--checkbox-border-color-hover);
}
.root:hover :global(.uui-checked), .root:active :global(.uui-checked), .root:focus-within :global(.uui-checked) {
  background-color: var(--checkbox-bg-color-checked-hover);
}
.root :global(.uui-checkbox) {
  background-color: var(--checkbox-bg-color);
  border: 1px solid var(--checkbox-border-color);
  border-radius: var(--checkbox-border-radius);
}
.root :global(.uui-input-label) {
  font-family: var(--font-regular);
  color: var(--checkbox-label-color);
  margin-left: 12px;
  flex-grow: 1;
}
.root :global(.uui-checked) {
  background-color: var(--checkbox-bg-color-checked);
  border-color: var(--checkbox-border-color-checked);
  fill: var(--checkbox-fill-color);
}
.root:global(.uui-disabled), .root:global(.uui-readonly) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-checkbox), .root:global(.uui-readonly) :global(.uui-checkbox) {
  cursor: default;
  background-color: var(--checkbox-bg-color-disabled);
  border-color: var(--checkbox-border-color-disabled);
}
.root:global(.uui-disabled):hover:global(.uui-checkbox), .root:global(.uui-disabled):global(.uui-checked):global(.uui-checkbox), .root:global(.uui-disabled):focus-within:global(.uui-checkbox), .root:global(.uui-readonly):hover:global(.uui-checkbox), .root:global(.uui-readonly):global(.uui-checked):global(.uui-checkbox), .root:global(.uui-readonly):focus-within:global(.uui-checkbox) {
  border-color: var(--checkbox-border-color-checked-disabled);
}
.root:global(.uui-disabled):hover:global(.uui-icon), .root:global(.uui-disabled):global(.uui-checked):global(.uui-icon), .root:global(.uui-disabled):focus-within:global(.uui-icon), .root:global(.uui-readonly):hover:global(.uui-icon), .root:global(.uui-readonly):global(.uui-checked):global(.uui-icon), .root:global(.uui-readonly):focus-within:global(.uui-icon) {
  background-color: var(--checkbox-bg-color-checked-disabled);
  border-color: var(--checkbox-border-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-input-label), .root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--checkbox-label-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked), .root:global(.uui-readonly) :global(.uui-checked) {
  background-color: var(--checkbox-bg-color-checked-disabled);
  border-color: var(--checkbox-border-color-checked-disabled);
}
.root:global(.uui-invalid) :global(.uui-checkbox) {
  border-color: var(--checkbox-error-color);
  fill: var(--checkbox-error-color);
}
.root:global(.uui-invalid) :global(.uui-checkbox):hover, .root:global(.uui-invalid) :global(.uui-checkbox):active, .root:global(.uui-invalid) :global(.uui-checkbox):focus-within {
  border-color: var(--checkbox-error-color);
  fill: var(--checkbox-error-color);
}
.root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked) {
  fill: var(--checkbox-fill-color);
  background-color: var(--checkbox-error-color);
  border-color: var(--checkbox-error-color);
}
.root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):hover, .root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):active, .root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):focus-within {
  background-color: var(--checkbox-error-color);
  border-color: var(--checkbox-error-color);
}
.root:global(.uui-invalid) :global(.uui-input-label) {
  color: var(--checkbox-error-color);
}
.root.size-18 :global(.uui-checkbox) {
  width: 18px;
  height: 18px;
}
.root.size-18 :global(.uui-checkbox) :global(.uui-icon) svg {
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-12 :global(.uui-checkbox) {
  width: 12px;
  height: 12px;
}
.root.size-12 :global(.uui-checkbox) :global(.uui-icon) svg {
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  line-height: 12px;
}
.root.size-12 :global(.uui-input-label) {
  margin-left: 6px;
}