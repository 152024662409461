@import '../../../../../styles/base';

.technologies {
  .uui-input-box {
    margin-bottom: 3px;
  }
  .uui-button-box.uui-enabled {
    background-color: #e3fcfc;
  }

  .uui-label-top {
    .uui-label {
      font-family: $sans-pro-source;
      font-weight: 600;
    }
  }
}
