.root:global(.uui-dropdown-body) {
  display: flex;
  flex-direction: column;
  min-width: 150px;
  box-sizing: border-box;
  box-shadow: var(--dropdown-container-shadow);
  background-color: var(--surface-color);
  border: 0;
  overflow: hidden;
}
.root:global(.uui-dropdown-body) ::-webkit-scrollbar {
  width: 5px;
}
.root:global(.uui-dropdown-body) ::-webkit-scrollbar-thumb {
  background: var(--dropdown-container-scrollbar-thumb-color);
}