/* Main Colors */
/* Gray */
.root :global(.uui-count) {
  font-family: "Sans Semibold", Arial, sans-serif;
  margin: auto;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 9px;
  padding-left: 6px;
  padding-right: 6px;
  min-width: 6px;
  text-align: center;
}
.root :global(.uui-count):not(:first-child) {
  margin-left: 6px;
}
.root :global(.uui-caption) {
  font-family: "Sans Regular", Arial, sans-serif;
}
.root:global(.uui-button-box) {
  border-width: 1px;
}
.root.size-18 {
  min-width: 18px;
  min-height: 18px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 9px;
}
.root.size-18 :global(.uui-count) {
  height: 12px;
  line-height: 12px;
  font-size: 8px;
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 3px;
}
.root.size-18 :global(.uui-count):not(:first-child) {
  margin-left: 3px;
}
.root.size-18 :global(.uui-caption) {
  padding: 2px 3px;
  line-height: 12px;
  font-size: 12px;
}
.root.size-18.fill-transparent {
  padding-left: 0;
  padding-right: 0;
}
.root.size-18.fill-transparent :global(.uui-caption) {
  margin-left: 17px;
  padding-right: 0;
  padding-left: 0;
}
.root.size-18.fill-transparent::before {
  width: 8px;
  height: 8px;
  top: 4px;
  left: 1px;
}
.root.size-24 {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 12px;
  min-height: 24px;
}
.root.size-24 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-24 :global(.uui-caption) {
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 18px;
  font-size: 14px;
}
.root.size-24.fill-transparent {
  padding-left: 0;
  padding-right: 0;
}
.root.size-24.fill-transparent :global(.uui-caption) {
  margin-left: 23px;
  padding-right: 0;
  padding-left: 0;
}
.root.size-24.fill-transparent::before {
  width: 12px;
  height: 12px;
  top: 5px;
  left: 2px;
}
.root.size-30 {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
  min-height: 30px;
}
.root.size-30 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-30 :global(.uui-caption) {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 18px;
  font-size: 14px;
}
.root.size-30.fill-transparent {
  padding-left: 0;
  padding-right: 0;
}
.root.size-30.fill-transparent :global(.uui-caption) {
  margin-left: 23px;
  padding-right: 0;
  padding-left: 0;
}
.root.size-30.fill-transparent::before {
  width: 12px;
  height: 12px;
  top: 8px;
  left: 2px;
}
.root.size-36 {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 18px;
  min-height: 36px;
}
.root.size-36 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-36 :global(.uui-caption) {
  padding-top: 5px;
  padding-bottom: 5px;
  line-height: 24px;
  font-size: 14px;
}
.root.size-36.fill-transparent {
  padding-left: 0;
  padding-right: 0;
}
.root.size-36.fill-transparent :global(.uui-caption) {
  margin-left: 23px;
  padding-right: 0;
  padding-left: 0;
}
.root.size-36.fill-transparent::before {
  width: 12px;
  height: 12px;
  top: 11px;
  left: 2px;
}
.root.size-42 {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
  border-radius: 21px;
  min-height: 42px;
}
.root.size-42 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-42 :global(.uui-count) {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 12px;
  min-width: 12px;
}
.root.size-42 :global(.uui-caption) {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 24px;
  font-size: 16px;
}
.root.size-42.fill-transparent {
  padding-left: 0;
  padding-right: 0;
}
.root.size-42.fill-transparent :global(.uui-caption) {
  margin-left: 29px;
  padding-right: 0;
  padding-left: 0;
}
.root.size-42.fill-transparent::before {
  width: 16px;
  height: 16px;
  top: 12px;
  left: 3px;
}
.root.size-48 {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
  border-radius: 24px;
  min-height: 48px;
}
.root.size-48 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-48 :global(.uui-count) {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 12px;
  min-width: 12px;
}
.root.size-48 :global(.uui-caption) {
  padding-top: 11px;
  padding-bottom: 11px;
  line-height: 24px;
  font-size: 16px;
}
.root.size-48.fill-transparent {
  padding-left: 0;
  padding-right: 0;
}
.root.size-48.fill-transparent :global(.uui-caption) {
  margin-left: 29px;
  padding-right: 0;
  padding-left: 0;
}
.root.size-48.fill-transparent::before {
  width: 16px;
  height: 16px;
  top: 15px;
  left: 3px;
}
.root.fill-solid {
  background-color: var(--background-color);
  color: var(--caption-color-solid);
  fill: var(--caption-color-solid);
  border-color: var(--background-color);
}
.root.fill-solid :global(.uui-count) {
  background-color: var(--count-background-color);
  color: var(--count-color);
}
.root.fill-solid:global(.-clickable):hover {
  background-color: var(--background-color-hover);
  border-color: var(--background-color-hover);
  cursor: pointer;
}
.root.fill-semitransparent {
  background-color: var(--background-color-semitransparent);
  color: var(--caption-color-semitransparent);
  fill: var(--caption-color-semitransparent);
  border-color: var(--border-color-semitransparent);
}
.root.fill-semitransparent :global(.uui-count) {
  background-color: var(--count-background-color);
  color: var(--count-color);
}
.root.fill-semitransparent:global(.-clickable):hover {
  background-color: var(--background-color-semitransparent-hover);
  color: var(--caption-color-semitransparent-hover);
  fill: var(--caption-color-semitransparent-hover);
  cursor: pointer;
}
.root.fill-transparent {
  background-color: transparent;
  color: var(--caption-color-transparent);
  fill: var(--caption-color-transparent);
  border-color: transparent;
}
.root.fill-transparent:global(.-clickable):hover {
  fill: var(--background-color-hover);
  cursor: pointer;
}
.root.fill-transparent::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  background-color: var(--background-color);
}