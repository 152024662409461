@import '../../../../../styles/common.scss';

div .project-card-item__infor {
  .project-info {
    min-height: unset;
    flex-wrap: wrap;
    @media screen and (min-width: 860px) {
      flex-wrap: nowrap;
    }

    .card-header {
      gap: 12px;

      &__title {
        font-size: 30px;
        color: #0f9e9e;
        line-height: 37px;
        padding: 0;
      }

      &__controls {
        justify-content: space-between;
        flex-grow: 1;
        gap: 12px;
      }

      &__git-button {
        & svg {
          height: 12px;
        }
      }

      &__status-badge {
        cursor: pointer;
      }
    }

    &__cover {
      border-radius: unset;
      margin-right: 30px;
    }

    &__container {
      align-self: stretch;
      flex: 100%;
      justify-content: space-between;
      margin-top: 12px;
      @media screen and (min-width: 860px) {
        flex: auto;
        margin-top: 0;
      }
    }

    &__badges {
      min-height: unset;
      margin-top: 8px;

      button {
        padding: 0;
        border: none;
        border-radius: 0;

        & .badge__caption {
          padding: 3px 6px;
        }

        &[class*='color-blue'] {
          background-color: #e3fcfc;
        }
      }
    }

    padding: 30px 40px;

    &__description {
      font-family: $sans-pro-regular;
      @include ellipsis(2, 16);
      padding: 0;

      .text-display {
        line-height: 24px;
      }
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    .keywords-tags {
      margin-bottom: 11px;
    }
  }
}
