/* Main Colors */
/* Gray */
.sub-header-wrapper {
  border-bottom: 1px solid #CED0DB;
  padding: 6px 24px;
}

.switch {
  margin-bottom: 6px;
}

.no-found-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.no-found-modal-container-icon {
  align-items: center;
  margin-bottom: 12px;
}
.no-found-modal-container-text {
  padding: 0;
  margin: 0;
}