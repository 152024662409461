/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.group-header {
  padding: 17px 0 0 0;
}

.group-name {
  --text-color: #ACAFBF;
  --background-color: #303240;
  --line-background-color: #6C6F80;
  font-family: "Sans Regular", Arial, sans-serif;
  font-size: 12px;
  color: var(--text-color);
  background: var(--background-color);
  padding: 0 14px 0 18px;
}

.line {
  --text-color: #ACAFBF;
  --background-color: #303240;
  --line-background-color: #6C6F80;
  position: absolute;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: var(--line-background-color);
  z-index: -1;
  margin-top: 11px;
}