@import '../../../../../styles/base';

.Panel_root__eSlhR {
  &.artifact-panel-section {
    &__body {
      .artificat-panel-items {
        &__body {
          overflow: auto;

          &__description {
            overflow: visible;
            padding: 12px 24px;
            font-size: 14px;
            line-height: 24px;
          }

          &__status {
            padding: 12px 0 0;

            &__title {
              margin: 0;
              padding: 0 0 8px 0;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
            }

            .showroom-label {
              width: 150px;
              border: 1px solid #e1e3eb;
              background-color: #fafafc;
              min-height: 36px;
              padding: 8px;
              font-size: 12px;
              font-style: normal;
              position: relative;

              &_add {
                width: 150px;

                .uui-caption {
                  flex-grow: 0;
                }
              }

              &__files {
                width: 150px;
                background-color: #fafafc;
                border: 1px solid #e1e3eb;
                display: flex;
                height: 101px;
                color: red;
                justify-content: left;
                padding: 40px 10px;
              }

              &__input-block {
                width: 100%;
              }

              &__invalid-field {
                border: 1px solid #e54322;
              }

              .uui-label-top {
                display: inline-block;
                .uui-label {
                  margin-bottom: 0px;
                  font-family: $sans-pro-source;
                  font-weight: 400;
                }
              }
            }

            .showroom-drop-spot {
              &__invalid-field {
                border: 2px dashed #e54322;
              }
            }
          }

          .modal-footer {
            padding: 12px 24px;
          }
        }
      }
    }
  }

  .showroom-label__files {
    .uui-label-top {
      .uui-label {
        font-family: $sans-pro-source;
        font-weight: 400;
      }
    }
  }
}
