/* Main Colors */
/* Gray */
.color-amber {
  --fill-color: #FFC000;
}

.color-green {
  --fill-color: #88CC00;
}

.color-blue {
  --fill-color: #008ACE;
}

.color-red {
  --fill-color: #E54322;
}

.color-gray60 {
  --fill-color: #6C6F80;
}