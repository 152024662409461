.render-item {
  display: flex;
  flex: auto;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker-row:global(.uui-focus) {
  background-color: var(--surface-hover-color);
}
.picker-row:global(.-clickable) {
  cursor: pointer;
}