:global(.badge-color-info) {
  --badge-bg-color: var(--info-color);
  --badge-bg-color-hover: var(--info-active-color);
  --badge-caption-color-solid: white;
  --badge-count-bg-color: white;
  --badge-count-color: var(--text-secondary-color);
}

:global(.badge-color-success) {
  --badge-bg-color: var(--success-color);
  --badge-bg-color-hover: var(--success-active-color);
  --badge-caption-color-solid: white;
  --badge-count-bg-color: white;
  --badge-count-color: var(--text-secondary-color);
}

:global(.badge-color-warning) {
  --badge-bg-color: var(--warning-color);
  --badge-bg-color-hover: var(--warning-active-color);
  --badge-caption-color-solid: white;
  --badge-count-bg-color: white;
  --badge-count-color: var(--text-secondary-color);
}

:global(.badge-color-error) {
  --badge-bg-color: var(--error-color);
  --badge-bg-color-hover: var(--error-active-color);
  --badge-caption-color-solid: var(--text-primary-color);
  --badge-count-bg-color: white;
  --badge-count-color: var(--text-secondary-color);
}