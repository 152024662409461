/* Main Colors */
/* Gray */
.preset-input-cell {
  margin-top: auto;
  margin-bottom: auto;
}
.preset-input-cell .preset-input {
  padding-left: 3px;
  padding-right: 3px;
}
.preset-input-cell .preset-input :global(.uui-icon-accept),
.preset-input-cell .preset-input :global(.uui-icon-cancel) {
  width: 30px;
  height: 30px;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preset-input-cell .preset-input :global(.uui-icon-accept):global(.-clickable) {
  fill: #FFFFFF;
  background-color: #88CC00;
}
.preset-input-cell .preset-input :global(.uui-icon-accept):global(.-clickable):hover {
  fill: #FFFFFF;
  background-color: #81c200;
}
.preset-input-cell .preset-input :global(.uui-icon-cancel) {
  background-color: #EBEDF5;
  margin-left: 1px;
}
.preset-input-cell .preset-input :global(.uui-icon-cancel):hover {
  background-color: #e4e7f2;
}