/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.root {
  --color: white;
  --fill: white;
  --background-color-hover_active: #1D1E26;
  --color-hover: white;
  --fill-hover: white;
  --color-active: white;
  --fill-active: white;
  --border-color-active: #008ACE;
  flex-shrink: 0;
  color: var(--color);
  fill: var(--fill);
}
.root :global(.uui-caption), .root :global(.uui-input) {
  padding: 22px 6px;
  line-height: 16px;
  margin-bottom: -3px;
  font-family: "Sans Semibold", Arial, sans-serif;
}
.root:global(.uui-button-box), .root:global(.uui-input-box) {
  min-height: 60px;
  padding-right: 6px;
  padding-left: 6px;
}
.root :global(.uui-caption) {
  white-space: nowrap;
  font-size: 16px;
}
.root :global(.uui-icon) svg {
  width: 18px;
}
.root.type-primary :global(.uui-icon) svg {
  height: 24px;
  max-width: 24px;
}
.root.type-secondary :global(.uui-icon) svg {
  height: 24px;
  max-width: 24px;
}
.root:global(.-clickable):hover, .root:global(.-clickable):focus {
  background-color: var(--background-color-hover_active);
  color: var(--color-hover);
  fill: var(--fill-hover);
}
.root:global(.uui-active) {
  background-color: var(--background-color-hover_active);
  border-bottom: 3px solid var(--border-color-active);
  color: var(--color-active);
  fill: var(--fill-active);
}