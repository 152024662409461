/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.root {
  --color: #303240;
  --color-disabled: #6C6F80;
  --color-errored: #E54322;
  --fill-color: #6C6F80;
  --optional-text-color: #6C6F80;
  width: 100%;
}
.root :global(.uui-label) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
  color: var(--color);
}
.root :global(.uui-label) :global(.uui-labeled-input-info-icon) {
  flex-shrink: 0;
  margin: 0 4px;
  fill: var(--fill-color);
}
.root :global(.uui-label) :global(.uui-labeled-input-asterisk) {
  color: #E54322;
}
.root :global(.uui-label) :global(.uui-labeled-input-optional) {
  font-family: "Sans Italic", Arial, sans-serif;
  font-size: 12px;
  line-height: 18px;
  color: var(--optional-text-color);
}
.root :global(.uui-label-left) > :global(.uui-label) {
  margin-right: 5px;
}
.root :global(.uui-invalid-message) {
  line-height: 18px;
  font-size: 12px;
  color: var(--color-errored);
  font-family: "Sans Regular", Arial, sans-serif;
  margin-top: 3px;
}
.root.size-24 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-24 :global(.uui-label) {
  font-size: 12px;
  line-height: 18px;
}
.root.size-30 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-30 :global(.uui-label) {
  font-size: 12px;
  line-height: 18px;
}
.root.size-36 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-36 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-42 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-42 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-48 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-48 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}