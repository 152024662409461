/* Main Colors */
/* Gray */
.badge-color-blue {
  --background-color: #008ACE;
  --background-color-hover: #00689b;
  --background-color-semitransparent: #ceefff;
  --background-color-semitransparent-hover: #9bdeff;
  --border-color-semitransparent: #9bdeff;
  --caption-color-solid: white;
  --caption-color-semitransparent: #00689b;
  --caption-color-semitransparent-hover: #004668;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-cyan {
  --background-color: #14CCCC;
  --background-color-hover: #0f9e9e;
  --background-color-semitransparent: #e3fcfc;
  --background-color-semitransparent-hover: #b4f8f8;
  --border-color-semitransparent: #b4f8f8;
  --caption-color-solid: white;
  --caption-color-semitransparent: #0f9e9e;
  --caption-color-semitransparent-hover: #0b6f6f;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-green {
  --background-color: #88CC00;
  --background-color-hover: #669900;
  --background-color-semitransparent: #eeffcc;
  --background-color-semitransparent-hover: #ddff99;
  --border-color-semitransparent: #ddff99;
  --caption-color-solid: white;
  --caption-color-semitransparent: #669900;
  --caption-color-semitransparent-hover: #446600;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-amber {
  --background-color: #FFC000;
  --background-color-hover: #995A00;
  --background-color-semitransparent: #fff2cc;
  --background-color-semitransparent-hover: #ffe699;
  --border-color-semitransparent: #ffe699;
  --caption-color-solid: #303240;
  --caption-color-semitransparent: #995A00;
  --caption-color-semitransparent-hover: #804000;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-orange {
  --background-color: #E67E17;
  --background-color-hover: #89370E;
  --background-color-semitransparent: #fae4cf;
  --background-color-semitransparent-hover: #f6cba0;
  --border-color-semitransparent: #f6cba0;
  --caption-color-solid: white;
  --caption-color-semitransparent: #89370E;
  --caption-color-semitransparent-hover: #72250B;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-red {
  --background-color: #E54322;
  --background-color-hover: #A72014;
  --background-color-semitransparent: #faded9;
  --background-color-semitransparent-hover: #f5b7ab;
  --border-color-semitransparent: #f5b7ab;
  --caption-color-solid: white;
  --caption-color-semitransparent: #A72014;
  --caption-color-semitransparent-hover: #901111;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-purple {
  --background-color: #AD14CC;
  --background-color-hover: #860f9e;
  --background-color-semitransparent: #f2ccfa;
  --background-color-semitransparent-hover: #e79df5;
  --border-color-semitransparent: #e79df5;
  --caption-color-solid: white;
  --caption-color-semitransparent: #860f9e;
  --caption-color-semitransparent-hover: #5e0b6f;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-violet {
  --background-color: #5214CC;
  --background-color-hover: #3f0f9e;
  --background-color-semitransparent: #dbccfa;
  --background-color-semitransparent-hover: #bb9df5;
  --border-color-semitransparent: #bb9df5;
  --caption-color-solid: white;
  --caption-color-semitransparent: #3f0f9e;
  --caption-color-semitransparent-hover: #2d0b6f;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}

.badge-color-gray30 {
  --background-color: #E1E3EB;
  --background-color-hover: #CED0DB;
  --background-color-semitransparent: #EBEDF5;
  --background-color-semitransparent-hover: #E1E3EB;
  --caption-color-solid: #303240;
  --border-color-semitransparent: #CED0DB;
  --caption-color-semitransparent: #303240;
  --caption-color-semitransparent-hover: #303240;
  --caption-color-transparent: #303240;
  --count-background-color: white;
  --count-color: #6C6F80;
}