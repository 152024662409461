/* Main Colors */
/* Gray */
.root:global(.uui-input-box) {
  width: 100%;
  box-sizing: border-box;
  min-width: 40px;
  position: relative;
  display: flex;
  outline: none;
  border: 1px solid #CED0DB;
  background-color: #FFFFFF;
  padding-left: 6px;
  padding-right: 6px;
}
.root:global(.uui-input-box):global(.-clickable):hover {
  cursor: pointer;
}
.root:global(.uui-input-box):hover {
  border-color: #CED0DB;
  background-color: #F5F6FA;
}
.root:global(.uui-input-box):global(.uui-opened) {
  border-color: #303240;
  background-color: #F5F6FA;
}
.root:global(.uui-input-box) .title-wrapper {
  font-family: "Sans Regular", Arial, sans-serif;
  flex: 1 1 auto;
  min-width: 0;
  outline: none;
  background: none;
  cursor: pointer;
  white-space: nowrap;
}
.root:global(.uui-input-box) .title-wrapper .title {
  text-overflow: initial;
  overflow: visible;
  padding-top: 0;
  padding-bottom: 0;
}
.root:global(.uui-input-box) .title-wrapper .text-wrapper {
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  padding-left: 3px;
}
.root:global(.uui-input-box) .title-wrapper .selection {
  padding-top: 0;
  padding-bottom: 0;
}
.root:global(.uui-input-box) .title-wrapper .selection div {
  display: inline-block;
  margin-right: 3px;
}
.root:global(.uui-input-box) .title-wrapper .selection div :nth-last-child {
  margin-right: 0;
}
.root:global(.uui-input-box) .title-wrapper .postfix {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 6px;
  overflow: visible;
}
.root:global(.uui-input-box) .title-wrapper .selected {
  color: #303240;
}
.root:global(.uui-input-box):global(.size-24) {
  font-size: 12px;
}
.root:global(.uui-input-box):global(.size-24) :global(.uui-caption), .root:global(.uui-input-box):global(.size-24) :global(.uui-input), .root:global(.uui-input-box):global(.size-24) :global(.uui-input-prefix), .root:global(.uui-input-box):global(.size-24) :global(.uui-input-suffix) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.root:global(.uui-input-box):global(.size-24):global(.uui-button-box), .root:global(.uui-input-box):global(.size-24):global(.uui-input-box) {
  min-height: 24px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-30) :global(.uui-caption), .root:global(.uui-input-box):global(.size-30) :global(.uui-input), .root:global(.uui-input-box):global(.size-30) :global(.uui-input-prefix), .root:global(.uui-input-box):global(.size-30) :global(.uui-input-suffix) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.root:global(.uui-input-box):global(.size-30):global(.uui-button-box), .root:global(.uui-input-box):global(.size-30):global(.uui-input-box) {
  min-height: 30px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-36) :global(.uui-caption), .root:global(.uui-input-box):global(.size-36) :global(.uui-input), .root:global(.uui-input-box):global(.size-36) :global(.uui-input-prefix), .root:global(.uui-input-box):global(.size-36) :global(.uui-input-suffix) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root:global(.uui-input-box):global(.size-36):global(.uui-button-box), .root:global(.uui-input-box):global(.size-36):global(.uui-input-box) {
  min-height: 36px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-42) :global(.uui-caption), .root:global(.uui-input-box):global(.size-42) :global(.uui-input), .root:global(.uui-input-box):global(.size-42) :global(.uui-input-prefix), .root:global(.uui-input-box):global(.size-42) :global(.uui-input-suffix) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root:global(.uui-input-box):global(.size-42):global(.uui-button-box), .root:global(.uui-input-box):global(.size-42):global(.uui-input-box) {
  min-height: 42px;
  border-width: 1px;
}
.root:global(.uui-input-box):global(.size-48) :global(.uui-caption), .root:global(.uui-input-box):global(.size-48) :global(.uui-input), .root:global(.uui-input-box):global(.size-48) :global(.uui-input-prefix), .root:global(.uui-input-box):global(.size-48) :global(.uui-input-suffix) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.root:global(.uui-input-box):global(.size-48):global(.uui-button-box), .root:global(.uui-input-box):global(.size-48):global(.uui-input-box) {
  min-height: 48px;
  border-width: 1px;
}
.root:global(.uui-input-box) :global(.uui-icon-dropdown) {
  fill: #303240;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 3px;
}