/* Main Colors */
/* Gray */
.root {
  min-width: 36px;
  color: var(--text-color);
  fill: var(--text-color);
  background-color: transparent;
  cursor: pointer;
  padding-left: 9px;
  padding-right: 9px;
  position: relative;
  bottom: -1px;
  display: flex;
  align-items: center;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
  border-bottom: 3px solid transparent;
}
.root:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root:hover, .root:focus {
  color: var(--text-color-active);
  fill: var(--text-color-active);
}
.root:global(.uui-active) {
  color: var(--text-color-active);
  fill: var(--text-color-active);
  border-color: var(--text-color-active);
}
.root:global(.uui-active) {
  border-color: var(--text-color-active);
}
.root:global(.uui-disabled) {
  color: var(--text-color-disabled);
  fill: var(--text-color-disabled);
  background-color: transparent;
}
.root:global(.uui-disabled):hover, .root:global(.uui-disabled):focus {
  color: var(--text-color-disabled);
  fill: var(--text-color-disabled);
}
.root:global(.uui-disabled):global(.uui-active) {
  color: var(--text-color-disabled);
  fill: var(--text-color-disabled);
  border-color: var(--text-color-disabled);
}
.root.uui-notification :global(.uui-caption) {
  position: relative;
}
.root.uui-notification :global(.uui-caption)::after {
  position: absolute;
  content: "";
  margin-bottom: 18px;
  height: 6px;
  width: 6px;
  background: #E54322;
  box-shadow: 0 1px 8px 0 #E54322;
  border-radius: 50%;
}
.root :global(.uui-count) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  letter-spacing: 0;
  color: #6C6F80;
  background-color: #E1E3EB;
  padding: 0 6px;
  margin-left: 6px;
  margin-right: 3px;
  border-radius: 9px;
}
.root :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: left;
}
.root.size-36 {
  min-height: 36px;
}
.root.size-48 {
  min-height: 48px;
}
.root.size-60 {
  min-height: 60px;
}