/* Main Colors */
/* Gray */
.icon-color-blue {
  --icon-color: #008ACE;
  --icon-color-hover: #0079b5;
  --icon-color-active: #00689b;
  --icon-color-disabled: #ACAFBF;
  --icon-color-invalid: #E54322;
}

.icon-color-green {
  --icon-color: #88CC00;
  --icon-color-hover: #77b300;
  --icon-color-active: #669900;
  --icon-color-disabled: #ACAFBF;
  --icon-color-invalid: #E54322;
}

.icon-color-amber {
  --icon-color: #FFC000;
  --icon-color-hover: #e6ad00;
  --icon-color-active: #cc9a00;
  --icon-color-disabled: #ACAFBF;
  --icon-color-invalid: #E54322;
}

.icon-color-red {
  --icon-color: #E54322;
  --icon-color-hover: #d53919;
  --icon-color-active: #be3316;
  --icon-color-disabled: #ACAFBF;
  --icon-color-invalid: #E54322;
}

.icon-color-gray30 {
  --icon-color: #E1E3EB;
  --icon-color-hover: #FFFFFF;
  --icon-color-active: #FAFAFC;
  --icon-color-disabled: #6C6F80;
  --icon-color-invalid: #E54322;
}

.icon-color-gray50 {
  --icon-color: #ACAFBF;
  --icon-color-hover: #6C6F80;
  --icon-color-active: #474A59;
  --icon-color-disabled: #CED0DB;
  --icon-color-invalid: #E54322;
}

.icon-color-gray60 {
  --icon-color: #6C6F80;
  --icon-color-hover: #474A59;
  --icon-color-active: #303240;
  --icon-color-disabled: #ACAFBF;
  --icon-color-invalid: #E54322;
}