@import '../../styles/base';

.stepper-root {
  padding: 24px;

  .step-root {
    padding: 0;

    .step-label-root {
      .step-counter {
        padding-right: 12px;

        .MuiSvgIcon-root {
          width: 36px;
          height: 36px;
          border-radius: 100px;
          border: 2px solid #ced0db;
          color: #fafafc;

          .MuiStepIcon-text {
            fill: #acafbf;
            font-family: $sans-pro-bold;
            font-size: 14px;
            font-style: normal;
            line-height: 18px;
            transform: translate(0, 0.5px);
          }

          &.Mui-active {
            border-radius: 100px;
            border: 2px solid #008ace;
            color: #ceefff;

            .MuiStepIcon-text {
              fill: #008ace;
            }
          }
        }

        .circle {
          width: 36px;
          height: 36px;
          border-radius: 100px;
          border: 2px solid #ced0db;
          background-color: #fafafc;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;

          .circle-text {
            color: #acafbf;
            font-family: $sans-pro-source;
            font-weight: 600;
            font-size: 14px;
            font-style: normal;
            line-height: 18px;
          }

          &.circle-active {
            border-radius: 100px;
            border: 2px solid #008ace;
            background-color: #ceefff;

            .circle-text {
              color: #008ace;
            }
          }
        }
      }

      .step-label {
        .MuiStepLabel-label {
          color: #303240;
          font-family: $sans-pro-source;
          font-weight: 600;
          font-size: 14px;
          font-style: normal;
          line-height: 18px;

          &.step-active {
            color: #008ace;
            font-family: $sans-pro-source;
            line-height: 24px;
          }
        }
      }
    }
  }

  .MuiStepConnector-root {
    padding: 0 24px;

    &.last-spacer {
      flex: 1 1 auto;

      .last-horizontal-border {
        display: block;
        border-color: #ced0db;
        border-top-style: solid;
        border-top-width: 2px;
      }
    }

    .css-z7uhs0-MuiStepConnector-line {
      border-color: #ced0db;
      border-top-width: 2px;
    }
  }
}
