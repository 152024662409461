.container {
  position: relative;
}
.container.day-selection .from-picker :global(.uui-datepickerheader-nav-icon-right) {
  visibility: hidden;
}
.container.day-selection .to-picker :global(.uui-datepickerheader-nav-icon-left) {
  visibility: hidden;
}
.container .picker-wrapper {
  position: relative;
}
.container .bodes-wrapper {
  transition: height 0.3s ease-in-out;
}
.container .blocker {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

:global(.uui-datepicker-container) {
  transition: height 0.3s ease-in-out;
}