:global(.button-vars) {
  --button-border-radius: var(--border-radius);
}

:global(.button-color-primary) {
  --button-bg-color: var(--primary-color);
  --button-bg-color-hover: var(--primary-dark-color);
  --button-bg-color-active: var(--primary-darkest-color);
  --button-bg-color-halftone: var(--button-bg-halftone-color);
  --button-bg-color-halftone-hover: var(--primary-lightest-color);
  --button-bg-color-halftone-active: var(--primary-light-color);
  --button-bg-color-disabled: var(--disabled-color);
  --button-border-color: var(--primary-color);
  --button-border-color-hover: var(--primary-dark-color);
  --button-border-color-active: var(--primary-darkest-color);
  --button-border-color-disabled: var(--disabled-color);
  --button-caption-color: var(--contrast-color);
  --button-caption-halftone-color: var(--primary-color);
  --button-caption-color-disabled: var(--button-caption-disabled-color);
}

:global(.button-color-accent) {
  --button-bg-color: var(--accent-color);
  --button-bg-color-hover: var(--accent-dark-color);
  --button-bg-color-active: var(--accent-darkest-color);
  --button-bg-color-halftone: var(--button-bg-halftone-color);
  --button-bg-color-halftone-hover: var(--accent-lightest-color);
  --button-bg-color-halftone-active: var(--accent-light-color);
  --button-bg-color-disabled: var(--disabled-color);
  --button-border-color: var(--accent-color);
  --button-border-color-hover: var(--accent-dark-color);
  --button-border-color-active: var(--accent-darkest-color);
  --button-border-color-disabled: var(--disabled-color);
  --button-caption-color: var(--contrast-color);
  --button-caption-halftone-color: var(--accent-color);
  --button-caption-color-disabled: var(--button-caption-disabled-color);
}

:global(.button-color-negative) {
  --button-bg-color: var(--negative-color);
  --button-bg-color-hover: var(--negative-dark-color);
  --button-bg-color-active: var(--negative-darkest-color);
  --button-bg-color-halftone: var(--button-bg-halftone-color);
  --button-bg-color-halftone-hover: var(--negative-lightest-color);
  --button-bg-color-halftone-active: var(--negative-light-color);
  --button-bg-color-disabled: var(--disabled-color);
  --button-border-color: var(--negative-color);
  --button-border-color-hover: var(--negative-dark-color);
  --button-border-color-active: var(--negative-darkest-color);
  --button-border-color-disabled: var(--disabled-color);
  --button-caption-color: var(--contrast-color);
  --button-caption-halftone-color: var(--negative-color);
  --button-caption-color-disabled: var(--button-caption-disabled-color);
}

:global(.button-color-secondary) {
  --button-bg-color: var(--secondary-color);
  --button-bg-color-hover: var(--secondary-dark-color);
  --button-bg-color-active: var(--secondary-darkest-color);
  --button-bg-color-halftone: var(--button-bg-halftone-color);
  --button-bg-color-halftone-hover: var(--secondary-lightest-color);
  --button-bg-color-halftone-active: var(--secondary-light-color);
  --button-bg-color-disabled: var(--disabled-color);
  --button-border-color: var(--secondary-color);
  --button-border-color-hover: var(--secondary-dark-color);
  --button-border-color-active: var(--secondary-darkest-color);
  --button-border-color-disabled: var(--disabled-color);
  --button-caption-color: var(--contrast-color);
  --button-caption-halftone-color: var(--secondary-color);
  --button-caption-color-disabled: var(--button-caption-disabled-color);
}