.root {
  box-sizing: content-box;
}
.root:global(.-clickable):hover {
  background-color: var(--surface-hover-color);
  cursor: pointer;
}
.root.top-shadow {
  box-shadow: inset 0 10px 5px -10px rgba(51, 51, 51, 0.2);
}
.root.size-24 {
  min-height: 24px;
}
.root.size-30 {
  min-height: 30px;
}
.root.size-36 {
  min-height: 36px;
}
.root.size-42 {
  min-height: 42px;
}
.root.size-48 {
  min-height: 48px;
}
.root.padding-6 {
  padding-left: 6px;
  padding-right: 6px;
}
.root.padding-12 {
  padding-left: 12px;
  padding-right: 12px;
}
.root.padding-18 {
  padding-left: 18px;
  padding-right: 18px;
}
.root.padding-24 {
  padding-left: 24px;
  padding-right: 24px;
}
.root.margin-24 {
  margin: 24px;
}
.root.margin-12 {
  margin: 12px;
}
.root.vPadding-12 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.vPadding-18 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.root.vPadding-24 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.root.vPadding-36 {
  padding-top: 18px;
  padding-bottom: 18px;
}
.root.vPadding-48 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.root.spacing-6 > *:not(:last-child) {
  margin-right: 3px;
}
.root.spacing-6 > *:not(:first-child) {
  margin-left: 3px;
}
.root.spacing-12 > *:not(:last-child) {
  margin-right: 6px;
}
.root.spacing-12 > *:not(:first-child) {
  margin-left: 6px;
}
.root.spacing-18 > *:not(:last-child) {
  margin-right: 9px;
}
.root.spacing-18 > *:not(:first-child) {
  margin-left: 9px;
}
.root.border-bottom {
  border-bottom: 1px solid var(--divider-color);
}