/* Main Colors */
/* Gray */
.body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-self: stretch;
  width: 100%;
}

.search-wrapper ~ .body:not(.editMode-modal) :global(.-scrolled-vertical) {
  border-top: 1px solid var(--gray40);
}

.modal {
  border: 0;
}

.checkbox {
  padding: 6px 0;
}

.search-wrapper {
  background: white;
  padding: 9px 12px;
}

.no-found-size-24 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.no-found-size-30, .no-found-size-36, .no-found-size-42 {
  padding-top: 23px;
  padding-bottom: 23px;
}