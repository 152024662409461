/* Main Colors */
/* Gray */
.container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 60px;
  padding-left: 18px;
  padding-right: 6px;
}
.container :global(.uui-caption), .container :global(.uui-input) {
  padding-top: 21px;
  padding-bottom: 21px;
  min-width: 60px;
  line-height: 18px;
  font-size: 18px;
}

.dropdown {
  cursor: pointer;
}
.dropdown:hover {
  background: #1D1E26;
}
.dropdown:active {
  background-color: #1D1E26;
}

.open {
  background: #1D1E26;
}