.root:global(.uui-button-box) {
  background-color: transparent;
  color: var(--link-button-text-color);
  fill: var(--link-button-text-color);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):hover, .root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):focus {
  color: var(--link-button-text-color-hover);
  fill: var(--link-button-text-color-hover);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):active {
  color: var(--link-button-text-color-active);
  fill: var(--link-button-text-color-active);
}
.root:global(.uui-button-box):global(.-clickable:not(.uui-disabled)):focus {
  box-shadow: none;
}
.root:global(.uui-button-box):global(.uui-disabled) {
  color: var(--link-button-text-color-disabled);
  fill: var(--link-button-text-color-disabled);
  background-color: transparent;
}
.root:global(.uui-button-box):global(.uui-disabled):hover {
  background-color: transparent;
}
.root :global(.uui-caption) {
  font-family: var(--font-semibold);
  font-weight: normal;
}
.root.size-18 {
  min-width: 18px;
  min-width: auto;
}
.root.size-18:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root.size-18:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root.size-18:global(.uui-button-box) {
  min-height: 18px;
  border-width: 0;
}
.root.size-18 :global(.uui-caption) {
  line-height: 12px;
  font-size: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.root.size-24 {
  min-width: 24px;
  min-width: auto;
}
.root.size-24:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root.size-24:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root.size-24:global(.uui-button-box) {
  min-height: 24px;
  border-width: 0;
}
.root.size-24 :global(.uui-caption) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.root.size-30 {
  min-width: 30px;
  min-width: auto;
}
.root.size-30:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root.size-30:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root.size-30:global(.uui-button-box) {
  min-height: 30px;
  border-width: 0;
}
.root.size-30 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.size-36 {
  min-width: 36px;
  min-width: auto;
}
.root.size-36:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root.size-36:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root.size-36:global(.uui-button-box) {
  min-height: 36px;
  border-width: 0;
}
.root.size-36 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.root.size-42 {
  min-width: 42px;
  min-width: auto;
}
.root.size-42:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root.size-42:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root.size-42:global(.uui-button-box) {
  min-height: 42px;
  border-width: 0;
}
.root.size-42 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 14px;
}
.root.size-42 :global(.uui-caption) {
  font-family: var(--font-primary);
  text-transform: uppercase;
}
.root.size-48 {
  min-width: 48px;
  min-width: auto;
}
.root.size-48:global(.uui-has-left-icon) :global(.uui-caption) {
  padding-left: 6px;
}
.root.size-48:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 6px;
}
.root.size-48:global(.uui-button-box) {
  min-height: 48px;
  border-width: 0;
}
.root.size-48 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
}
.root.size-48 :global(.uui-caption) {
  font-family: var(--font-primary);
  text-transform: uppercase;
}