@use '~@epam/promo/assets/styles/colors.scss' as *;
@import 'styles/common.scss';

.project-detail {
  margin-bottom: 10px;
  &-tech {
    background-color: #e3fcfc !important;
    height: 24px;
    align-items: flex-start;
    min-height: unset !important;
  }
  &__opportunities {
    word-break: break-word;
    position: relative;
    padding: 6px;
    min-height: 80px !important;

    &.has-action {
      &:hover {
        background: #ebedf5 !important;
        cursor: pointer;
      }
    }
    &-tags {
      gap: 8px !important;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
    &__name {
      min-height: unset !important;
      & > div {
        padding-top: 0 !important;
        padding-bottom: 8px;
      }
    }
    &__description {
      min-height: unset !important;
      & > div {
        line-height: 24px;
        max-width: 321px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
      }
    }
    &__icon {
      display: none;
    }

    &__refresh {
      position: absolute;
      display: none;
      top: 7px;
      right: 2px;
    }

    &:hover & {
      &__icon {
        display: block;
      }

      &__refresh {
        display: block;
      }
    }

    .request-btn {
      width: fit-content;
      height: 36px;
      margin: 8px 0px 0px 0px;
    }
  }
}
.wrapped {
  flex-wrap: wrap;
  gap: 8px !important;
}

.current-opportunities {
  max-height: 409px;
  padding-right: 15px;
}
