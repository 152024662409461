.contribution-report {
  .contribution-container {
    .contribution-range {
      padding-top: 12px;

      .title {
        margin-bottom: 27px;

        &-invalid {
          margin-bottom: 6px;
        }
      }
    }

    .contribution-footer {
      padding-top: 0;
    }
  }
}
