/* Main Colors */
/* Gray */
.preset-dropdown-panel {
  min-width: 150px;
}

.delete-row svg {
  fill: #be3316;
}
.delete-row .delete-button {
  color: #be3316;
}

.targetOpen:global(.uui-button-box) :global(.uui-icon) {
  visibility: visible;
}