@keyframes uui-spinner {
  0% {
    transform: translateY(0);
  }
  66% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0);
  }
}
.root:global(.uui-spinner-container) {
  transition: all 0.3s;
}
.root:global(.uui-spinner-container) :global(.uui-spinner-animation) {
  width: 90px;
  height: 30px;
}
.root:global(.uui-spinner-container) :global(.uui-spinner-dot) {
  width: 10px;
  height: 10px;
  margin: 0 3px;
  border-radius: 100%;
  animation: uui-spinner 0.8s infinite ease-in-out both;
}
.root:global(.uui-spinner-container) :global(.uui-spinner-dot-1) {
  animation-delay: -0.2s;
}
.root:global(.uui-spinner-container) :global(.uui-spinner-dot-2) {
  animation-delay: -0.1s;
}
.root :global(.uui-spinner-dot) {
  background-color: var(--background-color);
}