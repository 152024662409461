.container {
  cursor: pointer;
}
.container:global(.uui-input-box) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
}
.container :global(.uui-input) {
  flex: 1 0 auto;
  min-width: 0;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
}
.container :global(.uui-input).single-input {
  flex: 1 1 auto;
}
.container :global(.uui-input).cursor-text {
  cursor: text;
}
.container :global(.uui-input-prefix) {
  white-space: nowrap;
}
.container :global(.uui-input-suffix) {
  white-space: nowrap;
}

.body {
  display: flex;
  width: 100%;
  overflow: hidden;
  outline: none;
  align-items: center;
}
.body.multiline {
  flex-wrap: wrap;
}

.actions {
  display: flex;
  flex-shrink: 0;
}

.toggler {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}