.page-header {
  &__header_background {
    height: 130px;
    left: 0px;
    padding: 0 1.2rem;
    position: relative;
  }
  &__header {
    margin: 0 auto;
    position: relative;
    height: 100%;
    max-width: 1166px;
  }
  &__title {
    position: absolute;
    width: 80%;
    top: 30px;
    font-family: 'MuseoSans 700';
    font-style: normal;
    font-size: 30px;
    line-height: 36px;
  }

  &__action-button {
    position: absolute;
    top: 30px;
    right: 0;
  }
}
