.root.size-12 :global(.uui-switch-body) {
  height: 12px;
  width: 24px;
}
.root.size-12 :global(.uui-switch-toggler) {
  height: 12px;
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  letter-spacing: 0.2px;
}
.root.size-12 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(12px);
}
.root.size-18 :global(.uui-switch-body) {
  height: 18px;
  width: 36px;
}
.root.size-18 :global(.uui-switch-toggler) {
  height: 18px;
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  letter-spacing: 0.1px;
}
.root.size-18 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(18px);
}
.root.size-24 :global(.uui-switch-body) {
  height: 24px;
  width: 48px;
}
.root.size-24 :global(.uui-switch-toggler) {
  height: 24px;
  width: 24px;
}
.root.size-24 :global(.uui-input-label) {
  font-size: 14px;
  letter-spacing: 0.1px;
}
.root.size-24 :global(.uui-checked) :global(.uui-switch-toggler) {
  transform: translateX(24px);
}
.root :global(.uui-switch-body) {
  background-color: var(--switch-bg-color);
  border: 1px solid var(--switch-border-color);
  width: 36px;
  border-radius: 18px;
  box-sizing: border-box;
}
.root :global(.uui-switch-toggler) {
  left: -1px;
  border-radius: 50%;
  box-sizing: border-box;
  background-color: var(--switch-toggler-bg-color);
  border: 1px solid var(--switch-toggler-border-color);
  transition: 0.1s transform cubic-bezier(0.4, 0, 0.2, 1);
}
.root :global(.uui-input-label) {
  font-family: var(--font-regular);
  color: var(--switch-label-color);
  margin-left: 11px;
}
.root:hover :global(.uui-switch-body), .root:focus-within :global(.uui-switch-body) {
  background-color: var(--switch-bg-color-hover);
  border-color: var(--switch-border-color-hover);
}
.root:hover :global(.uui-switch-toggler), .root:focus-within :global(.uui-switch-toggler) {
  border-color: var(--switch-toggler-border-color-hover);
}
.root:hover :global(.uui-checked):global(.uui-switch-body), .root:focus-within :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--switch-bg-color-checked-hover);
  border-color: var(--switch-border-color-checked-hover);
}
.root:hover :global(.uui-checked) :global(.uui-switch-toggler), .root:focus-within :global(.uui-checked) :global(.uui-switch-toggler) {
  border-color: var(--switch-toggler-border-color-checked-hover);
}
.root :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--switch-bg-color-checked);
  border: 1px solid var(--switch-border-color-checked);
}
.root :global(.uui-checked):global(.uui-switch-body) :global(.uui-switch-toggler) {
  border: 1px solid var(--switch-toggler-border-color-checked);
}
.root:global(.uui-disabled) :global(.uui-input-label) {
  color: var(--switch-label-color-disabled);
}
.root:global(.uui-disabled),
.root:global(.uui-disabled) :global(.uui-switch-toggler),
.root:global(.uui-disabled) :global(.uui-switch-body),
.root:global(.uui-disabled) :global(.uui-input-label) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-switch-body) {
  background-color: var(--switch-bg-color-disabled);
  border: 1px solid var(--switch-border-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-switch-toggler), .root:global(.uui-disabled):hover :global(.uui-switch-toggler), .root:global(.uui-disabled):focus-within :global(.uui-switch-toggler) {
  background-color: var(--switch-toggler-bg-color-disabled);
  border-color: var(--switch-toggler-border-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked):global(.uui-switch-body) {
  background-color: var(--switch-bg-color-checked-disabled);
  border: 1px solid var(--switch-border-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked) :global(.uui-switch-toggler) {
  background-color: var(--switch-toggler-bg-color-disabled);
  border-color: var(--switch-toggler-border-color-checked-disabled);
}