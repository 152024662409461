/* Main Colors */
/* Gray */
.root:hover :global(.uui-checkbox), .root:active :global(.uui-checkbox), .root:focus-within :global(.uui-checkbox) {
  border-color: var(--border-color-hovered);
}
.root:hover :global(.uui-checked), .root:active :global(.uui-checked), .root:focus-within :global(.uui-checked) {
  background-color: var(--background-color-checked-hovered);
}
.root :global(.uui-checkbox) {
  background-color: var(--background-color);
  border: 1px solid var(--border-color-default);
}
.root :global(.uui-input-label) {
  font-family: "Sans Regular", Arial, sans-serif;
  color: var(--text-color-default);
  margin-left: 12px;
  flex-grow: 1;
}
.root :global(.uui-input-label) b, .root :global(.uui-input-label) strong {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.root :global(.uui-input-label) i, .root :global(.uui-input-label) em {
  font-family: "Sans Italic", Arial, sans-serif;
}
.root :global(.uui-input-label) a {
  display: inline-flex;
  align-items: center;
  color: #008ACE;
}
.root :global(.uui-input-label) a:visited {
  color: #5214CC;
}
.root :global(.uui-input-label) a:visited:hover {
  color: #3F0F9E;
}
.root :global(.uui-input-label) a:hover {
  text-decoration: underline;
  color: #0079b5;
}
.root :global(.uui-input-label) code {
  padding: 0.125em 0.25em;
  color: #303240;
  background-color: #E1E3EB;
  font-family: "Roboto Mono", Arial, sans-serif;
  white-space: pre-wrap;
  word-wrap: normal;
}
.root :global(.uui-checked) {
  background-color: var(--background-color-checked);
  border-color: var(--background-color-checked);
  fill: var(--fill-color);
}
.root:global(.uui-disabled), .root:global(.uui-readonly) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-checkbox), .root:global(.uui-readonly) :global(.uui-checkbox) {
  cursor: default;
  background-color: var(--background-color-disabled);
  border-color: var(--border-color-disabled);
}
.root:global(.uui-disabled):hover:global(.uui-checkbox), .root:global(.uui-disabled):global(.uui-checked):global(.uui-checkbox), .root:global(.uui-disabled):focus-within:global(.uui-checkbox), .root:global(.uui-readonly):hover:global(.uui-checkbox), .root:global(.uui-readonly):global(.uui-checked):global(.uui-checkbox), .root:global(.uui-readonly):focus-within:global(.uui-checkbox) {
  border-color: var(--border-color-disabled);
}
.root:global(.uui-disabled):hover:global(.uui-icon), .root:global(.uui-disabled):global(.uui-checked):global(.uui-icon), .root:global(.uui-disabled):focus-within:global(.uui-icon), .root:global(.uui-readonly):hover:global(.uui-icon), .root:global(.uui-readonly):global(.uui-checked):global(.uui-icon), .root:global(.uui-readonly):focus-within:global(.uui-icon) {
  background-color: var(--background-color-checked-disabled);
  border-color: var(--border-color-checked-disabled);
}
.root:global(.uui-disabled) :global(.uui-input-label), .root:global(.uui-readonly) :global(.uui-input-label) {
  color: var(--text-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-checked), .root:global(.uui-readonly) :global(.uui-checked) {
  background-color: var(--background-color-checked-disabled);
  border-color: var(--border-color-checked-disabled);
}
.root:global(.uui-invalid) :global(.uui-checkbox) {
  border-color: var(--errored-color);
  fill: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-checkbox):hover, .root:global(.uui-invalid) :global(.uui-checkbox):active, .root:global(.uui-invalid) :global(.uui-checkbox):focus-within {
  border-color: var(--errored-color);
  fill: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked) {
  fill: #FFFFFF;
  background-color: var(--errored-color);
  border-color: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):hover, .root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):active, .root:global(.uui-invalid) :global(.uui-checkbox):global(.uui-checked):focus-within {
  background-color: var(--errored-color);
  border-color: var(--errored-color);
}
.root:global(.uui-invalid) :global(.uui-input-label) {
  color: var(--errored-color);
}
.root.size-18 :global(.uui-checkbox) {
  box-sizing: border-box;
  width: 18px;
  height: 18px;
}
.root.size-18 :global(.uui-checkbox) :global(.uui-icon) svg {
  width: 18px;
}
.root.size-18 :global(.uui-input-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-12 :global(.uui-checkbox) {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
}
.root.size-12 :global(.uui-checkbox) :global(.uui-icon) svg {
  width: 12px;
}
.root.size-12 :global(.uui-input-label) {
  font-size: 12px;
  line-height: 12px;
}
.root.size-12 :global(.uui-input-label) {
  margin-left: 6px;
}
.root.mode-cell {
  justify-content: center;
}