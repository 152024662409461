/* Main Colors */
/* Gray */
.root {
  fill: var(--icon-color);
}
.root:global(.-clickable):hover, .root:global(.-clickable):focus {
  fill: var(--icon-color-hover);
}
.root:global(.-clickable):active {
  fill: var(--icon-color-active);
}
.root:global(.uui-disabled) {
  fill: var(--icon-color-disabled);
}
.root:global(.uui-disabled):global(.-clickable):hover, .root:global(.uui-disabled):global(.-clickable):focus {
  fill: var(--icon-color-disabled);
}
.root:global(.uui-disabled):global(.-clickable):active {
  fill: var(--icon-color-disabled);
}
.root:global(.uui-invalid) {
  fill: var(--icon-color-invalid);
}