/* Main Colors */
/* Gray */
.root :global(.uui-calendar-content) {
  font-family: "Sans Regular", Arial, sans-serif;
}
.root :global(.uui-calendar-weekday) {
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  width: 34px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}
.root :global(.uui-calendar-day-cell) {
  color: #303240;
  text-align: center;
  padding: 0;
  height: 36px;
  width: 36px;
  display: flex;
  position: relative;
}
.root :global(.uui-calendar-day-cell):focus {
  outline: none;
}
.root :global(.uui-calendar-day-wrapper) {
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  margin: auto;
  cursor: default;
}
.root :global(.uui-calendar-day) {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 34px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)) {
  cursor: pointer;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)) :global(.uui-calendar-day) {
  border: 1px solid #008ACE;
  background-color: #FFFFFF;
  z-index: 1;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)):global(.uui-calendar-selected-day) :global(.uui-calendar-day) {
  border: 1px solid #0079b5;
  background-color: #0079b5;
}
.root :global(.uui-calendar-current-day):global(.uui-calendar-day-wrapper) {
  color: #008ACE;
  font-family: "Sans Semibold", Arial, sans-serif;
}
.root :global(.uui-calendar-current-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day)::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #008ACE;
  bottom: 3px;
  left: calc(50% - 2px);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day) {
  background-color: #008ACE;
  color: #FFFFFF;
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day)::after {
  background-color: #FFFFFF;
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-clickable-day):hover {
  cursor: pointer;
}
.root :global(.uui-calendar-day-holiday) {
  color: #6C6F80;
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper) {
  color: #ACAFBF;
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper):hover {
  cursor: default;
  background-color: transparent;
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper):global(.uui-calendar-selected-day) :global(.uui-calendar-day) {
  background-color: #B3B6C3;
}