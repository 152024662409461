.opportunities-page-container {
  .search-container {
    padding-top: 56px;
    &__search_div {
      position: relative;
    }
    &__search {
      position: absolute;
      height: 60px;
      bottom: 14px;
      padding: 0 23px;
      div &:hover {
        border-color: #008ace;
      }
    }
  }

  .opportunity {
    $-filter-width: 241px;

    &__content {
      padding-top: 56px;
      width: calc(100% - $-filter-width);
    }

    &__filter {
      width: $-filter-width;
      padding-right: 24px;
      margin-top: -18px;
      margin-bottom: 16px;
      z-index: 1;
    }

    &__list {
      padding-top: 11px;
    }
  }

  .loading-spinner {
    margin-bottom: 32px;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  // redundant
  .placeholder-header {
    h2 {
      font-size: 30px;
      font-family: 'MuseoSans 700';
      font-weight: 600;
      line-height: 36px;
    }
  }
  // redundant
  .placeholder-body {
    p {
      font-weight: 400;
      font-family: 'Source Sans Pro', Helvetica, serif;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
