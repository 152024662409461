.custom-data-table {
  & > .search-input {
    width: 350px;
    margin-bottom: 12px;
  }

  .column {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .img {
    height: 184px;
    margin-bottom: 32px;
  }
  .header {
    font-size: 24px;
    font-family: 'Source Sans Pro', Helvetica, serif;
    font-weight: 600;
    margin-bottom: 11px;
  }
  .no-result-message {
    font-family: 'Source Sans Pro', Helvetica, serif;
    font-size: 16px;
    color: #6c6f80;
  }

  .pagination {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .no-data-panel {
    align-items: center;
    padding: 32px;
  }
}
