/* Main Colors */
/* Gray */
.header {
  font-family: "Sans Regular", Arial, sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px 0 12px;
}
.header button.removeButton {
  padding-left: 24px;
}