/* Main Colors */
/* Gray */
.root {
  --background-color: white;
  --background-color-disabled: #FAFAFC;
  --background-color-readonly: #FAFAFC;
  --border-color: #CED0DB;
  --border-color-hovered: #6C6F80;
  --border-color-active: #008ACE;
  --border-color-errored: #E54322;
  --border-color-disabled: #E1E3EB;
  --border-color-readonly: #E1E3EB;
  --placeholder-color: #6C6F80;
  --placeholder-color-disabled: #ACAFBF;
  --text-color: #474A59;
  --text-color-disabled: #ACAFBF;
  --icon-color: #6C6F80;
  --icon-color-disabled: #ACAFBF;
  --counter-color: #6C6F80;
}
.root :global(.uui-input) {
  color: var(--text-color);
  fill: var(--icon-color);
  border-style: solid;
  font-family: "Sans Regular", Arial, sans-serif;
  box-sizing: border-box;
  padding-right: 18px;
  padding-left: 12px;
  overflow-x: hidden;
}
.root :global(.uui-input)::placeholder {
  color: var(--placeholder-color);
}
.root :global(.uui-input):placeholder-shown {
  text-overflow: ellipsis;
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-input):global(.uui-readonly) {
  color: var(--text-color);
  fill: var(--icon-color);
  pointer-events: none;
}
.root :global(.uui-input):global(.uui-disabled) {
  color: var(--text-color-disabled);
  fill: var(--icon-color-disabled);
  pointer-events: none;
}
.root :global(.uui-input):global(.uui-disabled)::placeholder {
  color: var(--placeholder-color-disabled);
}
.root.mode-form :global(.uui-input) {
  background-color: var(--background-color);
  border-color: var(--border-color);
}
.root.mode-form :global(.uui-input):hover {
  border-color: var(--border-color-hovered);
}
.root.mode-form :global(.uui-input):global(.uui-focus) {
  border-color: var(--border-color-active);
}
.root.mode-form :global(.uui-input):global(.uui-invalid) {
  border-color: var(--border-color-errored);
}
.root.mode-form :global(.uui-input):global(.uui-readonly) {
  background-color: var(--background-color-readonly);
  border-color: var(--border-color-readonly);
}
.root.mode-form :global(.uui-input):global(.uui-disabled) {
  background-color: var(--background-color-disabled);
  border-color: var(--border-color-disabled);
}
.root.mode-cell :global(.uui-input) {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.uui-input):global(.uui-readonly) {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.uui-input):global(.uui-disabled) {
  background-color: transparent;
  border-color: transparent;
}
.root :global(.uui-textarea-counter) {
  line-height: 18px;
  font-size: 12px;
  color: var(--counter-color);
  font-family: "Sans Regular", Arial, sans-serif;
}
.root.size-24 {
  min-width: 24px;
  min-height: 24px;
}
.root.size-24 :global(.uui-input) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.root.size-30 {
  min-width: 30px;
  min-height: 30px;
}
.root.size-30 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.root.size-36 {
  min-width: 36px;
  min-height: 36px;
}
.root.size-36 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root.size-42 {
  min-width: 42px;
  min-height: 42px;
}
.root.size-42 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root.size-48 {
  min-width: 48px;
  min-height: 48px;
}
.root.size-48 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
}