/* Main Colors */
/* Gray */
.root:global(.uui-button-box) {
  border-style: solid;
  justify-content: center;
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  text-align: center;
  flex: 1 1 auto;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-weight: normal;
  user-select: none;
}

.fill-solid {
  background-color: var(--background-color);
  color: var(--background-color-contrast);
  fill: var(--background-color-contrast);
  border-color: var(--border-color);
}
.fill-solid:global(.-clickable):hover, .fill-solid:global(.-clickable):focus {
  background-color: var(--background-color-hover);
  border-color: var(--background-color-hover);
}
.fill-solid:global(.-clickable):active {
  background-color: var(--background-color-active);
  border-color: var(--background-color-active);
}
.fill-solid:global(.uui-disabled) {
  background-color: var(--background-color-disabled);
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: var(--background-color-disabled);
}
.fill-solid:global(.-clickable):global(.uui-disabled), .fill-solid:hover:global(.uui-disabled), .fill-solid:active:global(.uui-disabled), .fill-solid:focus:global(.uui-disabled) {
  background-color: var(--background-color-disabled);
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: var(--background-color-disabled);
}

.fill-white {
  background-color: #FFFFFF;
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: var(--border-color);
}
.fill-white:global(.-clickable):hover, .fill-white:global(.-clickable):focus {
  background-color: var(--background-color-light-hover);
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: var(--border-color);
}
.fill-white:global(.-clickable):active {
  background-color: var(--background-color-light-active);
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: var(--border-color);
}
.fill-white:global(.uui-disabled) {
  background-color: #FFFFFF;
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: var(--background-color-disabled);
}
.fill-white:global(.-clickable):global(.uui-disabled), .fill-white:hover:global(.uui-disabled), .fill-white:active:global(.uui-disabled), .fill-white:focus:global(.uui-disabled) {
  background-color: #FFFFFF;
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: var(--background-color-disabled);
}

.fill-none {
  background-color: transparent;
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: var(--border-color);
}
.fill-none:global(.-clickable):hover, .fill-none:global(.-clickable):focus {
  background-color: var(--background-color-light-hover);
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: var(--border-color);
}
.fill-none:global(.-clickable):active {
  background-color: var(--background-color-light-active);
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: var(--border-color);
}
.fill-none:global(.uui-disabled) {
  background-color: transparent;
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: var(--background-color-disabled);
}
.fill-none:global(.-clickable):global(.uui-disabled), .fill-none:hover:global(.uui-disabled), .fill-none:active:global(.uui-disabled), .fill-none:focus:global(.uui-disabled) {
  background-color: transparent;
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: var(--background-color-disabled);
}

.fill-light {
  background-color: transparent;
  color: var(--caption-color);
  fill: var(--caption-color);
  border-color: transparent;
}
.fill-light:global(.-clickable):hover, .fill-light:global(.-clickable):focus {
  background-color: var(--background-color-light-hover);
  border-color: var(--background-color-light-hover);
}
.fill-light:global(.-clickable):active {
  background-color: var(--background-color-light-active);
  border-color: var(--background-color-light-active);
}
.fill-light:global(.uui-disabled) {
  background-color: transparent;
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: transparent;
}
.fill-light:global(.-clickable):global(.uui-disabled), .fill-light:hover:global(.uui-disabled), .fill-light:active:global(.uui-disabled), .fill-light:focus:global(.uui-disabled) {
  background-color: transparent;
  color: var(--caption-color-disabled);
  fill: var(--caption-color-disabled);
  border-color: transparent;
}

.size-24 {
  min-width: 24px;
  padding-left: 5px;
  padding-right: 5px;
}
.size-24 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-24:global(.uui-button-box) {
  min-height: 24px;
  border-width: 1px;
}
.size-24 :global(.uui-caption) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.size-30 {
  min-width: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
.size-30 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-30:global(.uui-button-box) {
  min-height: 30px;
  border-width: 1px;
}
.size-30 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.size-36 {
  min-width: 36px;
  padding-left: 5px;
  padding-right: 5px;
}
.size-36 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-36:global(.uui-button-box) {
  min-height: 36px;
  border-width: 1px;
}
.size-36 :global(.uui-caption) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.size-42 {
  min-width: 42px;
  padding-left: 11px;
  padding-right: 11px;
}
.size-42 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-42:global(.uui-button-box) {
  min-height: 42px;
  border-width: 1px;
}
.size-42 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
}
.size-42 :global(.uui-caption) {
  font-family: "Museo Sans", "Sans Semibold", Arial, sans-serif;
  text-transform: uppercase;
}

.size-48 {
  min-width: 48px;
  padding-left: 11px;
  padding-right: 11px;
}
.size-48 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.size-48:global(.uui-button-box) {
  min-height: 48px;
  border-width: 1px;
}
.size-48 :global(.uui-caption) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
}
.size-48 :global(.uui-caption) {
  font-family: "Museo Sans", "Sans Semibold", Arial, sans-serif;
  text-transform: uppercase;
}