/* Main Colors */
/* Gray */
.header {
  display: none;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 720px) {
  .header {
    display: flex;
  }
}

.close {
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  right: 24px;
}