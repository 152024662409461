/* Main Colors */
/* Gray */
.cell {
  color: #303240;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  padding: 0 12px;
  position: relative;
  width: 0;
  border-right: 1px solid transparent;
  margin-left: -1px;
  background-clip: padding-box;
}
.cell:global(.-clickable) {
  cursor: pointer;
}
.cell:global(.-clickable):hover {
  background: #EBEDF5;
  box-shadow: -1px 0 0 0 #E1E3EB, 1px 0 0 0 #E1E3EB;
}
.cell:global(.-clickable):hover .sort-icon {
  fill: #474A59;
}
.cell:global(.-clickable):hover .dropdown-icon {
  fill: #474A59;
}
.cell.size-24 {
  height: 24px;
}
.cell.size-30 {
  height: 30px;
}
.cell.size-36 {
  height: 36px;
}
.cell.size-42 {
  height: 42px;
}
.cell.size-48 {
  height: 48px;
}
.cell.padding-left-24 {
  padding-left: 24px;
}
.cell.padding-right-24 {
  padding-right: 24px;
}
.cell .caption {
  font-family: "Sans Semibold", Arial, sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell .checkbox {
  margin-right: 12px;
}
.cell .icon {
  display: flex;
  flex-shrink: 0;
}
.cell .sort-icon {
  margin-left: 1px;
}
.cell .sort-icon svg {
  width: 16px;
  height: 16px;
}
.cell .sort-icon-active {
  fill: #6C6F80;
}
.cell .dropdown-icon svg {
  width: 20px;
  height: 20px;
}
.cell .filter-icon {
  padding-left: 3px;
}
.cell .icon-cell {
  display: flex;
  overflow: hidden;
}
.cell .icon-cell.align-right {
  justify-content: flex-end;
}
.cell .icon-cell.align-center {
  justify-content: center;
}
.cell .tooltip-wrapper {
  width: 100%;
  min-width: 0;
}
.cell .cell-tooltip {
  top: 0;
}
.cell .upper-case {
  text-transform: uppercase;
  font-size: 12px;
}
.cell .font-size-14 {
  font-size: 14px;
}
.cell.resizable:hover {
  box-shadow: -1px 0 0 0 #E1E3EB, 1px 0 0 0 #E1E3EB;
}
.cell .resize-mark {
  position: absolute;
  width: 12px;
  right: 0;
  height: 100%;
  cursor: col-resize;
}
.cell.draggable {
  cursor: pointer;
}
.cell.ghost {
  background-color: #EBEDF5;
}
.cell.is-dragged-out {
  background-color: #F5F6FA;
}
.cell.is-dragged-out :local(.caption) {
  color: #ACAFBF;
}
.cell.dnd-marker-left {
  box-shadow: inset 2px 0 0 0 #008ACE;
}
.cell.dnd-marker-left:global(.-clickable), .cell.dnd-marker-left:local(.resizable) {
  box-shadow: inset 2px 0 0 0 #008ACE;
}
.cell.dnd-marker-right {
  box-shadow: inset -2px 0 0 0 #008ACE;
}
.cell.dnd-marker-right:global(.-clickable), .cell.dnd-marker-right:local(.resizable) {
  box-shadow: inset -2px 0 0 0 #008ACE;
}