/* Main Colors */
/* Gray */
.root {
  --background-color: white;
  --background-color-disabled: #FAFAFC;
  --background-color-readonly: #FAFAFC;
  --border-color: #CED0DB;
  --border-color-hovered: #6C6F80;
  --border-color-active: #008ACE;
  --border-color-errored: #E54322;
  --border-color-disabled: #E1E3EB;
  --border-color-readonly: #E1E3EB;
  --placeholder-color: #6C6F80;
  --placeholder-color-disabled: #ACAFBF;
  --text-color: #303240;
  --text-color-disabled: #ACAFBF;
  --icon-color: #ACAFBF;
  --icon-color-disabled: #ACAFBF;
  --icon-color-clickable: #6C6F80;
  --icon-color-clickable-hovered: #474A59;
  fill: var(--icon-color);
  border-style: solid;
  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;
}
.root :global(.uui-input-prefix) {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-left: -5px;
  padding: 6px;
  border-right: 1px solid #CED0DB;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.root :global(.uui-input-suffix) {
  display: flex;
  align-items: center;
  align-self: stretch;
  font-family: "Sans Regular", Arial, sans-serif;
  color: #6C6F80;
  background: #F5F6FA;
  margin-right: -5px;
  padding: 6px;
  border-left: 1px solid #CED0DB;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.root :global(.uui-input) {
  color: var(--text-color);
  font-family: "Sans Regular", Arial, sans-serif;
  padding-left: 6px;
  padding-right: 6px;
}
.root :global(.uui-input)::placeholder {
  color: var(--placeholder-color);
}
.root :global(.uui-input):placeholder-shown {
  text-overflow: ellipsis;
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root.mode-form {
  background-color: var(--background-color);
  border-color: var(--border-color);
}
.root.mode-form :global(.-clickable) {
  fill: var(--icon-color-clickable);
}
.root.mode-form :global(.-clickable):hover {
  fill: var(--icon-color-clickable-hovered);
}
.root.mode-form:hover {
  border-color: var(--border-color-hovered);
}
.root.mode-form:global(.uui-focus) {
  border-color: var(--border-color-active);
}
.root.mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.uui-input-prefix) {
  border-right: none;
}
.root.mode-cell :global(.uui-input-suffix) {
  border-left: none;
}
.root.mode-cell :global(.uui-icon):first-child {
  padding-left: 6px;
}
.root.mode-cell :global(.-clickable) {
  fill: transparent;
}
.root.mode-cell:hover :global(.-clickable) {
  fill: var(--icon-color-clickable);
}
.root.mode-cell:hover :global(.-clickable):hover {
  fill: var(--icon-color-clickable-hovered);
}
.root:global(.uui-invalid).mode-form {
  background-color: white;
  border-color: var(--border-color-errored);
}
.root:global(.uui-readonly) {
  color: var(--text-color);
  fill: var(--icon-color);
  pointer-events: none;
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--background-color-readonly);
  border-color: var(--border-color-readonly);
}
.root:global(.uui-disabled) {
  color: var(--text-color-disabled);
  fill: var(--icon-color-disabled);
  pointer-events: none;
}
.root:global(.uui-disabled) :global(.uui-input) {
  color: var(--text-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-input)::placeholder {
  color: var(--placeholder-color-disabled);
}
.root:global(.uui-disabled).mode-form {
  background-color: var(--background-color-disabled);
  border-color: var(--border-color-disabled);
}
.root.size-24 {
  min-width: 24px;
}
.root.size-24 :global(.uui-caption), .root.size-24 :global(.uui-input), .root.size-24 :global(.uui-input-prefix), .root.size-24 :global(.uui-input-suffix) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.root.size-24:global(.uui-button-box), .root.size-24:global(.uui-input-box) {
  min-height: 24px;
  border-width: 1px;
}
.root.size-30 {
  min-width: 30px;
}
.root.size-30 :global(.uui-caption), .root.size-30 :global(.uui-input), .root.size-30 :global(.uui-input-prefix), .root.size-30 :global(.uui-input-suffix) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.root.size-30:global(.uui-button-box), .root.size-30:global(.uui-input-box) {
  min-height: 30px;
  border-width: 1px;
}
.root.size-36 {
  min-width: 36px;
}
.root.size-36 :global(.uui-caption), .root.size-36 :global(.uui-input), .root.size-36 :global(.uui-input-prefix), .root.size-36 :global(.uui-input-suffix) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root.size-36:global(.uui-button-box), .root.size-36:global(.uui-input-box) {
  min-height: 36px;
  border-width: 1px;
}
.root.size-42 {
  min-width: 42px;
}
.root.size-42 :global(.uui-caption), .root.size-42 :global(.uui-input), .root.size-42 :global(.uui-input-prefix), .root.size-42 :global(.uui-input-suffix) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.root.size-42:global(.uui-button-box), .root.size-42:global(.uui-input-box) {
  min-height: 42px;
  border-width: 1px;
}
.root.size-48 {
  min-width: 48px;
}
.root.size-48 :global(.uui-caption), .root.size-48 :global(.uui-input), .root.size-48 :global(.uui-input-prefix), .root.size-48 :global(.uui-input-suffix) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 11px;
  padding-bottom: 11px;
}
.root.size-48:global(.uui-button-box), .root.size-48:global(.uui-input-box) {
  min-height: 48px;
  border-width: 1px;
}