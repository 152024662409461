/* Main Colors */
/* Gray */
.color-amber {
  --fill-color: #FFC000;
  --background-color: #fff2cc;
}

.color-green {
  --fill-color: #88CC00;
  --background-color: #eeffcc;
}

.color-blue {
  --fill-color: #008ACE;
  --background-color: #ceefff;
}

.color-red {
  --fill-color: #E54322;
  --background-color: #faded9;
}