.showroom-page {
  &__header_background {
    background: linear-gradient(89.78deg, #4181f0 3.35%, #49a8e3 99.67%);
    height: 157px;
    left: 0px;
    top: 60px;
    padding: 0 1.2rem;
  }
  &__header {
    margin: 0 auto;
    width: 1166px;
  }
  &__title {
    position: absolute;
    top: 99px;
    font-family: 'MuseoSans 700';
    font-style: normal;
    font-size: 36px;
    line-height: 48px;
    color: #ffffff;
  }
}
