@use 'sass:math';
@import 'font';
// img source
$img-path: '/assets/img';
$svg-path: '/assets/svg';

// font size
$font-size-base: 16;
$font-size-base-px: $font-size-base * 1px;

//font family
$sans-pro-black: 'Source Sans Pro Black', Arial, sans-serif;
$sans-pro-black-italic: 'Source Sans Pro Black Italic', Arial, sans-serif;
$sans-pro-bold: 'Source Sans Pro Bold', Arial, sans-serif;
$sans-pro-bold-italic: 'Source Sans Pro Bold Italic', Arial, sans-serif;
$sans-pro-extra-light: 'Source Sans Pro ExtraLight', Arial, sans-serif;
$sans-pro-extra-light-italic: 'Source Sans Pro ExtraLight Italic', Arial,
  sans-serif;
$sans-pro-family-italic: 'Source Sans Pro Italic', Arial, sans-serif;
$sans-pro-family-light: 'Source Sans Pro Light', Arial, sans-serif;
$sans-pro-light-italic: 'Source Sans Pro Light Italic', Arial, sans-serif;
$sans-pro-regular: 'Source Sans Pro Regular', Arial, sans-serif;
$sans-pro-source: 'Source Sans Pro', Arial, sans-serif;
$sans-pro-semi-bold: 'Source Sans Pro SemiBold', Arial, sans-serif;
$sans-pro-semi-bold-italic: 'Source Sans Pro SemiBold Italic', Arial, sans-serif;

// Faster taps on mobile devices
a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  touch-action: manipulation;
}

// convert px to rem
@function toRem($value) {
  $remValue: math.div($value, $font-size-base) + rem;
  @return $remValue;
}

//conver font-size heading
@mixin font-heading($heading) {
  @if $heading==h1 {
    font-size: toRem(32);
  }

  @if $heading==h2 {
    font-size: toRem(28);
  }

  @if $heading==h3 {
    font-size: toRem(24);
  }

  @if $heading==h4 {
    font-size: toRem(20);
  }

  @if $heading==h5 {
    font-size: toRem(18);
  }

  @if $heading==h6 {
    font-size: toRem(16);
  }
}
