.header {
  margin: 0 auto;
  a {
    text-decoration: none;
  }
  &__main-logo {
    padding-left: 22px;
    padding-right: 22px;
  }
  &__switch-label {
    color: white;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 24px;
  }

  .nav-item {
    & > button {
      cursor: pointer;
    }
  }
  .global-menu-style {
    float: left;
    height: 60px;
    background-color: #303240;
    cursor: pointer;
    border: none;
    padding: 0px;
  }
  &__user-menu {
    text-align: left;
    min-width: 120px;
  }
}
.modal-content {
  padding-left: 24px;
  padding-right: 84px;
}
