/* Main Colors */
/* Gray */
/* Main Colors */
/* Gray */
.root {
  --color: white;
  --fill: white;
  --color-hover: #008ACE;
  --fill-hover: #008ACE;
  --color-active: #008ACE;
  padding: 0 24px;
  background: none;
  border: 0;
  outline: none;
  font-family: "Sans Regular", Arial, sans-serif;
  line-height: 48px;
  flex-shrink: 0;
}
.root:global(.uui-enabled) {
  color: var(--color);
  fill: var(--fill);
}
.root:global(.uui-enabled):global(.-clickable):hover {
  color: var(--color-hover);
  fill: var(--fill-hover);
}
.root:global(.uui-active) {
  color: var(--color-active);
  fill: var(--color-active);
}
.root :global(.uui-icon) {
  padding: 0 12px 0 24px;
}
.root.button-primary {
  font-size: 18px;
}
.root.button-secondary {
  font-size: 14px;
  padding-left: 18px;
  margin-left: 48px;
  border-left: 1px #E1E3EB solid;
}
.root.hasIcon, .root.dropdown {
  padding-left: 0;
}
.root.hasIcon :global(.uui-icon) svg {
  height: 16px;
  max-width: 16px;
}
.root.hasIcon :global(.uui-icon) {
  padding: 0 12px 0 20px;
}