/* Main Colors */
/* Gray */
.preset:global(.uui-has-right-icon) :global(.uui-caption) {
  padding-right: 3px;
}
.preset:global(.uui-button-box) {
  padding-right: 3px;
}
.preset :global(.uui-icon) {
  visibility: hidden;
}
.preset:hover :global(.uui-icon) {
  visibility: visible;
}
.preset.activePreset :global(.uui-icon) {
  visibility: visible;
}