.tag-input {
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  padding: 0 5px;
  border: 1px solid #ced0db;
  fill: #6c6f80;
  margin-bottom: 3px;

  .keywords-input {
    display: flex;
    flex-wrap: wrap;

    input,
    .width-input-reference {
      border: none;
      font-size: 14px;
      line-height: 18px;
      font-family: 'Sans Regular', Arial, sans-serif;
      color: #303240;
    }
    input {
      padding-left: 6px;
      padding-right: 0px;
    }
    .width-input-reference {
      visibility: hidden;
      white-space: nowrap;
      position: absolute;
      top: 0;
      left: -9999px;
    }
    .autocomplete {
      margin: auto 0;
      border: none;
      font-size: 14px;
      line-height: 18px;
      font-family: 'Sans Regular', Arial, sans-serif;
      color: #c7c9d4;
      cursor: default;
    }
    input:focus {
      outline: transparent;
    }
    .tags {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 2px 0;
      .tag {
        background-color: #eeffcc;
        margin-right: 3px;
      }
    }

    .clear-button {
      position: relative;
      right: 0px;
    }
  }
}
