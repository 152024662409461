.opportunity-card {
  margin-bottom: 24px;

  & > .op-card-container {
    display: grid;
    grid-template-columns: 75% 25%;
    min-height: 200px;

    @media (max-width: 900px) {
      grid-template-columns: 100%;
    }

    & > .op-card-project-details {
      border-right: 1px solid #ebedf5;
      padding: 24px 40px;

      @media (max-width: 900px) {
        border-right: 0;
        border-bottom: 1px solid #ebedf5;
        padding: 20px;
      }

      & > .title-margin-without-tech {
        margin-bottom: 26px;
      }
      & > .title-margin-with-tech {
        margin-bottom: auto;
      }
      & > .project-title {
        display: flex;
        align-items: flex-start;
        gap: 5px;

        @media (max-width: 900px) {
          display: block;
        }

        .opportunity-name {
          color: #0f9e9e;
          font-weight: 600;
          font-size: 30px;
          line-height: 36px;
          cursor: pointer;
          white-space: nowrap;
          padding: 1px 0;
        }

        & > .date-created {
          margin-left: auto;
          color: #303240;
          min-width: 107px;

          .created-text {
            color: #acafbf;
          }
        }
      }
    }

    & > .op-card-project-owner {
      padding: 24px 0;

      & > .product-owner-information {
        padding: 0 20px;
        border-bottom: 1px solid #ebedf5;
        width: 100%;
        & > .project-owner {
          padding-left: 48px;
          position: relative;

          & > .project-owner-avatar {
            position: absolute;
            top: 0;
            left: 0;
          }

          & > .project-owner-information {
            & > .project-owner-name {
              color: #303240;
              padding: 0;
              margin-bottom: 1px;
            }

            & > .project-owner-job-title {
              color: #6c6f80;
              padding: 0;
              margin-bottom: 1px;
            }

            & > .button-icons {
              display: flex;

              & > .mail-icon,
              .teams-icon {
                margin-right: 12px;
              }
            }
          }
        }
      }

      & > .request-to-join {
        padding: 15px 20px 0;

        & > .request-btn {
          width: 100%;
        }
      }
    }
  }

  .tech-badges {
    width: 100%;

    .badge {
      background-color: #e3fcfc;
      height: 24px;
      gap: 8px;
      padding-top: 3px;
      border-radius: unset;
      border: none;
    }
  }
}
