.not-found-page {
  min-height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  & > .page-container {
    & > .text {
      font-weight: 600;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 12px;
    }

    & > .text-small {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
