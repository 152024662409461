@import 'styles/common.scss';

.project-tags-header {
  padding: 0px !important;
}

.project-tags {
  flex-wrap: wrap !important; //re-writing default styles
  gap: 8px !important;
  min-height: unset !important;
  & .project-detail-key,
  .project-detail-tech {
    margin: 0 !important;
  }
}
.project-detail {
  &-tags {
    gap: 8px !important;
  }
  &-tech {
    background-color: #e3fcfc !important;
    height: 24px;
    padding-top: 3px !important;
  }
  &-key {
    background-color: #eeffcc !important;
    height: 24px;
    padding-top: 3px !important;
  }
}
