.opportunity-filter {
  padding: 12px 18px;
  gap: 26px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__technologies {
    .uui-button-box.uui-enabled {
      background-color: #e3fcfc;
    }
  }
}
