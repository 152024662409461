.root {
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  box-sizing: border-box;
  border-style: solid;
}
.root.mode-form {
  background-color: var(--picker-toggler-bg-color);
  border-color: var(--picker-toggler-border-color);
  fill: var(--picker-toggler-icon-color);
  border-radius: var(--picker-toggler-border-radius);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-form :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: var(--picker-toggler-icon-color-clickable);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-form :global(.uui-icon-dropdown):global(.uui-enabled):hover {
  fill: var(--picker-toggler-icon-color-clickable-hover);
}
.root.mode-form :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-form :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: var(--picker-toggler-icon-color-disabled);
}
.root.mode-form:hover {
  border-color: var(--picker-toggler-border-color-hover);
}
.root.mode-form:global(.uui-focus) {
  border-color: var(--picker-toggler-border-color-active);
  outline: none;
}
.root.mode-cell {
  background-color: transparent;
  border-color: transparent;
  fill: var(--picker-toggler-icon-color);
}
.root.mode-cell :global(.uui-icon):global(.-clickable), .root.mode-cell :global(.uui-icon-dropdown) {
  cursor: pointer;
}
.root.mode-cell :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: transparent;
}
.root.mode-cell :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: transparent;
}
.root.mode-cell:hover {
  background-color: white;
  border-color: var(--picker-toggler-border-color);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-enabled), .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-enabled) {
  fill: var(--picker-toggler-icon-color-clickable);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-enabled):hover, .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-enabled):hover {
  fill: var(--picker-toggler-icon-color-clickable-hover);
}
.root.mode-cell:hover :global(.uui-icon):global(.-clickable):global(.uui-disabled), .root.mode-cell:hover :global(.uui-icon-dropdown):global(.uui-disabled) {
  fill: transparent;
}
.root.mode-cell:global(.uui-focus) {
  background-color: white;
  border-color: var(--picker-toggler-border-color-active);
  outline: none;
}
.root:global(.uui-invalid).mode-form, .root:global(.uui-invalid).mode-cell {
  border-color: var(--picker-toggler-border-color-error);
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--input-bg-readonly-color);
  border-color: var(--input-border-readonly-color);
  pointer-events: none;
}
.root:global(.uui-readonly).mode-form:hover {
  border-color: var(--input-border-readonly-color);
}
.root:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}
.root:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.root:global(.uui-readonly) :global(.uui-icon-dropdown), .root:global(.uui-readonly) :global(.uui-icon-cancel) {
  visibility: hidden;
}
.root:global(.uui-disabled) {
  fill: var(--picker-toggler-icon-color-disabled);
}
.root:global(.uui-disabled).mode-form {
  background-color: var(--input-bg-disabled-color);
  color: var(--input-text-disabled-color);
  fill: var(--input-text-disabled-color);
  border-color: var(--input-border-disabled-color);
  cursor: default;
  pointer-events: none;
}
.root:global(.uui-disabled).mode-form :global(.uui-input) {
  color: var(--input-text-disabled-color);
}
.root:global(.uui-disabled).mode-form :global(.uui-input)::placeholder {
  color: var(--input-placeholder-disabled-color);
}
.root:global(.uui-disabled).mode-form:hover, .root:global(.uui-disabled).mode-form:active, .root:global(.uui-disabled).mode-form:focus {
  background-color: var(--input-bg-disabled-color);
  color: var(--input-text-disabled-color);
  fill: var(--input-text-disabled-color);
  border-color: var(--input-border-disabled-color);
  box-shadow: none;
}
.root:global(.uui-disabled).mode-form:hover :global(.uui-input), .root:global(.uui-disabled).mode-form:active :global(.uui-input), .root:global(.uui-disabled).mode-form:focus :global(.uui-input) {
  color: var(--input-text-disabled-color);
}
.root:global(.uui-disabled).mode-form:hover :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-form:active :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-form:focus :global(.uui-input)::placeholder {
  color: var(--input-placeholder-disabled-color);
}
.root:global(.uui-disabled).mode-form::placeholder {
  color: var(--input-placeholder-disabled-color);
}
.root:global(.uui-disabled).mode-cell {
  background-color: transparent;
  color: var(--input-text-disabled-color);
  fill: var(--input-text-disabled-color);
  border-color: transparent;
  cursor: default;
  pointer-events: none;
}
.root:global(.uui-disabled).mode-cell :global(.uui-input) {
  color: var(--input-text-disabled-color);
}
.root:global(.uui-disabled).mode-cell :global(.uui-input)::placeholder {
  color: var(--input-placeholder-disabled-color);
}
.root:global(.uui-disabled).mode-cell:hover, .root:global(.uui-disabled).mode-cell:active, .root:global(.uui-disabled).mode-cell:focus {
  background-color: transparent;
  color: var(--input-text-disabled-color);
  fill: var(--input-text-disabled-color);
  border-color: transparent;
  box-shadow: none;
}
.root:global(.uui-disabled).mode-cell:hover :global(.uui-input), .root:global(.uui-disabled).mode-cell:active :global(.uui-input), .root:global(.uui-disabled).mode-cell:focus :global(.uui-input) {
  color: var(--input-text-disabled-color);
}
.root:global(.uui-disabled).mode-cell:hover :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-cell:active :global(.uui-input)::placeholder, .root:global(.uui-disabled).mode-cell:focus :global(.uui-input)::placeholder {
  color: var(--input-placeholder-disabled-color);
}
.root:global(.uui-disabled).mode-cell::placeholder {
  color: var(--input-placeholder-disabled-color);
}
.root:global(.uui-disabled) :global(.uui-input) {
  cursor: default;
}
.root:global(.uui-disabled) :global(.uui-input)::placeholder {
  color: var(--picker-toggler-text-color-disabled);
}
.root:global(.uui-disabled) :global(.uui-icon-dropdown), .root:global(.uui-disabled) :global(.uui-icon-cancel) {
  visibility: hidden;
}
.root :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 2px;
  font-family: var(--font-regular);
  color: var(--picker-toggler-text-color);
}
.root :global(.uui-input)::placeholder {
  color: var(--picker-toggler-placeholder-color);
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-caption) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-regular);
  align-self: center;
  white-space: nowrap;
}
.root :global(.uui-button-box) {
  margin-right: 3px;
  margin-bottom: 2px;
}
.root :global(.uui-button-box):last-of-type {
  margin-right: 0;
}
.root :global(.uui-placeholder) {
  color: var(--picker-toggler-text-color);
}
.root :global(.uui-placeholder)::placeholder {
  color: var(--picker-toggler-text-color);
}
.root :global(.uui-placeholder)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-icon-cancel) {
  margin-left: 6px;
}
.root.size-24 {
  min-width: 24px;
}
.root.size-24:global(.uui-input-box) {
  min-height: 24px;
  border-width: 1px;
}
.root.size-24 :global(.uui-input) {
  line-height: 18px;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-top: 0;
  padding-bottom: 0;
}
.root.size-24 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 18px;
  margin-bottom: 2px;
}
.root.size-30 {
  min-width: 30px;
}
.root.size-30:global(.uui-input-box) {
  min-height: 30px;
  border-width: 1px;
}
.root.size-30 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.root.size-30 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 24px;
  margin-bottom: 2px;
}
.root.size-36 {
  min-width: 36px;
}
.root.size-36:global(.uui-input-box) {
  min-height: 36px;
  border-width: 1px;
}
.root.size-36 :global(.uui-input) {
  line-height: 18px;
  font-size: 14px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.size-36 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 30px;
  margin-bottom: 2px;
}
.root.size-42 {
  min-width: 42px;
}
.root.size-42:global(.uui-input-box) {
  min-height: 42px;
  border-width: 1px;
}
.root.size-42 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.root.size-42 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 36px;
  margin-bottom: 2px;
}
.root.size-48 {
  min-width: 48px;
}
.root.size-48:global(.uui-input-box) {
  min-height: 48px;
  border-width: 1px;
}
.root.size-48 :global(.uui-input) {
  line-height: 24px;
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
}
.root.size-48 :not(:global(.uui-button-box)) > :global(.uui-icon) {
  height: 42px;
  margin-bottom: 2px;
}