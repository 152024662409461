@import '../../../../../styles/base';

.metadata-container {
  flex-grow: 1;
  gap: 20px;
  position: relative;
  &__project-name-input {
    margin-bottom: 3px;
  }
  &__project-description-input {
    margin-bottom: 3px;
  }

  .uui-label-top {
    .uui-label {
      font-family: $sans-pro-source;
      font-weight: 600;
    }
  }
}
