:global(.font-regular) {
  font-family: var(--font-regular);
  font-weight: normal;
}

:global(.font-semibold) {
  font-family: var(--font-semibold);
  font-weight: normal;
}

:global(.font-italic) {
  font-family: var(--font-italic);
  font-weight: normal;
}

:global(.font-primary) {
  font-family: var(--font-primary);
  font-weight: normal;
}

:global(.font-promo) {
  font-family: var(--font-promo);
  font-weight: normal;
}