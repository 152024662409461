@media screen and (max-width: 720px) {
  .panel {
    height: var(--app-mobile-height);
  }
}

.footer-wrapper {
  border-top: 1px solid var(--divider-color);
  background: white;
  padding: 0 12px;
  display: flex;
}
.footer-wrapper.footer-size-24 {
  min-height: 24px;
}
.footer-wrapper.footer-size-30 {
  min-height: 30px;
}
.footer-wrapper.footer-size-30 :global(.uui-caption) {
  font-size: 14px;
}
.footer-wrapper.footer-size-36 {
  min-height: 36px;
}
.footer-wrapper.footer-size-42 {
  min-height: 42px;
}
.footer-wrapper.footer-size-42 :global(.uui-caption) {
  font-size: 16px;
}
.footer-wrapper.footer-size-48 {
  min-height: 48px;
}
.footer-wrapper.footer-size-48 :global(.uui-caption) {
  font-size: 16px;
}