@import 'styles/common.scss';

.dropdown {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 12px;
    width: 100%;
    cursor: pointer;
  }

  &__item {
    text-transform: capitalize;
  }
}
