/* Main Colors */
/* Gray */
.root :global(.avatars) {
  padding-right: 12px;
}
.root :global(.avatars) > * {
  border-radius: 50%;
  border: 1px solid white;
}
.root :global(.avatarsCount) {
  color: #303240;
  margin-left: 3px;
  line-height: 1;
  font-family: "Sans Semibold", Arial, sans-serif;
  font-size: 14px;
}