:global(.uui-thumb-vertical), :global(.uui-thumb-horizontal) {
  background: var(--scroll-bars-bg-color);
  border-radius: 1.5px;
}
:global(.uui-thumb-vertical):hover, :global(.uui-thumb-horizontal):hover {
  border-radius: 3px;
}
:global(.uui-thumb-vertical):active, :global(.uui-thumb-horizontal):active {
  border-radius: 3px;
  background: var(--scroll-bars-bg-color-active);
}

:global(.uui-thumb-vertical) {
  width: 3px;
  margin-left: 3px;
}
:global(.uui-thumb-vertical):hover {
  width: 6px;
  margin-left: 0;
}
:global(.uui-thumb-vertical):active {
  width: 6px;
  margin-left: 0;
}

:global(.uui-thumb-horizontal) {
  height: 3px;
  margin-top: 3px;
}
:global(.uui-thumb-horizontal):hover {
  height: 6px;
  margin-top: 0;
}
:global(.uui-thumb-horizontal):active {
  height: 6px;
  margin-top: 0;
}