/* Main Colors */
/* Gray */
.main-panel {
  height: 100%;
  padding-top: 6px;
}
.main-panel .group-title {
  margin-bottom: -6px;
}
.main-panel .group-title > .group-title-text {
  padding-top: 0;
}
.main-panel .group-title > .group-title-badge {
  top: -3px;
}
.main-panel .group-title > .group-title-badge > :global(.uui-caption) {
  font-family: "Sans Semibold", Arial, sans-serif;
}
.main-panel .group-title:not(:first-child) {
  padding-top: 12px;
}
.main-panel .group-items {
  flex-direction: column;
}
.main-panel .no-data {
  flex-direction: column;
  top: calc(50% - 35px);
  position: relative;
}
.main-panel .h-divider {
  width: 100%;
  box-sizing: border-box;
  padding: 6px 24px;
}
.main-panel .h-divider::before {
  content: "";
  height: 1px;
  display: block;
  background: #E1E3EB;
}

.search-area {
  padding-bottom: 6px;
}