/* Main Colors */
/* Gray */
.root {
  --background-color-hover: #1D1E26;
  --icon-fill: #FFFFFF;
  --menu-background-color: #1D1E26;
  --menu-background-color-hover: #0079b5;
  --items-background-color: #1D1E26;
  position: relative;
  display: flex;
}
.root:global(.uui-burger-menu-open) {
  z-index: 1001;
}
.root:global(.uui-burger-menu-open) ~ :global(#global_menu_toggle) {
  background-color: var(--menu-background-color);
  z-index: 1001;
}
.root:global(.uui-burger-menu-open) ~ :global(#global_menu_toggle) :hover {
  background-color: var(--menu-background-color-hover);
}
.root :global(.uui-burger-button) {
  width: 60px;
  cursor: pointer;
  fill: var(--icon-fill);
}
.root :global(.uui-burger-button):hover {
  background-color: var(--background-color-hover);
}
.root :global(.uui-burger-button):active {
  background-color: var(--background-color-hover);
}
.root :global(.uui-burger-button) :global(.uui-icon) svg {
  width: 60px;
  height: 60px;
}
.root :global(.uui-burger-logo-container) {
  position: absolute;
  left: 60px;
  top: 0;
}
.root :global(.uui-burger-logo) {
  display: block;
  height: 60px;
}

.burger-content:global(.uui-burger-overlay):global(.uui-burger-overlay-visible) {
  position: fixed;
  z-index: 1000;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(29, 30, 38, 0.8);
}
.burger-content :global(.uui-burger-items) {
  --background-color-hover: #1D1E26;
  --icon-fill: #FFFFFF;
  --menu-background-color: #1D1E26;
  --menu-background-color-hover: #0079b5;
  --items-background-color: #1D1E26;
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 300px;
  background: var(--items-background-color);
  transform: translateX(-100px);
  overflow-y: overlay;
}
.burger-content :global(.uui-burger-items):global(.uui-burger-items-visible) {
  transform: translateX(0);
}