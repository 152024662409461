/* Main Colors */
/* Gray */
.button-color-blue {
  --background-color: #008ACE;
  --background-color-hover: #0079b5;
  --background-color-active: #00689b;
  --background-color-light-hover: #ceefff;
  --background-color-light-active: #b5e6ff;
  --background-color-contrast: white;
  --border-color: #008ACE;
  --border-color-hover: #0079b5;
  --border-color-active: #00689b;
  --background-color-disabled: #E1E3EB;
  --caption-color: #008ACE;
  --caption-color-disabled: #ACAFBF;
}

.button-color-green {
  --background-color: #88CC00;
  --background-color-hover: #77b300;
  --background-color-active: #669900;
  --background-color-light-hover: #eeffcc;
  --background-color-light-active: #e6ffb3;
  --background-color-contrast: white;
  --border-color: #88CC00;
  --border-color-hover: #77b300;
  --border-color-active: #669900;
  --background-color-disabled: #E1E3EB;
  --caption-color: #88CC00;
  --caption-color-disabled: #ACAFBF;
}

.button-color-red {
  --background-color: #E54322;
  --background-color-hover: #d53919;
  --background-color-active: #be3316;
  --background-color-light-hover: #faded9;
  --background-color-light-active: #f8cbc2;
  --background-color-contrast: white;
  --border-color: #E54322;
  --border-color-hover: #d53919;
  --border-color-active: #be3316;
  --background-color-disabled: #E1E3EB;
  --caption-color: #E54322;
  --caption-color-disabled: #ACAFBF;
}

.button-color-gray50 {
  --background-color: #ACAFBF;
  --background-color-hover: #6C6F80;
  --background-color-active: #474A59;
  --background-color-light-hover: #EBEDF5;
  --background-color-light-active: #E1E3EB;
  --background-color-contrast: white;
  --border-color: #ACAFBF;
  --border-color-hover: #6C6F80;
  --border-color-active: #474A59;
  --background-color-disabled: #E1E3EB;
  --caption-color: #6C6F80;
  --caption-color-disabled: #ACAFBF;
}