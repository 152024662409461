:global(.checkbox-vars) {
  --checkbox-border-color: var(--input-border-color);
  --checkbox-border-color-hover: var(--input-border-hover-color);
  --checkbox-border-color-disabled: var(--input-border-disabled-color);
  --checkbox-border-color-checked: var(--input-border-checked-color);
  --checkbox-border-color-checked-disabled: var(--input-border-checked-disabled-color);
  --checkbox-bg-color: var(--input-bg-color);
  --checkbox-bg-color-checked: var(--input-bg-checked-color);
  --checkbox-bg-color-disabled: var(--input-bg-disabled-color);
  --checkbox-bg-color-checked-hover: var(--input-bg-checked-hover-color);
  --checkbox-bg-color-checked-disabled: var(--input-bg-checked-disabled-color);
  --checkbox-error-color: var(--negative-color);
  --checkbox-fill-color: var(--input-checkbox-icon-color);
  --checkbox-label-color: var(--input-label-color);
  --checkbox-label-color-disabled: var(--input-label-disabled-color);
  --checkbox-border-radius: var(--border-radius);
}