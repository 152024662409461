/* Main Colors */
/* Gray */
.dropdown-container {
  align-items: normal;
}

.container {
  display: flex;
  padding: 9px 30px;
  justify-content: space-between;
  border-top: 1px solid #CED0DB;
  align-items: center;
  background-color: #FFFFFF;
  position: relative;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.counter {
  color: #CED0DB;
  font-size: 15px;
  font-family: "Sans Regular", Arial, sans-serif;
}

.date-input {
  min-width: 0;
  padding: 0 5px;
}
.date-input:global(.uui-focus) {
  border: 1px solid #008ACE;
}
.date-input.size-24 {
  min-width: 22px;
  padding-left: 5px;
  padding-right: 5px;
}
.date-input.size-24 :global(.uui-caption), .date-input.size-24 :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
}
.date-input.size-24:global(.uui-button-box), .date-input.size-24:global(.uui-input-box) {
  min-height: 22px;
  border-width: 0;
}
.date-input.size-30 {
  min-width: 28px;
  padding-left: 5px;
  padding-right: 5px;
}
.date-input.size-30 :global(.uui-caption), .date-input.size-30 :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
}
.date-input.size-30:global(.uui-button-box), .date-input.size-30:global(.uui-input-box) {
  min-height: 28px;
  border-width: 0;
}
.date-input.size-36 {
  min-width: 34px;
  padding-left: 5px;
  padding-right: 5px;
}
.date-input.size-36 :global(.uui-caption), .date-input.size-36 :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
}
.date-input.size-36:global(.uui-button-box), .date-input.size-36:global(.uui-input-box) {
  min-height: 34px;
  border-width: 0;
}
.date-input.size-42 {
  min-width: 40px;
  padding-left: 5px;
  padding-right: 5px;
}
.date-input.size-42 :global(.uui-caption), .date-input.size-42 :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
}
.date-input.size-42:global(.uui-button-box), .date-input.size-42:global(.uui-input-box) {
  min-height: 40px;
  border-width: 0;
}
.date-input.size-48 {
  min-width: 46px;
  padding-left: 5px;
  padding-right: 5px;
}
.date-input.size-48 :global(.uui-caption), .date-input.size-48 :global(.uui-input) {
  padding-left: 6px;
  padding-right: 6px;
}
.date-input.size-48:global(.uui-button-box), .date-input.size-48:global(.uui-input-box) {
  min-height: 46px;
  border-width: 0;
}

.date-input-group {
  display: flex;
  min-width: 0;
  background-color: #FFFFFF;
  border: 1px solid #CED0DB;
}
.date-input-group .separator {
  display: flex;
  flex-shrink: 0;
  background: #CED0DB;
  width: 1px;
}
.date-input-group:hover {
  border-color: #6C6F80;
}
.date-input-group:hover .separator {
  background: #6C6F80;
}
.date-input-group:hover:global(.uui-disabled), .date-input-group:hover:global(.uui-readonly) {
  border-color: #CED0DB;
}
.date-input-group:hover:global(.uui-disabled) .separator, .date-input-group:hover:global(.uui-readonly) .separator {
  background: #CED0DB;
}
.date-input-group:global(.uui-focus) {
  border-color: #CED0DB;
}
.date-input-group:global(.uui-focus) .separator {
  background: #008ACE;
}
.date-input-group:global(.uui-focus) .date-input:hover {
  outline: 1px solid #6C6F80;
}
.date-input-group:global(.uui-focus) .date-input:global(.uui-focus) {
  outline: 1px solid #008ACE;
}
.date-input-group:global(.uui-readonly).mode-form {
  background-color: #FAFAFC;
  border-color: #E1E3EB;
  pointer-events: none;
}
.date-input-group:global(.uui-readonly).mode-form:hover {
  border-color: #E1E3EB;
}
.date-input-group:global(.uui-readonly).mode-cell {
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}
.date-input-group:global(.uui-readonly).mode-cell:hover {
  background-color: transparent;
  border-color: transparent;
}
.date-input-group:global(.uui-readonly) .date-input:global(.uui-focus) {
  box-shadow: none;
}
.date-input-group:global(.uui-invalid) {
  border-color: #E54322;
}