:global(.picker-toggler-vars) {
  --picker-toggler-bg-color: var(--input-bg-color);
  --picker-toggler-border-color: var(--input-border-color);
  --picker-toggler-border-color-hover: var(--input-textinput-border-hover-color);
  --picker-toggler-border-color-active: var(--input-border-checked-color);
  --picker-toggler-border-color-error: var(--negative-color);
  --picker-toggler-placeholder-color: var(--input-placeholder-color);
  --picker-toggler-text-color: var(--input-text-color);
  --picker-toggler-text-color-disabled: var(--input-text-disabled-color);
  --picker-toggler-icon-color: var(--input-icon-color);
  --picker-toggler-icon-color-disabled: var(--input-icon-disabled-color);
  --picker-toggler-icon-color-clickable: var(--icon-color);
  --picker-toggler-icon-color-clickable-hover: var(--icon-hover-color);
  --picker-toggler-border-radius: var(--border-radius);
}