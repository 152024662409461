.root {
  display: flex;
  align-items: center;
}
.root.size-18 {
  min-height: 18px;
}
.root.size-24 {
  min-height: 24px;
}
.root.size-30 {
  min-height: 30px;
}
.root.size-36 {
  min-height: 36px;
}
.root.size-48 {
  min-height: 48px;
}