.root:global(.uui-button-box) {
  background-color: var(--tag-bg-color);
  fill: var(--tag-fill-color);
  border: 0;
}
.root:global(.uui-button-box) :global(.uui-count) {
  background-color: var(--tag-count-bg-color);
  color: var(--tag-count-color);
  font-family: var(--font-semibold);
  margin: auto;
  height: 18px;
  line-height: 18px;
  font-size: 12px;
  border-radius: 9px;
  padding-left: 6px;
  padding-right: 6px;
}
.root:global(.uui-button-box) :global(.uui-count):not(:first-child) {
  margin-left: 6px;
}
.root:global(.uui-button-box):global(.-clickable):hover {
  background-color: var(--tag-bg-color-hover);
  fill: var(--tag-fill-color-hover);
  cursor: pointer;
}
.root:global(.uui-button-box) :global(.uui-caption) {
  font-family: var(--font-regular);
  color: var(--tag-caption-color);
}
.root.size-18 {
  min-height: 18px;
  min-width: 18px;
  padding-left: 3px;
  padding-right: 3px;
}
.root.size-18 :global(.uui-count) {
  height: 12px;
  min-width: 6px;
  line-height: 12px;
  font-size: 8px;
  border-radius: 6px;
  padding-left: 3px;
  padding-right: 3px;
}
.root.size-18 :global(.uui-count):not(:first-child) {
  margin-left: 3px;
}
.root.size-18 :global(.uui-caption), .root.size-18 :global(.uui-input) {
  padding: 3px 3px;
  line-height: 12px;
  font-size: 12px;
}
.root.size-24 {
  min-width: 24px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 24px;
}
.root.size-24 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-24 :global(.uui-caption), .root.size-24 :global(.uui-input) {
  padding-top: 3px;
  padding-bottom: 3px;
  line-height: 18px;
  font-size: 14px;
}
.root.size-30 {
  min-width: 30px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 30px;
}
.root.size-30 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-30 :global(.uui-caption), .root.size-30 :global(.uui-input) {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 18px;
  font-size: 14px;
}
.root.size-36 {
  min-width: 36px;
  padding-left: 6px;
  padding-right: 6px;
  min-height: 36px;
}
.root.size-36 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-36 :global(.uui-caption), .root.size-36 :global(.uui-input) {
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: 24px;
  font-size: 14px;
}
.root.size-42 {
  min-width: 42px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 42px;
}
.root.size-42 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-42 :global(.uui-caption), .root.size-42 :global(.uui-input) {
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 24px;
  font-size: 16px;
}
.root.size-42 :global(.uui-count) {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 12px;
  min-width: 12px;
}
.root.size-48 {
  min-width: 48px;
  padding-left: 12px;
  padding-right: 12px;
  min-height: 48px;
}
.root.size-48 :global(.uui-caption) {
  padding-left: 6px;
  padding-right: 6px;
}
.root.size-48 :global(.uui-caption), .root.size-48 :global(.uui-input) {
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  font-size: 16px;
}
.root.size-48 :global(.uui-count) {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 12px;
  min-width: 12px;
}