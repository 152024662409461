.root {
  width: 100%;
}
.root :global(.uui-label) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: var(--font-semibold);
  color: var(--labeled-input-color);
}
.root :global(.uui-label) :global(.uui-labeled-input-info-icon) {
  flex-shrink: 0;
  margin: 0 4px;
  fill: var(--labeled-input-fill-color);
}
.root :global(.uui-label) :global(.uui-labeled-input-asterisk) {
  color: var(--labeled-input-asterisk-color);
}
.root :global(.uui-label) :global(.uui-labeled-input-optional) {
  font-family: var(--font-italic);
  font-size: 12px;
  line-height: 18px;
  color: var(--labeled-input-optional-text-color);
}
.root :global(.uui-label-left) > :global(.uui-label) {
  margin-right: 5px;
}
.root :global(.uui-invalid-message) {
  line-height: 18px;
  font-size: 12px;
  color: var(--labeled-input-color-error);
  font-family: var(--font-regular);
  margin-top: 3px;
}
.root.size-24 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-24 :global(.uui-label) {
  font-size: 12px;
  line-height: 18px;
}
.root.size-30 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-30 :global(.uui-label) {
  font-size: 12px;
  line-height: 18px;
}
.root.size-36 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-36 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-42 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-42 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}
.root.size-48 :global(.uui-label-top) > :global(.uui-label) {
  margin-bottom: 6px;
}
.root.size-48 :global(.uui-label) {
  font-size: 14px;
  line-height: 18px;
}