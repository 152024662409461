/* Main Colors */
/* Gray */
.root {
  position: relative;
  min-height: 104px;
  border: 2px dashed #CED0DB;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
}
.root.drop-start {
  border: 2px dashed #008ACE;
}
.root.drop-over {
  background-color: #ceefff;
}
.root .link {
  color: #008ACE;
}
.root .link:hover {
  color: #0079b5;
}
.root .drop-area {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}