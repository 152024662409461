.cell {
  position: relative;
  box-sizing: border-box;
  width: 0;
}
.cell.wrapper {
  display: flex;
  align-items: flex-start;
}
.cell.align-widgets-top.size-24 :global(.uui-checkbox), .cell.align-widgets-top.size-24 .folding-arrow, .cell.align-widgets-top.size-30 :global(.uui-checkbox), .cell.align-widgets-top.size-30 .folding-arrow {
  margin-top: 6px;
}
.cell.align-widgets-top.size-36 :global(.uui-checkbox), .cell.align-widgets-top.size-36 .folding-arrow {
  margin-top: 9px;
}
.cell.align-widgets-top.size-42 :global(.uui-checkbox), .cell.align-widgets-top.size-42 .folding-arrow {
  margin-top: 12px;
}
.cell.align-widgets-top.size-48 :global(.uui-checkbox), .cell.align-widgets-top.size-48 .folding-arrow {
  margin-top: 15px;
}
.cell.align-widgets-top.size-60 :global(.uui-checkbox), .cell.align-widgets-top.size-60 .folding-arrow {
  margin-top: 21px;
}
.cell.align-widgets-center {
  align-self: center;
}
.cell.padding-12 {
  padding: 0 12px;
}
.cell.padding-24 {
  padding: 0 24px;
}
.cell.padding-left-12 {
  padding-left: 12px;
}
.cell.padding-left-24 {
  padding-left: 24px;
}
.cell.padding-right-24 {
  padding-right: 24px;
}

.drag-handle {
  position: absolute;
  left: 2px;
  width: 6px;
  top: 3px;
  bottom: 2px;
  align-self: stretch;
  visibility: hidden;
}
:global(.-draggable.uui-table-row):not(:global(.uui-drop-accepted)):hover .drag-handle {
  visibility: visible;
}

.checkbox {
  padding-right: 12px;
}

.indent {
  display: flex;
  justify-content: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  width: 12px;
  padding-right: 12px;
}

.folding-arrow {
  margin: auto 0;
}
.folding-arrow:global(.uui-icon) {
  position: relative;
  fill: var(--icon-color);
}

.folding-arrow-12:global(.uui-icon) {
  left: -2px;
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
}

.folding-arrow-18:global(.uui-icon) {
  left: -4px;
  flex: 0 0 18px;
  width: 18px;
  height: 18px;
}