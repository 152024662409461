@use '~@epam/promo/assets/styles/colors.scss' as *;
@import 'styles/common.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;

  .modal-body {
    background-color: white;
    z-index: 1051;
    min-height: 363px;
    width: 600px;
  }
}

.header-diaolog-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  border-bottom: 1px solid #ced0db;
  width: 600px;
  height: 60px;

  .dialog-title-text {
    margin-left: 24px;
    font-weight: 700;
    font-size: 18px;
  }
  .close-button {
    margin-right: 24px;
  }
}

.message-text-container {
  margin-bottom: 16px;
  width: 600px;
  .message-text-inner-container {
    margin-left: 30px;
    margin-right: 30px;
  }
  text {
    font-size: 16px;
  }
  .message-text {
    font-weight: 600;
    display: inline;
    font-size: 16px;
  }
}
.dialog-comments {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 30px;
}

.text-input-container {
  width: 600px;
  margin-bottom: 24px;
  .text-input-custom-size {
    display: flex;
    align-items: flex-start;
    padding-left: 6px;
    padding-top: 9px;
    margin-left: 24px;
    margin-right: 34px;
    height: 108px;
  }
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid #ced0db;
  width: 600px;
  height: 54px;
  button {
    margin-right: 12px;
  }
  button:last-child {
    margin-right: 24px;
    margin-left: 0;
  }
}
