.root .multiline-vertical-padding-24 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.root .multiline-vertical-padding-30 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.root .multiline-vertical-padding-36 {
  padding-top: 6px;
  padding-bottom: 6px;
}
.root .multiline-vertical-padding-42 {
  padding-top: 9px;
  padding-bottom: 9px;
}
.root .multiline-vertical-padding-48 {
  padding-top: 9px;
  padding-bottom: 9px;
}