/* Main Colors */
/* Gray */
.spinner-color-blue {
  --background-color: #008ACE;
}

.spinner-color-gray50 {
  --background-color: #ACAFBF;
}

.spinner-color-white {
  --background-color: white;
}